<template>
  <div class="bg-overlay">
    <div class="col-10 col-md-7 col-lg-8 mx-auto py-4">
      <div class="d-flex justify-content-end">
        <div type="button" class="btn bg-dark text-white
        rounded-pill shadow ms-2"
        @click="changeTab('info')">
          Account
        </div>
        <div type="button" class="btn bg-light text-blue
        rounded-pill shadow ms-2"
        @click="changeTab('course')">
          Course
        </div>
      </div>
      <div class="modal fade" id="confirmEdit" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="confirmEditModalLabel">
              Confirm Changes</h5>
              <button type="button" class="btn-close"
                data-bs-dismiss="modal" aria-label="Close">
                </button>
            </div>
            <div class="modal-body">
              Do you wish to proceed?
            </div>
            <div class="modal-footer">
              <button type="button" class="btn bg-acc2"
              data-bs-dismiss="modal">Cancel</button>
              <button type="button" class="btn bg-ter"
              v-on:click="updateUserAcc()">Confirm</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="deleteUserModal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteUserModalLabel">
              This action cannot be undone</h5>
              <button type="button" class="btn-close"
                data-bs-dismiss="modal" aria-label="Close">
                </button>
            </div>
            <div class="modal-body">
              Do you wish to proceed?
            </div>
            <div class="modal-footer">
              <button type="button" class="btn bg-acc2"
              data-bs-dismiss="modal">Cancel</button>
              <button type="button" class="btn bg-ter"
              v-on:click="deleteUser()">Confirm</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="changeEmailModal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="changeEmailLabel">
              Confirm New Email</h5>
              <button type="button" class="btn-close"
                data-bs-dismiss="modal" aria-label="Close">
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group d-flex fs-4 text-start mt-4 row px-3 input-container">
              <label class="fs-5">New Email</label>
                <input type="text" name="email"
                class="input-field" v-model="newEmail"
                :class="{ 'is-invalid': verifySubmit && $v.newEmail.$error }"/>
                <span class="input-border"></span>
              </div>
              <div v-if="verifySubmit && !$v.newEmail.required"
              class="error">Please enter an email</div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn bg-acc2"
              data-bs-dismiss="modal">Cancel</button>
              <button type="button" class="btn bg-ter"
              v-on:click="submitVerify()">Confirm</button>
            </div>
          </div>
        </div>
      </div>
      <div class="card card-shadow mt-4">
        <div class="card-body">
          <h3 class="card-title d-inline mb-4">Account Information</h3>
          <div class="text-white d-inline">
            <button v-show="edit==false" type="button"
            class="float-end btn btn-danger text-center mx-2" @click="loadDelete">
            <span class="material-icons-outlined align-middle"
               data-bs-toggle="tooltip" title="Delete">delete</span>
              Delete
            </button>
            <button v-show="edit==false" type="button"
            class="float-end btn bg-pri text-center mx-2" @click="editInfo">
            <span class="material-icons-outlined align-middle"
               data-bs-toggle="tooltip" title="Edit">edit</span>
              Edit
            </button>
          </div>
          <form @submit="updateUserAcc" class="container">
            <div class="form-group d-flex fs-4 text-start mt-4 row px-3 input-container">
              <label class="fs-6 text-accent-1">Given Name</label>
              <input type="text" name="name" :disabled="edit == false"
              class="input-field fs-5" v-model="user.givenName"
              :class="{ 'edit-mode': edit,
              'is-invalid': submitted && $v.user.givenName.$error }"/>
              <span class="input-border"></span>
              <div v-if="submitted && !$v.user.givenName.required"
              class="form-text error">Given Name is required</div>
            </div>
            <div class="form-group d-flex fs-4 text-start mt-4 row px-3 input-container">
              <label class="fs-6 text-accent-1">Last Name</label>
              <input type="text" name="name"
              class="input-field fs-5" v-model="user.lastName" :disabled="edit == false"
              :class="{ 'edit-mode': edit,
              'is-invalid': submitted && $v.user.lastName.$error }"/>
              <span class="input-border"></span>
              <div v-if="submitted && !$v.user.lastName.required"
              class="form-text error">Last Name is required</div>
            </div>
            <div class="form-group d-flex fs-4 text-start mt-4 row px-3 input-container">
              <label class="fs-6 text-accent-1">Email</label>
              <input type="text" name="email"
              class="input-field fs-5" v-model="email" disabled/>
              <span class="input-border"></span>
            </div>
            <div v-show="edit" class="form-group fs-4 text-start mt-4 mx-2">
              <div  class="col-auto">
                <button type="button" class="btn mt-0 btn bg-acc1"
                @click="loadVerify">Change Email</button>
              </div>
            </div>
            <div class="form-group d-flex fs-4 text-start mt-4 row px-3 input-container">
              <label class="fs-6 text-accent-1">School</label>
              <input type="text" name="tier"
              class="input-field fs-5" v-model="school" disabled>
              <span class="input-border"></span>
            </div>
            <div class="form-group fs-4 text-start mt-4 mx-2">
              <input
                type="checkbox"
                id="verified"
                v-model="verified"
                true-value=1
                false-value=0 :disabled="edit == false">
              <label class="mx-2 fs-5" for="verified">Verified</label>
            </div>
            <div v-show="edit" class="form-group fs-4 text-start mt-4 mx-2">
              <div  class="col-auto">
                <button type="button" class="btn mt-0 btn bg-acc1"
                @click="submitReset">Reset Password</button>
              </div>
            </div>
            <div v-show="edit===true"
            class="col mx-2 bg-gradient-primary text-white">
              <button type="button"
              class=" mx-2 float-end btn bg-pri text-center" @click="submitEdit">
                Update
              </button>
            </div>
            <div v-show="edit===true"
            class="col mx-2 bg-gradient-primary text-white">
              <button type="button"
              class=" mx-2 float-end btn bg-ter text-center" @click="cancelEdit">
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
// import firebase from 'firebase';
import { Modal } from 'bootstrap';
import {
  required,
} from 'vuelidate/lib/validators';

const stubTitleMap = {
  info: 'User Info',
  course: 'Course',
};

export default {
  name: 'AdminUserAcc',
  components: {
  },
  computed: {
    loadScreen() {
      return this.$store.state.loadScreen;
    },
  },
  data() {
    return {
      userAcc: null,
      userFocus: null,
      userId: null,
      passwordFail: false,
      user: {
        givenName: '',
        lastName: '',
      },
      email: null,
      school: '',
      roleId: null,
      verified: null,
      edit: false,
      submitted: false,
      newEmail: '',
      verifySubmit: false,
      invalid: null,
      activeTab: null,
      title: '',
      selectedTab: null,
      activeItem: [true, false],
      deleteModal: null,
      changeEmailModal: null,
      rootUrl: window.location.origin,
    };
  },
  validations: {
    user: {
      givenName: { required },
      lastName: { required },
    },
    newEmail: { required },
  },
  methods: {
    getUserAcc() {
      this.$store.commit('setLoadScreen', { status: true });
      const payload = {
        id: this.userId,
      };
      axios.post('/api/admin/getUserAcc', payload)
        .then((response) => {
          this.userAcc = response.data.user;
          console.log(this.userAcc);
          this.user.givenName = this.userAcc.givenName;
          this.user.lastName = this.userAcc.lastName;
          this.email = this.userAcc.email;
          this.school = this.userAcc.school;
          this.verified = this.userAcc.verified;
          this.roleId = this.userAcc.role;
          this.$store.commit('setLoadScreen', { status: false });
        }).catch((err) => {
          console.error(err);
          this.$store.commit('setLoadScreen', { status: false });
        });
    },
    changeTab(stub) {
      this.selectedTab = stubTitleMap[stub];
      this.$router.push(stub);
    },
    editInfo() {
      this.edit = true;
    },
    cancelEdit() {
      this.edit = false;
      this.getUserAcc();
    },
    submitReset() {
      const encEmail = this.encoder64(this.email);
      const payload = {
        email: this.email,
      };
      payload.resetUrl = `${this.rootUrl}/reset/${encEmail}`;
      this.$store.commit('setLoadScreen', { status: true });
      axios.post('/api/sendReset', payload)
        .then(() => {
          this.emailSubmitted = true;
          this.$store.commit('setLoadScreen', { status: false });
        });
      console.log('Reset email sent');
    },
    encoder64(param) {
      const encoded = btoa(param);
      return encoded;
    },
    submitEdit() {
      this.submitted = true;
      if (!this.$v.user.$invalid) {
        this.editModal = new Modal(document.getElementById('confirmEdit', {}));
        this.editModal.show();
      }
    },
    updateUserAcc() {
      this.$store.commit('setLoadScreen', { status: true });
      const payload = {
        id: this.userId,
        givenname: this.user.givenName,
        lastname: this.user.lastName,
        email: this.email,
        verified: this.verified,
      };
      console.log(payload);
      axios.post('/api/admin/updateUsers', payload)
        .then(() => {
          const userData = {
            email: this.email,
            givenName: this.user.givenName,
            id: this.userId,
            lastName: this.user.lastName,
            roleId: this.roleId,
            schoolId: this.school,
            verified: this.verified,
          };
          console.log(userData);
        }).catch((err) => {
          console.error(err);
          this.getUserAcc();
          this.$store.commit('setLoadScreen', { status: false });
        });
      this.edit = false;
      this.submitted = false;
      this.editModal.hide();
      this.$store.commit('setLoadScreen', { status: false });
    },
    loadVerify(ind) {
      this.changeEmailModal = new Modal(document.getElementById('changeEmailModal', {}));
      this.userFocus = ind;
      console.log(this.userFocus);
      this.changeEmailModal.show();
    },
    submitVerify() {
      this.verifySubmit = true;
      if (!this.$v.newEmail.$invalid) {
        const encEmail = this.encoder64(this.newEmail);
        const session = {
          email: this.newEmail,
          name: this.user.givenName,
        };
        session.verificationUrl = `${this.rootUrl}/verify/${encEmail}`;
        axios.post('/api/account/sendVerification', session)
          .then(() => {
            this.$store.commit('setLoadScreen', { status: false });
          });
        this.email = this.newEmail;
        this.verified = 0;
        this.changeEmailModal.hide();
        this.updateUserAcc();
      }
    },
    loadDelete(ind) {
      this.deleteModal = new Modal(document.getElementById('deleteUserModal', {}));
      this.userFocus = ind;
      console.log(this.userFocus);
      this.deleteModal.show();
    },
    deleteUser() {
      axios.post('/api/admin/deleteUser', {
        // eslint-disable-next-line no-underscore-dangle
        id: this.userId,
      }).then(() => {
        this.deleteModal.hide();
        this.$router.go(-1);
      }).catch((err) => {
        throw err;
      });
    },
  },
  mounted() {
    this.userId = this.$route.params.uid;
    this.roleId = this.$route.params.rid;
    // const routeSplit = this.$route.path.split('/');
    // this.selectedTab = stubTitleMap[routeSplit[routeSplit.length - 1]];
    this.getUserAcc();
  },
};
</script>

<style>
.error {
  color: red;
}
input.edit-mode {
  background: #F3ECE6 !important;
  color: #000 !important;
}

.input-field{
  width: 100%;
  min-height: 1em;
  border: none;
  /*outline: none;*/
  background-color: rgba(0,103,165,0.1);
  border-radius: 3em;
  padding: 0.1em 1em;
}

.input-border{
  margin-left: auto;
  margin-right: auto;
  display:inline-block;
  width: 0px;
  height: 0px;
  background: #ECAB32;
  position: relative;
  top:-2px;
  -webkit-transition: all ease-in-out .15s;
  -o-transition: all ease-in-out .15s;
  transition: all ease-in-out .15s;
}
.input-field:focus {
  outline:  none;
  background-color: rgba(236,171,50,.15);
}

.input-field:focus ~ .input-border {
  height:  2px;
  width: calc(100% - 2em);
}

.tab-radio {
    display: none;
  }
  .tab-radio:checked + div {
    background-color: var(--accent-1) !important;
    color: var(--dark-color) !important;
  }
</style>

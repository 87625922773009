<template>
<div>
  <loading :active.sync="loadScreen"
  :is-full-page="true"></loading>
  <nav id="primary-sidebar" class="d-none d-md-block text-light text-center h-100">
    <div class="">
      <img class="logo hover-pointer" id="logo" src="@/assets/pple_logo.png">
    </div>
    <ul v-if="userData.roleId !== 5" class="nav flex-column">
      <li class="nav-item" id="home">
        <router-link to="/home">
          <div class="nav-container">
            <div>
              <i class="material-icons md-32 md-light">home</i><br>
              <span>Home</span>
            </div>
          </div>
        </router-link>
      </li>
      <li class="nav-item" id="courses"
        v-show="userData.roleId == 1 || (userData.roleId == 2 && userData.schoolId !=1)">
        <router-link to="/courses">
          <div class="nav-container">
            <div>
              <i class="material-icons md-32 md-light">class</i><br>
              <span>Courses</span>
            </div>
          </div>
        </router-link>
      </li>
    </ul>
    <ul v-else class="nav flex-column">
      <li class="nav-item" id="user">
        <router-link to="/users/">
          <div class="nav-container">
            <div>
              <i class="material-icons md-32 md-light">people</i><br>
              <span>User</span>
            </div>
          </div>
        </router-link>
      </li>
       <li class="nav-item" id="institute">
        <router-link to="/institutes/">
          <div class="nav-container">
            <div>
              <i class="material-icons md-32 md-light">home_work</i><br>
              <span>Institute</span>
            </div>
          </div>
        </router-link>
      </li>
    </ul>
    <div class="logout nav">
      <li class="nav-item mb-2" id="account">
        <router-link to="/account">
          <div class="nav-container">
            <div>
              <div class="circle-span mx-auto mt-1">
                <span class="align-middle">{{initials}}</span>
              </div>
              <!-- <span>Account</span> -->
            </div>
          </div>
        </router-link>
      </li>
      <li class="nav-item">
        <div class="text-light" @click="logOut">
          <i class="material-icons md-32 md-light">exit_to_app</i><br>
          <span>Sign out</span>
        </div>
      </li>
    </div>
  </nav>
</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'navbar',
  components: {
    Loading,
  },
  data() {
    return {
      loadScreen: false,
    };
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
    initials() {
      return `${this.userData.givenName[0]}${this.userData.lastName[0]}`;
    },
  },
  methods: {
    loadAccount() {
      this.$router.push('/account');
    },
    logOut() {
      this.loadScreen = true;
      this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/login');
          this.loadScreen = false;
        });
    },
  },
};
</script>

<style scoped>
.circle-span {
  height: 2rem;
  width:  2rem;
  background-color: #ffffff;
  border-radius: 50%;
  font-size: 18px;
  color: #455866;
  margin-bottom: 0.5em;
  text-align: center;
}
.nav-logo {
  height:  2em;
}
.navbar-brand {
  font-family: 'Nunito';
  font-stretch: extra-expanded;
  font-weight: 400;
  color: #fff;
  vertical-align: middle;
}
.navbar {
  background: linear-gradient(90deg, rgba(126,47,26,1) 0%,
    rgba(187,75,37,1) 50%, rgba(233,120,82,1) 100%);
}
.sub-brand {
  margin-left:  -0.5em;
}
#primary-sidebar {
  position: fixed;
  width: 5em;
  font-size: 12px;
  background-color: var(--dark-color);
}

#primary-sidebar ul {
  /*margin-top: 2em;*/
  /*height: 100%;*/
  height:100%;
}

#primary-sidebar .nav-item {
  width: 100%;
}

#primary-sidebar .nav-item:hover {
  cursor: pointer;
}

#primary-sidebar .nav-item.active {
  /*margin-bottom: 2em;*/
  border-left: 0.2rem solid #87ceeb;
  background-color: #536A79 !important;
}

#primary-sidebar .nav-item.active div{
  margin-left: -0.2rem;
}

#primary-sidebar img {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding:  0.5rem;
  width: 100%;
  /*height: 4.5em;*/
}

#primary-sidebar a:hover {
  text-decoration: none;
}

#primary-sidebar > div {
  /*background-color: var(--dark-color-tint);*/
}

.nav-container {
  padding-top: 1em;
  padding-bottom: 1em;
}

#primary-sidebar a {
  font-weight: bold;
  color: #fff;
  text-decoration: none;
}

#primary-sidebar a.router-link-active .nav-container {
  border-left: 0.2rem solid var(--accent-1);
  background-color: var(--dark-color-tint) !important;
  color:  var(--accent-1);
}

#primary-sidebar a.router-link-active .nav-container div{
  margin-left: -0.2rem;
}

.logout {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 1em;
}
</style>

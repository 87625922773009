<template>
  <div>
    <div v-if="preview || ansKey">
      <div class="text-center">
        #{{component.content}}#
      </div>
    </div>
    <div v-else>
      <small class="qn-label"> Equation </small>
      <math-field class="math-field" :id="`eqn${uInd}${type}`" smart-mode="`true`"
      virtual-keyboard-mode="manual">
      </math-field>
    </div>
  </div>
</template>

<script>
import 'mathlive';

export default {
  name: 'Equation',
  props: ['component', 'ind', 'type', 'uInd', 'ansKey', 'preview'],
  computed: {
  },
  watch: {
    preview(newVal) {
      if (newVal) {
        this.mfe.removeEventListener('input', this.updateContent);
        setTimeout(() => {
          window.MathJax.typesetPromise();
        }, 0);
      } else {
        setTimeout(() => {
          this.mfe = document.getElementById(`eqn${this.uInd}${this.type}`);
          this.mfe.addEventListener('input', this.updateContent);
          this.mfe.setValue(this.component.content);
        }, 100);
      }
    },
    ansKey(newVal) {
      console.log(this.ansKey, newVal);
      if (newVal) {
        // this.mfe.removeEventListener('input', this.updateContent);
        setTimeout(() => {
          window.MathJax.typesetPromise();
        }, 0);
      } else {
        setTimeout(() => {
          // this.mfe = new MathfieldElement({
          //   virtualKeyboardMode: 'manual',
          //   smartMode: true,
          //   onContentDidChange: (mathField) => {
          //     this.component.content = mathField.getValue();
          //   },
          // });
          this.mfe = document.getElementById(`eqn${this.uInd}${this.type}`);
          // document.getElementById(`eqn${this.uInd}${this.type}`).appendChild(this.mfe);
          this.mfe.addEventListener('input', this.updateContent);
          this.mfe.setValue(this.component.content);
        }, 100);
      }
    },
  },
  data() {
    return {
      mfe: null,
    };
  },
  methods: {
    updateContent() {
      this.component.content = this.mfe.value;
    },
  },
  mounted() {
    // this.mfe = new MathfieldElement();
    if (!(this.preview || this.ansKey)) {
      this.mfe = document.getElementById(`eqn${this.uInd}${this.type}`);
      this.mfe.addEventListener('input', this.updateContent);
      this.mfe.setValue(this.component.content);
      // this.mfe = new MathfieldElement({
      //   virtualKeyboardMode: 'manual',
      //   smartMode: true,
      //   onContentDidChange: (mathField) => {
      //     this.component.content = mathField.getValue();
      //   },
      // });
      this.mfe = document.getElementById(`eqn${this.uInd}${this.type}`);
      // document.getElementById(`eqn${this.uInd}${this.type}`).appendChild(this.mfe);
      this.mfe.addEventListener('input', this.updateContent);
      this.mfe.setValue(this.component.content);
    } else {
      window.MathJax.typesetPromise();
    }
  },
};
</script>

<style>
/*.ML__virtual-keyboard-toggle {
 display: none !important;
}*/
/*.ML__focused ~ .ML__virtual-keyboard-toggle {
 display: block !important;
}*/
.math-field:not(:focus) {
  background-color: rgba(235, 247, 255, 0.3);
  text-align: center;
  padding: 0 0.5rem;
}

.math-field ~ * {
  display: block !important;
}

.ML__fieldcontainer__field {
  display: block !important;
}
</style>

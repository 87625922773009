<template>
  <div class="row mx-0 p-5 justify-content-center bg-overlay">
    <div class="col-lg-6 col-sm-9 col-xs-8 align-self-center">
      <div style = "border-radius:1em;" class="card p-2 shadow border-0">
        <div class="col-lg-10 mx-auto align-self-center my-auto text-center">
          <div v-if="emailSubmitted===false" class="container my-3 text-center">
            <h2 class="text">Reset your password</h2>
            <form>
              <h5 class="text">Please enter your email</h5>
              <div class="text-start text-secondary my-4 row input-container">
                <label class="form-label fs-5">Email</label>
                <input type="text" name="email"
                class="input-field" v-model="email"
                :class="{ 'is-invalid': submitted && $v.email.$error }"/>
                <span class="input-border"></span>
                <div v-if="submitted && !$v.email.required" class="error">
                Email is required</div>
                <div v-if="submitted && !$v.email.email" class="error">
                Email is invalid</div>
                <div v-if="submitted && !emailValid && $v.email.email" class="error">
                Email is not registered, please register.</div>
              </div>
              <div class="col bg-gradient-primary text-white">
                <button type="button"
                class=" float-end btn button-color btn-lg text-center" @click="checkUser">
                  Submit
                </button>
              </div>
            </form>
          </div>
          <div v-if="emailSubmitted===true">
            <h2 class="text">Reset your password</h2>
            <p>A reset email has been sent. Please check your inbox.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {
  required, email,
} from 'vuelidate/lib/validators';

export default {
  name: 'ForgotPassword',
  components: {
  },
  computed: {
    loadScreen() {
      return this.$store.state.loadScreen;
    },
  },
  data() {
    return {
      email: '',
      submitted: false,
      emailSubmitted: false,
      invalid: false,
      emailValid: null,
      rootUrl: window.location.origin,
    };
  },
  validations: {
    email: { required, email },
  },
  methods: {
    submitEmail() {
      const encEmail = this.encoder64(this.email);
      const payload = {
        email: this.email,
      };
      payload.resetUrl = `${this.rootUrl}/reset/${encEmail}`;
      axios.post('/api/sendReset', payload)
        .then(() => {
          this.emailSubmitted = true;
          this.$store.commit('setLoadScreen', { status: false });
        });
    },
    checkUser() {
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          email: this.email,
        };
        axios.post('/api/checkUser', payload)
          .then((res) => {
            if (res.data.valid === 0) {
              this.$store.commit('setLoadScreen', { status: true });
              this.emailValid = true;
              this.submitEmail();
            } else {
              this.emailValid = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    encoder64(param) {
      const encoded = btoa(param);
      return encoded;
    },
    loginRedirect() {
      this.$router.push('/login');
    },
  },
};
</script>

<style scoped>
  .error {
  color: red;
}

.button-color{
  background-color: #BB4B25 !important;
  color: #FFF !important;
}

</style>

<template>
  <div>
    <div class="col-md-10 col-lg-10 mx-sm-auto pt-5">
      <div class="row">
        <div class="col-3 d-flex flex-column justify-content-between">
          <div class="card left-accent-card" style="height: 49%">
            <div class="card-body" style="overflow-y: scroll">
              <h4 class="card-title">To-do</h4>
              <ul class="list-group list-group-flush">
                <li class="list-group-item" v-for="item, index in incompleteTasks" :key="index">
                  <div>
                    <button class="btn float-right" v-on:click="loadAssessment(item.assid)">
                      <i class="material-icons" data-toggle="tooltip" title="Open">
                        open_in_browser
                      </i>
                    </button>
                    <span><strong> {{item.title}} </strong></span><br>
                    <span class="text-muted"> {{item.course}} </span><br>
                    <span v-if="item.remaining > 1"><em>Due in  {{item.remaining}}  days</em></span>
                    <span class="text-danger" v-else-if="item.remaining == 1">
                      <em>Due tomorrow</em>
                    </span>
                    <span class="text-danger" v-else><em>Due today</em></span>
                  </div>
                </li>
              </ul>
              <p class="card-text" v-if="incompleteTasks.length == 0"></p>
              <em>No incomplete assignments</em>
            </div>
          </div>
          <div class="card left-accent-card" style="height: 49%">
            <div class="card-body" style="overflow-y: scroll">
              <h4 class="card-title">Recently graded</h4>
              <ul class="list-group list-group-flush">
                <li class="list-group-item" v-for="item, index in recentlyGraded" :key="index">
                  <div>
                    <button class="btn float-right" v-on:click="reviewAssessment(item.assid)">
                      <i class="material-icons" data-toggle="tooltip" title="Review">pageview</i>
                    </button>
                    <span><strong> {{item.title}} </strong></span><br>
                    <span class="text-muted">|| {{item.course}} ||</span><br>
                    <span>Score:  {{item.points}} / {{item.totalpoints}} </span>
                  </div>
                </li>
              </ul>
              <p class="card-text" v-if="recentlyGraded.length == 0"></p>
              <em>No recently graded assignments</em>
            </div>
          </div>
        </div>
        <div class="card card-shadow col-9">
          <div class="card-body">
            <div id="calendar-container" class="shadow p-4 mx-auto">
              <FullCalendar ref="calendar" :options="calendarOption" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

// import $ from 'jquery';
import { Popover } from 'bootstrap';
import axios from 'axios';
import FullCalendar from '@fullcalendar/vue';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

export default {
  name: 'TeacherHome',
  components: {
    FullCalendar,
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
  },
  data() {
    return {
      incompleteTasks: [],
      recentlyGraded: [],
      calendarOption: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        headerToolbar: {
          left: 'prev,next today addEventButton',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        navLinks: true,
        dayMaxEventRows: true,
        moreLinkClick: 'day',
        scrollTime: '08:00:00',
        nowIndicator: true,
        initialView: 'dayGridMonth',
        // height: '80vh',
        // contentHeight: 'auto',
        events: this.fetchEvents,
        eventDidMount(arg) {
          // eslint-disable-next-line no-new
          new Popover(arg.el, {
            title: arg.event.title,
            content: arg.event.extendedProps.course,
            trigger: 'hover',
            placement: 'top',
          });
        },
        // eventClick: this.eventClick,
      },
    };
  },
  methods: {
    loadAssessment(assId) {
      const pathvar = `/tasks/${assId}/submit`;
      this.$router.push({ path: pathvar });
    },
    reviewAssessment(key) {
      const pathvar = `/tasks/${key}/review`;
      this.$router.push({ path: pathvar });
    },
    fetchEvents(fetchInfo, callback) {
      axios.get('/api/dashboard/events', {
        params: {
          start: fetchInfo.start,
          end: fetchInfo.end,
          user: this.userData.id,
          role: this.userData.roleId,
        },
      })
        .then((response) => {
          callback(response.data);
        });
    },
  },
  mounted() {
    // axios.get('/api/dashboard')
    //   .then((response) => {
    //     this.ongoingTasks = response.data.ongoing;
    //     this.ungraded = response.data.ungraded;
    //   });
  },
};
</script>
<style scoped>
</style>

<template>
  <div>
    <div class="pt-5">
      <div class="mb-4 mx-0" style="padding: 0px 15px;">
        <h2 class="d-inline-block">My Courses</h2>
        <button class="btn float-end" @click="loadClassCreation" v-if="userData.roleId == 2">
          <i class="align-middle material-icons md-32 md-light">add_circle_outline</i>
        </button>
      </div>
      <div class="course-container" style="padding: 0px 15px;">
        <div class="d-inline-block mx-2 " v-for="course, idx in courselist" :key="idx">
          <div class="course-card card card-shadow"
          style=""
          :style="{ 'border-color': colorArray[idx % 5] }">
            <div class="card-body">
              <h4 class="card-title d-inline">{{ course.title }}</h4>
              <i class="material-icons float-end text-accent-1" type="button"
              @click="loadClassPage(idx)"> open_in_new </i>
            </div>
            <div class="card-footer bg-white border-0">
              <div class="row">
                <div class="col">
                  <i class="align-middle material-icons md-40 md-light">
                    account_circle
                  </i>
                </div>
                <div class="col">
                  {{ course.instructor }}<br>
                  <small class="text-muted">Main instructor</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name: 'Course',
  components: {
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
  },
  data() {
    return {
      courselist: [],
      colorArray: ['#4d9de0', '#e15554', '#e1bc29', '#3bb273', '#37000a'],
    };
  },
  methods: {
    getAllCourses() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.get('/api/course', {
        params: {
          role: this.userData.roleId,
          user: this.userData.id,
        },
      })
        .then((response) => {
          this.courselist = response.data;
          setTimeout(() => {
            this.$store.commit('setLoadScreen', { status: false });
          }, 500);
        });
    },
    loadClassPage(index) {
      this.$router.push(`/courses/${index}/`);
    },
    loadClassCreation() {
      this.$router.push('/courses/create');
    },
  },
  mounted() {
    this.getAllCourses();
  },
};
</script>
<style scoped>
.course-card {
  border-top-style: solid;
  border-width: 0.5rem 0px 0px 0px;
  width:  25rem;
  margin-bottom: 30px;
}
</style>

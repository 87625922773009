import Vue from 'vue';
import Vuelidate from 'vuelidate';
import axios from 'axios';
import './app-insight';
// import firebase from 'firebase';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.css';

// const firebaseConfig = JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG);

// Initialize Firebase
// firebase.initializeApp(firebaseConfig);

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.interceptors.request.use((request) => {
  // add auth header with jwt if access_token is found
  const accessToken = localStorage.getItem('access_token');
  const isLoggedIn = accessToken !== null;
  const isApiUrl = request.url.startsWith('/api') && request.baseURL === process.env.VUE_APP_API_URL;
  if (isLoggedIn && isApiUrl) {
    // eslint-disable-next-line no-param-reassign
    request.headers.Authorization = `Bearer ${accessToken}`;
  }

  return request;
});
axios.interceptors.response.use((response) => response, (error) => {
  store.commit('setLoadScreen', { status: false });
  if (error.response && (error.response.status === 401 || error.response.status === 403)) {
    if (error.response.data && error.response.data.message) {
      store.commit('setError', { status: true, errorMessage: error.response.data.message });
      // TODO: redirect to login page
    } else {
      store.commit('setError', { status: true, errorMessage: error.response.statusText });
    }
  } else {
    store.commit('setError', { status: true });
  }
  return Promise.reject(error);
});

Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.use(CKEditor);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

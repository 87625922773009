import Vue from 'vue';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

if (process.env.VUE_APP_APP_INSIGHTS_CONNECTION_STRING) {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: process.env.VUE_APP_APP_INSIGHTS_CONNECTION_STRING,
      appId: 'pple-lms-fe',
      enableAutoRouteTracking: true,
      /* ...Other Configuration Options... */
    },
  });
  appInsights.loadAppInsights();
  Vue.appInsights = appInsights;
}

<template>
  <!-- Free Response -->
  <div class="fr-cont" v-if="qInfo['type']==0">
    <div v-if="active">
      <div class="f-response q-field col-8 mx-auto my-2 p-4 selected-qn" v-if="!aKey">
        <div class=" mb-1 q-label">
          <small class="bg-light text-muted px-1">Free Response </small>
        </div>
        <input v-if="qInfo['title']!=null" type="text" v-model="qInfo['title']"
        :placeholder="'Question ' + qNo" class="my-2 t-fr h6">
        <h6 v-else class="my-2"> Question {{qNo}}
          <i @click="delQuestion"
            class=" material-icons text-danger float-end e_btn align-middle">
            delete
          </i>
        </h6>
        <!-- <div class="mt-4 container bg-light rounded p-1 pt-2">
          <p class="ms-2 pre-title text-muted bg-white px-2 rounded"> preview </p>
          <p class="my-4 text-muted" v-if="qInfo['problem'].length==0 && !qInfo['image']">
            Enter Problem Statement
          </p>
          <p class="my-4 mathjax p-pre" v-else><em v-html="qInfo['problem']"></em></p>
        </div> -->

        <div class="component-container" v-for="pItem, pInd in qInfo['problem']"
          :key="pInd">
          <RichText :component="pItem" :ind="qNo" :preview="false"
          v-if="pItem.type==='richText'">
          </RichText>
          <Equation :component="pItem" :ind="pInd" :u-ind="`${qNo}_${pInd}`"
            type="fr-akey" :preview="false"
            v-else-if="pItem.type==='equation'">
          </Equation>
        </div>

        <!-- <div class="row ms-0 mt-2 edit-box">
          <div type="text" id="pStatement" name="problemStatement"
          data-ph="Enter Problem Statement" class="px-0 py-1 t-fr col-sm-10"
          @focus="restoreSelection()" @input="updateProblem($event,'problem')"
          @click="saveSelection()" @blur="saveSelection()" v-html="pContent" contenteditable>
          </div>
          <div class="col-sm-2 text-center align-items-middle my-auto h-100">
            <input name="p-image" id="p-image" type="file" accept="image/jpeg image/png"
            @change="uploadImage($event,'problem')">
            <label for="p-image" type="button" class="align-middle btn p-0">
              <i class=" material-icons text-secondary align-self-center md-32 my-1">
              insert_photo</i>
            </label><br/>
            <button v-show="subtype!='Snack'" type="button" class="btn align-middle p-0"
              @click="mountML">
              <i class=" material-icons text-secondary align-self-center md-32 my-1">
              calculate</i>
            </button>
          </div>
        </div> -->

        <div class="add-fr-component">
          <div>
            <button class="btn btn-sm btn-outline" @click="addComponent('richText')">
              <i class="material-icons align-middle"> text_fields </i>
            </button>
            <button class="btn btn-sm btn-outline" @click="addComponent('equation')">
              <i class="material-icons align-middle"> functions </i>
            </button>
          </div>
          <small> Select a component to add </small>
        </div>

        <div id="equationmodal" class = "modal fade" role="dialog">
          <div class="modal-dialog">
            <form method="POST" @submit="addEqn('problem')" @submit.prevent>
              <fieldset class="form-group">
                <div class="modal-content site-config-box">
                  <div class="modal-header">
                    <h5 class="modal-title" > Create Equation </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                    @click="unmountML"></button>
                  </div>

                  <div class="modal-body">
                    <div id="mathField" class="border rounded border-primary">
                    </div>
                    <div id="output"></div>
                  </div>

                  <div class="modal-footer">
                    <input type="submit" value="Save" class="btn btn-primary btn-sm
                    text-white mt-3">
                    <button type="button" class="btn btn-danger btn-sm
                    text-white mt-3" data-bs-dismiss="modal" @click="unmountML"> Cancel </button>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        <div class="row align-items-center mt-3">
          <div class="col">
            <p class="text-primary text-small e_btn px-0 mt-3" @click="activeAkey">
              <i class="material-icons align-middle">assignment_turned_in</i>
              Answer Key
            </p>
          </div>
          <div class="col">
            <div class="text-end align-middle">
              <input class= "d-inline point-input text-center" type="number" step="1" min="0"
                v-model="qInfo['points']">
              <p class="d-inline text-end text-muted mt-4 mb-0 me-0 my-auto px-0">
                points
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="f-response q-field col-8 mx-auto my-2 p-4 selected-qn" v-else>
        <div class=" mb-1 q-label">
          <small class="bg-light text-muted px-1">Free Response </small>
        </div>
        <h6 class="my-2" v-if="qInfo['title']"> {{qInfo['title']}}
          <i data-html2canvas-ignore="true" @click="delQuestion"
          class="material-icons text-danger float-end e_btn align-middle">delete</i>
        </h6>
        <h6 class="my-2" v-else> Question {{qNo}}
          <i data-html2canvas-ignore="true" @click="delQuestion"
          class="material-icons text-danger float-end e_btn align-middle">delete</i>
        </h6>
        <!-- <div v-show="qInfo['image']" class="mt-4 mb-2">
          <img v-bind:src="qInfo['image']" class="d-flex img-pre mx-auto" />
        </div> -->
        <p class="my-4" v-if="qInfo['problem'].length==0 && !qInfo['image']">
          <em> "Problem Statement"</em>
        </p>
        <!-- <p class="my-4 mathjax p-pre" v-else><em v-html="qInfo['problem']"></em></p> -->
        <div class="component-container" v-for="pItem, pInd in qInfo['problem']"
          :key="pInd" v-else>
          <RichText :component="pItem" :ind="qNo" :preview="true"
          v-if="pItem.type==='richText'">
          </RichText>
          <Equation :component="pItem" :ind="pInd" :u-ind="`${qNo}_${pInd}`"
            type="fr-akey" :preview="true"
            v-else-if="pItem.type==='equation'">
          </Equation>
        </div>
        <p class="mt-2">
          Enter key words and/or phrases. Each key word must be on a new line.
        </p>

        <div class="mt-4 container bg-light rounded p-1 pt-2">
          <p class="ms-2 pre-title text-muted bg-white px-2 rounded"> preview </p>
          <p class="my-4 text-muted" v-if="qInfo['solution'].length==0">Enter Solution </p>
          <p class="my-4 mathjax p-pre" v-else><em v-html="qInfo['solution']"></em></p>
        </div>

        <div class="row ms-0 mt-2 edit-box">
          <div type="text" id="pStatement" name="answerKey" data-ph="Enter Solution"
          class="px-0 py-1 t-fr col-sm-10"
          @focus="restoreSelection()" @input="updateAkey"
          @click="saveSelection()" @blur="saveSelection()" v-html="akContent" contenteditable>
          </div>
          <div v-show="subtype!='Snack'"
          class="col-sm-2 text-center align-items-middle my-auto h-100">
            <input name="p-image" id="p-image" type="file" accept="image/jpeg image/png"
            @change="uploadImage($event,'solution')">
            <label for="p-image" type="button" class="align-middle btn p-0">
              <i class=" material-icons text-secondary algin-self-center md-32 my-1">
                insert_photo
              </i>
            </label><br/>
            <button type="button" class="btn align-middle p-0" data-toggle="modal"
            data-target="#equationmodal" @click="mountML">
              <i class=" material-icons text-secondary algin-self-center md-32 my-1">
                calculate
              </i>
            </button>
          </div>
        </div>

        <div id="equationmodal" class = "modal fade" role="dialog">
          <div class="modal-dialog">
            <form method="POST" @submit="addEqn('solution')" @submit.prevent>
              <fieldset class="form-group">
                <div class="modal-content site-config-box">
                  <div class="modal-header">
                    <h5 class="modal-title" > Create Equation </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                    @click="unmountML"></button>
                  </div>

                  <div class="modal-body">
                    <div id="mathField" class="border rounded border-primary">
                    </div>
                    <div id="output"></div>
                  </div>

                  <div class="modal-footer">
                    <input type="submit" value="Save" class="btn btn-primary btn-sm
                    text-white mt-3">
                    <button type="button" class="btn btn-danger btn-sm
                    text-white mt-3" data-bs-dismiss="modal" @click="unmountML"> Cancel </button>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        <p class="mb-0 text-start text-muted">{{qInfo['points']}} points </p>
        <p type="button" class="mb-0 btn btn-outline-primary btn-small float-end"
        @click="deactiveAkey">Done</p>
      </div>
    </div>

    <div v-else>
      <div class="f-response q-field col-8 mx-auto my-2 px-4 pb-4 pt-0 "
      v-if="qInfo['type']==0">
        <div class="handle  text-center">
          <i class="material-icons handle-ns"> drag_handle </i>
        </div>
        <div class=" mb-1 q-label">
          <small class="bg-light text-muted px-1">Free Response </small>
        </div>
        <h6 class="h6 mt-2 q-no" v-if="qInfo['title']"> {{qInfo['title']}}
          <i data-html2canvas-ignore="true" @click="delQuestion"
          class=" material-icons text-danger float-end e_btn align-middle">delete</i>
        </h6>
        <h6 class="mt-2 q-no" v-else> Question {{qNo}}
          <i data-html2canvas-ignore="true" @click="delQuestion"
          class=" material-icons text-danger float-end e_btn align-middle">delete</i>
        </h6>
        <!-- <div v-show="qInfo['image']" class="mt-4 mb-2 img-container">
          <img v-bind:src="qInfo['image']" class="d-flex img-pre mx-auto" />
        </div> -->
        <p class="my-4" v-if="qInfo['problem'].length==0 && !qInfo['image']">
          Enter Problem Statement
        </p>
        <!-- <p class="my-4 mathjax p-pre" v-else><em v-html="qInfo['problem']"></em></p> -->
        <div class="component-container" v-for="pItem, pInd in qInfo['problem']"
          :key="pInd" v-else>
          <RichText :component="pItem" :ind="qNo" :preview="true"
          v-if="pItem.type==='richText'">
          </RichText>
          <Equation :component="pItem" :ind="pInd" :u-ind="`${qNo}_${pInd}`"
            type="fr-akey" :preview="true"
            v-else-if="pItem.type==='equation'">
          </Equation>
        </div>
        <input type="text"
        class="mt-2 mb-4 t-answer" placeholder="Student's answer here..." disabled>
        <p class="text-end text-muted">{{qInfo['points']}} points </p>
      </div>
    </div>
  </div>

  <!-- Multiple Choice Questions -->
  <div class="mc-cont" v-else-if="qInfo['type']==1">
    <div v-if="active">
      <div class="mc-response q-field col-8 mx-auto my-2 p-4 selected-qn" v-if="!aKey">
        <div class=" mb-1 q-label">
          <small class="bg-light text-muted px-1">Multiple Choice </small>
        </div>
        <input v-if="qInfo['title']!=null" type="text" v-model="qInfo['title']"
        :placeholder="'Question ' + qNo" class="my-2 t-fr h6">
        <h6 v-else class="my-2"> Question {{qNo}}
          <i @click="delQuestion" class="material-icons text-danger float-end e_btn align-middle">
            delete
          </i>
        </h6>
        <!-- <div class="mt-4 container bg-light rounded p-1 pt-2 e_btn"
          :class="aField=='p' && 'active-sel'">
          <p class="ms-2 pre-title text-muted bg-white px-2 rounded"> preview </p>
          <div v-show="qInfo['image']" class="mt-4 mb-2 img-container">
            <span class="mx-auto">
              <span type="button" class="delete-img" @click="delImage">
                <i class="material-icons bg-danger text-white rounded-circle">cancel</i>
              </span>
              <img v-bind:src="qInfo['image']" class="d-flex img-pre" />
            </span>
          </div>
          <p class="my-4 text-muted" v-if="qInfo['problem'].length==0 && !qInfo['image']"
          @click="aField='p'">Enter Problem Statement </p>
          <p class="my-4 mathjax p-pre" v-else @click="aField='p'">
            <em v-html="qInfo['problem']"></em>
          </p>
        </div> -->
        <!-- <div class="row ms-0 mt-2 edit-box" v-show="aField=='p'">
          <div type="text" id="pStatement" name="problemStatement"
            data-ph="Enter Problem Statement" class="px-0 py-1 t-fr col-sm-10"
            @focus="restoreSelection()" @input="updateProblem($event,'problem')"
            @click="saveSelection()" @blur="saveSelection()"  v-html="pContent" contenteditable>
          </div>
          <div class="col-sm-2 text-center align-items-middle my-auto h-100">
            <input name="p-image" id="p-image" type="file" accept="image/jpeg image/png"
            @change="uploadImage($event,'problem')">
            <label for="p-image" type="button" class="align-middle btn p-0">
              <i class=" material-icons text-secondary algin-self-center md-32 my-1">
                insert_photo
              </i>
            </label><br/>
            <button v-show="subtype!='Snack'" type="button" class="btn align-middle p-0"
              data-toggle="modal" data-target="#equationmodal" @click="mountML">
              <i class=" material-icons text-secondary algin-self-center md-32 my-1">calculate</i>
            </button>
          </div>
        </div> -->
        <div class="component-container" v-for="pItem, pInd in qInfo['problem']"
          :key="pInd">
          <RichText :component="pItem" :ind="qNo" :preview="false"
          v-if="pItem.type==='richText'">
          </RichText>
          <Equation :component="pItem" :ind="pInd" :u-ind="`${qNo}_${pInd}`"
            type="fr-akey" :preview="false"
            v-else-if="pItem.type==='equation'">
          </Equation>
        </div>
        <div class="add-fr-component">
          <div>
            <button class="btn btn-sm btn-outline" @click="addComponent('richText')">
              <i class="material-icons align-middle"> text_fields </i>
            </button>
            <button class="btn btn-sm btn-outline" @click="addComponent('equation')">
              <i class="material-icons align-middle"> functions </i>
            </button>
          </div>
          <small> Select a component to add </small>
        </div>
        <div id="equationmodal" class = "modal fade" role="dialog">
          <div class="modal-dialog">
            <form method="POST" @submit="addEqn('problem')" @submit.prevent>
              <fieldset class="form-group">
                <div class="modal-content site-config-box">
                  <div class="modal-header">
                    <h5 class="modal-title" > Create Equation </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                    @click="unmountML"></button>
                  </div>
                  <div class="modal-body">
                    <div id="mathField" class="border rounded border-primary">
                    </div>
                    <div id="output"></div>
                  </div>
                  <div class="modal-footer">
                    <input type="submit" value="Save" class="btn btn-primary btn-sm
                    text-white mt-3">
                    <button type="button" class="btn btn-danger btn-sm
                    text-white mt-3" data-bs-dismiss="modal" @click="unmountML">
                      Cancel
                    </button>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        <div class="form-check my-2 h-100 pl-0" v-for="o,ind in qInfo['options']"
        :key="`O${ind}`">
          <div class="row p-2 rounded" :class="aField==ind && 'active-sel'">
            <div class="col-1">
              <button @click="delOption(ind)" class="btn pt-0 material-icons e_btn pl-0">
                remove_circle
              </button>
            </div>
            <div class="col-11 e_btn">
              <p class="my-4 mathjax p-pre d-inline" @click="aField=ind"
                v-if="qInfo['options'][ind].length>0">
                <em v-html="qInfo['options'][ind]"></em>
              </p>
              <p class="text-muted my-4 mathjax p-pre d-inline" @click="aField=ind" v-else>
                <em>Click to edit</em>
              </p>
            </div>
          </div>
          <div class="row ms-0 mt-2 edit-box-o" v-show="aField==ind">
            <div type="text" :id="'option'+ind" name="problemStatement"
              data-ph="Enter answer option" class="p-pre px-0 py-1 t-fr col-sm-10 e-field"
              @input="updateOption($event,ind)" v-html="oContent[ind]"
              @blur="updateOfield(ind); saveSelection();" contenteditable>
            </div>
            <div v-show="subtype!='Snack'"
              class="col-sm-2 text-center align-items-middle my-auto h-100">
              <input class="o-image" :name="'o-image'+ind" :id="'o-image'+ind" type="file"
              accept="image/jpeg image/png" @change="uploadImage($event,'option',ind)">
              <label :for="'o-image'+ind" type="button" class="align-middle btn p-0">
                <i class=" material-icons text-secondary algin-self-center md-32 my-1">
                  insert_photo
                </i>
              </label><br/>
              <button type="button" class="btn align-middle p-0" @click="mountOML(ind)">
                <i class=" material-icons text-secondary algin-self-center md-32 my-1">calculate</i>
              </button>
            </div>
          </div>
          <div :id="'oequationmodal'+ind" class = "modal fade" role="dialog">
            <div class="modal-dialog">
              <form method="POST" @submit="addOEqn(ind)" @submit.prevent>
                <fieldset class="form-group">
                  <div class="modal-content site-config-box">
                    <div class="modal-header">
                      <h5 class="modal-title" > Create Equation </h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal"
                      @click="unmountOML(ind)"></button>
                    </div>
                    <div class="modal-body">
                      <div :id="'mathField'+ind" class="border rounded border-primary">
                      </div>
                      <div id="output"></div>
                    </div>
                    <div class="modal-footer">
                      <input type="submit" value="Save" class="btn btn-primary btn-sm
                      text-white mt-3">
                      <button type="button" class="btn btn-danger btn-sm
                      text-white mt-3" data-bs-dismiss="modal" @click="unmountOML(ind)">
                      Cancel
                    </button>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
          <hr class="text-muted">
        </div>
        <p class="e_btn ms-4 text-primary" @click="addOption"> <small>Add another option</small></p>
        <p class="text-primary text-small float-start e_btn px-0 mt-3" @click="activeAkey">
          <i class="material-icons align-middle">assignment_turned_in</i>
          Answer Key
        </p>
        <div class="mt-3 mx-0 float-end">
          <input class= "d-inline point-input text-end" type="number" step="1" min="0"
            v-model="qInfo['points']">
          <p class="d-inline text-end text-muted mt-4 mb-0 me-0 my-auto px-0">points </p>
        </div>
      </div>
      <div class="mc-response q-field col-8 mx-auto my-2 p-4 selected-qn" v-else>
        <div class=" mb-1 q-label">
          <small class="bg-light text-muted px-1">Multiple Choice </small>
        </div>
        <h6 class="my-2" v-if="qInfo['title']"> {{qInfo['title']}}
          <i data-html2canvas-ignore="true" @click="delQuestion"
            class="material-icons text-danger float-end e_btn align-middle">delete</i>
        </h6>
        <h6 class="my-2" v-else> Question {{qNo}}
          <i data-html2canvas-ignore="true" @click="delQuestion"
            class="material-icons text-danger float-end e_btn align-middle">delete</i>
        </h6>
        <div v-show="qInfo['image']" class="mt-4 mb-2">
          <img v-bind:src="qInfo['image']" class="d-flex img-pre mx-auto" />
        </div>
        <p class="my-4" v-if="qInfo['problem'].length==0 && !qInfo['image']">
          <em> "Problem Statement"</em>
        </p>
        <!-- <p class="my-4 mathjax" v-else><em v-html="qInfo['problem']"></em></p> -->
        <div class="component-container" v-for="pItem, pInd in qInfo['problem']"
          :key="pInd" v-else>
          <RichText :component="pItem" :ind="qNo" :preview="true"
          v-if="pItem.type==='richText'">
          </RichText>
          <Equation :component="pItem" :ind="pInd" :u-ind="`${qNo}_${pInd}`"
            type="fr-akey" :preview="true"
            v-else-if="pItem.type==='equation'">
          </Equation>
        </div>

        <p> Select the correct answer choice: </p>
        <div class="form-check my-2" v-for="o,ind in qInfo['options']" :key="`O${ind}`">
          <input class="form-check-input" type="radio" id:="ind" name="answer_1"
            v-bind:value="o" v-model="qInfo['solution']">
          <p class="my-4 mathjax p-pre d-inline" v-if="o.length>0">
            <em v-html="qInfo['options'][ind]"></em>
          </p>
          <label for="ind" v-else> Enter answer choice </label>
        </div>
        <p class="mb-0 text-start text-muted">{{qInfo['points']}} points </p>
        <p type="button" class="mb-0 btn btn-outline-primary btn-small float-end"
          @click="deactiveAkey">Done</p>
      </div>
    </div>
    <div v-else>
      <div class="mc-response q-field col-8 mx-auto my-2 px-4 pb-4 pt-0">
        <div class="handle  text-center"> <i class="material-icons handle-ns"> drag_handle </i>
        </div>
        <div class=" mb-1 q-label">
          <small class="bg-light text-muted px-1">Multiple Choice </small>
        </div>
        <h6 class="my-2" v-if="qInfo['title']"> {{qInfo['title']}}
          <i data-html2canvas-ignore="true" @click="delQuestion"
            class="material-icons text-danger float-end e_btn align-middle">delete</i>
        </h6>
        <h6 class="my-2" v-else> Question {{qNo}}
          <i data-html2canvas-ignore="true" @click="delQuestion"
            class="material-icons text-danger float-end e_btn align-middle">delete</i>
        </h6>
        <div v-show="qInfo['image']" class="mt-4 mb-2">
          <img v-bind:src="qInfo['image']" class="d-flex img-pre mx-auto" />
        </div>
        <p class="my-4" v-if="qInfo['problem'].length==0 && !qInfo['image']">
          Enter Problem Statement
        </p>
        <!-- <p class="my-4 mathjax p-pre" v-else><em v-html="qInfo['problem']"></em></p> -->
        <div class="component-container" v-for="pItem, pInd in qInfo['problem']"
          :key="pInd" v-else>
          <RichText :component="pItem" :ind="qNo" :preview="true"
          v-if="pItem.type==='richText'">
          </RichText>
          <Equation :component="pItem" :ind="pInd" :u-ind="`${qNo}_${pInd}`"
            type="fr-akey" :preview="true"
            v-else-if="pItem.type==='equation'">
          </Equation>
        </div>
        <div class="form-check my-2" v-for="o,ind in qInfo['options']" :key="`O${ind}`">
          <input class="form-check-input" type="radio" id:="ind" name="answer_1" value="ind">
          <p class="my-4 mathjax p-pre d-inline" v-if="o.length>0">
            <em v-html="qInfo['options'][ind]"></em>
          </p>
          <label for="ind" v-else> Enter answer choice </label>
        </div>
        <p class="text-end text-muted"> {{qInfo['points']}} points </p>
      </div>
    </div>
  </div>

  <!-- Section Header -->
  <div class="fr-cont section-card" v-else-if="qInfo['type']==2">
    <div v-if="active">
      <div class="divider-sl"> Section {{sNo+1}}/{{sLen}} </div>
      <div class="s-header q-field col-8 mx-auto mb-2 px-4 pb-4 pt-0 selected-qn">
        <div class="row ms-0 mt-4">
          <div data-ph="Section Title"  class="px-0 py-1 t-fr col-sm-8 h5" v-html="qInfo['title']"
            @focusout="updateTitle" contenteditable>
          </div>
          <i data-html2canvas-ignore="true" @click="delSection"
            class=" material-icons text-danger col-sm-2 text-end ms-sm-auto
              pr-2 e_btn my-auto">
              delete_sweep
          </i>
        </div>
        <div data-ph="Description" class="px-0 py-1 t-fr col-sm-8" v-html="qInfo['desc']"
          @focusout="updateDesc" contenteditable>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="divider"> Section {{sNo+1}}/{{sLen}} </div>
      <div class="s-header q-field col-8 mx-auto mb-2 px-4 pb-4 pt-0 ">
        <p class="text-muted divider-print"> Section {{sNo+1}}/{{sLen}} </p>
        <div class="row ms-0 mt-4">
          <h5 class="px-0 py-1 col-sm-8" v-if="qInfo['title']" v-html="qInfo['title']">
          </h5>
          <h5 class="px-0 py-1 text-muted col-sm-8 h5" v-else >
            <em> Section Title </em>
          </h5>
          <i data-html2canvas-ignore="true" @click="delSection"
            class=" material-icons text-danger col-sm-2 text-end ms-sm-auto
              pr-2 e_btn my-auto">
              delete_sweep
          </i>
        </div>
        <div data-ph="Description" class="px-0 py-1 col-sm-8" v-html="qInfo['desc']"
          v-if="qInfo['desc']">
        </div>
        <p data-ph="Description" class="px-0 py-1 col-sm-8 text-muted" v-else>
          <em>Description</em>
        </p>
      </div>
    </div>
  </div>

  <!-- Title/Instructions -->
  <div class="fr-cont" v-else-if="qInfo['type']==3">
    <div v-if="active">
      <div class="f-response q-field col-8 mx-auto mb-2 px-4 pb-4 pt-0 selected-qn">
        <div class="row ms-0 mt-4">
          <div data-ph="Title"  class="px-0 py-1 t-fr col-sm-8 h5" v-html="qInfo['title']"
            @focusout="updateTitle" contenteditable>
          </div>
          <i @click="delQuestion" class="material-icons text-danger
            col-sm-2 text-end ms-sm-auto me-1 pr-2 e_btn my-auto">delete</i>
        </div>
        <div data-ph="Description" class="px-0 py-1 t-fr col-sm-8" v-html="qInfo['desc']"
          @focusout="updateDesc" contenteditable>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="f-response q-field col-8 mx-auto mb-2 px-4 pb-4 pt-0 ">
        <div class="row ms-0 mt-4">
          <h5 class="px-0 py-1 col-sm-8" v-if="qInfo['title']">
            {{qInfo['title']}}
          </h5>
          <h5 class="px-0 py-1 text-muted col-sm-8 h5" v-else >
            <em> Title </em>
          </h5>
          <i data-html2canvas-ignore="true" @click="delQuestion" class="material-icons
            text-danger col-sm-2 text-end ms-sm-auto me-1 pr-2 e_btn my-auto">
            delete
          </i>
        </div>
        <div data-ph="Description" class="px-0 py-1 col-sm-8"
          v-html="qInfo['desc']" v-if="qInfo['desc']">
        </div>
        <p data-ph="Description" class="px-0 py-1 col-sm-8 text-muted" v-else>
          <em>Description</em>
        </p>
      </div>
    </div>
  </div>

  <!-- Short Answer -->
  <div class="fr-cont" v-else-if="qInfo['type']==4">
    <div v-if="active">
      <div class="f-response q-field col-8 mx-auto my-2 p-4 selected-qn" v-if="!aKey">
        <div class=" mb-1 q-label">
          <small class="bg-light text-muted px-1">Short Answer</small>
        </div>
        <input v-if="qInfo['title']!=null" type="text" v-model="qInfo['title']"
          :placeholder="'Question ' + qNo" class="my-2 t-fr h6">
        <h6 v-else class="my-2"> Question {{qNo}}
          <i @click="delQuestion" class=" material-icons text-danger float-end e_btn align-middle">
            delete
          </i>
        </h6>
        <!-- <div class="mt-4 container bg-light rounded p-1 pt-2">
          <p class="ms-2 pre-title text-muted bg-white px-2 rounded"> preview </p>
          <p class="my-4 text-muted" v-if="qInfo['problem'].length==0 && !qInfo['image']">
            Enter Problem Statement
          </p>
          <p class="my-4 mathjax p-pre" v-else><em v-html="qInfo['problem']"></em></p>
        </div> -->
        <!-- <div class="row ms-0 mt-2 edit-box">
          <div type="text" id="pStatement" name="problemStatement"
            data-ph="Enter Problem Statement" class="px-0 py-1 t-fr col-sm-10"
            @focus="restoreSelection()" @input="updateProblem($event,'problem')"
            @click="saveSelection()" @blur="saveSelection()" v-html="pContent" contenteditable>
          </div>
          <div class="col-sm-2 text-center align-items-middle my-auto h-100">
            <input name="p-image" id="p-image" type="file" accept="image/jpeg image/png"
              @change="uploadImage($event,'problem')">
            <label for="p-image" type="button" class="align-middle btn p-0">
              <i class=" material-icons text-secondary algin-self-center md-32 my-1">
                insert_photo
              </i>
            </label><br/>
            <button v-show="subtype!='Snack'" type="button" class="btn align-middle p-0"
              data-toggle="modal" data-target="#equationmodal" @click="mountML">
              <i class=" material-icons text-secondary algin-self-center md-32 my-1">calculate</i>
            </button>
          </div>
        </div> -->
        <div class="component-container" v-for="pItem, pInd in qInfo['problem']"
          :key="pInd">
          <RichText :component="pItem" :ind="qNo" :preview="false"
          v-if="pItem.type==='richText'">
          </RichText>
          <Equation :component="pItem" :ind="pInd" :u-ind="`${qNo}_${pInd}`"
            type="fr-akey" :preview="false"
            v-else-if="pItem.type==='equation'">
          </Equation>
        </div>
        <div class="add-fr-component">
          <div>
            <button class="btn btn-sm btn-outline" @click="addComponent('richText')">
              <i class="material-icons align-middle"> text_fields </i>
            </button>
            <button class="btn btn-sm btn-outline" @click="addComponent('equation')">
              <i class="material-icons align-middle"> functions </i>
            </button>
          </div>
          <small> Select a component to add </small>
        </div>
        <div id="equationmodal" class = "modal fade" role="dialog">
          <div class="modal-dialog">
            <form method="POST" @submit="addEqn('problem')" @submit.prevent>
              <fieldset class="form-group">
                <div class="modal-content site-config-box">
                  <div class="modal-header">
                    <h5 class="modal-title" > Create Equation </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                    @click="unmountML"></button>
                  </div>
                  <div class="modal-body">
                    <div id="mathField" class="border rounded border-primary"
                    >
                    </div>
                    <div id="output"></div>
                  </div>
                  <div class="modal-footer">
                    <input type="submit" value="Save" class="btn btn-primary btn-sm
                    text-white mt-3">
                    <button type="button" class="btn btn-danger btn-sm
                    text-white mt-3" data-bs-dismiss="modal" @click="unmountML"> Cancel </button>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        <p class="text-primary text-small float-start e_btn px-0 mt-3" @click="activeAkey">
          <i class="material-icons align-middle">assignment_turned_in</i>
          Answer Key
        </p>
        <div class="mt-3 mx-0 float-end">
          <input class= "d-inline point-input text-end" type="number" step="1" min="0"
            v-model="qInfo['points']">
          <p class="d-inline text-end text-muted mt-4 mb-0 me-0 my-auto px-0">points </p>
        </div>
      </div>
      <div class="f-response q-field col-8 mx-auto my-2 p-4 selected-qn" v-else>
        <div class=" mb-1 q-label">
          <small class="bg-light text-muted px-1">Short Answer</small>
        </div>
        <h6 class="my-2" v-if="qInfo['title']"> {{qInfo['title']}}
          <i data-html2canvas-ignore="true" @click="delQuestion"
            class="material-icons text-danger float-end e_btn align-middle">
            delete
          </i>
        </h6>
        <h6 class="my-2" v-else> Question {{qNo}}
          <i data-html2canvas-ignore="true" @click="delQuestion"
            class="material-icons text-danger float-end e_btn align-middle">
            delete
          </i>
        </h6>
        <div v-show="qInfo['image']" class="mt-4 mb-2">
          <img v-bind:src="qInfo['image']" class="d-flex img-pre mx-auto" />
        </div>
        <p class="my-4" v-if="qInfo['problem'].length==0 && !qInfo['image']">
          <em> "Problem Statement"</em>
        </p>
        <!-- <p class="my-4 mathjax p-pre" v-else><em v-html="qInfo['problem']"></em></p> -->
        <div class="component-container" v-for="pItem, pInd in qInfo['problem']"
          :key="pInd" v-else>
          <RichText :component="pItem" :ind="qNo" :preview="true"
          v-if="pItem.type==='richText'">
          </RichText>
          <Equation :component="pItem" :ind="pInd" :u-ind="`${qNo}_${pInd}`"
            type="fr-akey" :preview="true"
            v-else-if="pItem.type==='equation'">
          </Equation>
        </div>
        <p class="mt-2"> Enter any acceptable answer separated by commas. </p>
        <input type="text" id="pStatement" name="answerKey" placeholder="Enter Solution"
          class="px-0 py-1 t-fr col-sm-10"
          @focus="restoreSelection()"
          @click="saveSelection()" @blur="saveSelection()" v-model="qInfo['solution']" />
        <p class="mb-0 text-start text-muted">{{qInfo['points']}} points </p>
        <p type="button" class="mb-0 btn btn-outline-primary btn-small float-end"
          @click="deactiveAkey">Done</p>
      </div>
    </div>
    <div v-else>
      <div class="f-response q-field col-8 mx-auto my-2 px-4 pb-4 pt-0 ">
        <div class="handle  text-center">
          <i class="material-icons handle-ns"> drag_handle </i>
        </div>
        <div class=" mb-1 q-label">
          <small class="bg-light text-muted px-1">Short Answer</small>
        </div>
        <h6 class="my-2" v-if="qInfo['title']"> {{qInfo['title']}}
          <i data-html2canvas-ignore="true" @click="delQuestion"
            class="material-icons text-danger float-end e_btn align-middle">delete</i>
        </h6>
        <h6 class="my-2" v-else> Question {{qNo}}
          <i data-html2canvas-ignore="true" @click="delQuestion"
            class="material-icons text-danger float-end e_btn align-middle">delete</i>
        </h6>
        <div v-show="qInfo['image']" class="mt-4 mb-2 img-container">
          <img v-bind:src="qInfo['image']" class="d-flex img-pre mx-auto" />
        </div>
        <p class="my-4" v-if="qInfo['problem'].length==0 && !qInfo['image']">
          Enter Problem Statement
        </p>
        <!-- <p class="my-4 mathjax p-pre" v-else><em v-html="qInfo['problem']"></em></p> -->
        <div class="component-container" v-for="pItem, pInd in qInfo['problem']"
          :key="pInd" v-else>
          <RichText :component="pItem" :ind="qNo" :preview="true"
          v-if="pItem.type==='richText'">
          </RichText>
          <Equation :component="pItem" :ind="pInd" :u-ind="`${qNo}_${pInd}`"
            type="fr-akey" :preview="true"
            v-else-if="pItem.type==='equation'">
          </Equation>
        </div>
        <input type="text" class="mt-2 mb-4 t-answer"
          placeholder="Student's answer here..." disabled>
        <p class="text-end text-muted">{{qInfo['points']}} points </p>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { Modal } from 'bootstrap';
import { makeMathField } from 'mathlive/dist/mathlive.min.js';
import Equation from '@/components/EquationEditor.vue';
import RichText from '@/components/RichTextEditor.vue';

export default {
  name: 'ProblemTemplate',
  props: ['qInfo', 'qNo', 'sNo', 'sLen'],
  components: {
    Equation,
    RichText,
  },
  data() {
    return {
      active: false,
      aKey: false,
      aField: null,
      editor: null,
      savedRange: null,
      activeSct: false,
      previewImage: null,
      pContent: '',
      akContent: null,
      oContent: [],
      isInFocus: false,
      eqn: '',
      mathField: null,
      fileName: '',
      subtype: 'subscription',
      eqnModal: null,
      oEqnModal: null,
      components: {
        richText: {
          type: 'richText',
          content: '',
        },
        equation: {
          type: 'equation',
          content: '',
        },
      },
    };
  },
  methods: {
    addComponent(component) {
      this.qInfo.problem.push(JSON.parse(JSON.stringify(this.components[component])));
    },
    setActive() {
      this.active = true;
      this.aField = 'p';
      this.reRender();

      this.resetEditor();
      this.initContedit();
      // this.updatePfield();

      // console.log("setting active");
      // CKEDITOR.replace('problemEdit');
    },

    setInactive() {
      this.active = false;
      this.aKey = false;
      this.reRender();
      // console.log("setting inactive")
      this.updatePfield('problem');
      this.updatePfield('solution');
      this.resetEditor();
    },

    addOption() {
      const temp = this.qInfo.options;
      temp.push('');
      this.qInfo.options = temp;
      this.$forceUpdate();
    },

    delOption(index) {
      const temp = this.qInfo.options;
      temp.splice(index, 1);
      this.qInfo.options = temp;
      this.$forceUpdate();
    },

    activeAkey() {
      this.aKey = true;
      this.reRender();
      this.updatePfield('problem');
      this.resetEditor();
    },

    deactiveAkey() {
      this.aKey = false;
      this.aField = 'p';
      this.updatePfield('solution');
      this.reRender();
      this.resetEditor();
      this.initContedit();
      // this.updatePfield();
    },

    rText() {
      this.getCursorPosition();

      // if(!this.editor){
      //  this.editor= CKEDITOR.inline('problemStatement');
      // }
      // else {
      //  this.editor.destroy();
      //  this.editor= CKEDITOR.inline('problemStatement');
      // }

      // el.setAttribute("v-html","qInfo['solution']");
    },

    resetEditor() {
      // if(this.editor){
      //  this.editor.destroy();
      //  this.editor = null;
      // }

    },

    updateProblem(event, field) {
      const el = event.target;
      const tempC = el.innerHTML;
      // this.saveSelection();
      this.qInfo[field] = tempC;
      this.reRender();
      // this.restoreSelection();

      // this.reRender();
    },
    updateAkey(event) {
      const el = event.target;
      const tempC = el.innerHTML;
      // this.saveSelection();
      this.qInfo.solution = tempC;
      this.reRender();
      // this.restoreSelection();

      // this.reRender();
    },

    updateOption(event, ind) {
      const el = event.target;
      const tempC = el.innerHTML;
      // console.log(ind,tempC)
      // this.saveSelection();
      // let tempArr = this.qInfo;
      const tempArr = this.qInfo.options.filter((item) => item);

      tempArr.splice(ind, 1, tempC);
      this.qInfo.options = tempArr;
      // console.log(tempArr,this.qInfo['options'])
      // Vue.set(this.qInfo['options'],ind,tempC);
      this.reRender();
    },

    updatePfield(field) {
      // const el = document.getElementById("pStatement");
      // var images = el.getElementsByTagName("img");

      // for(var i =0; i<images.length; i++){
      //   if(images[i].src.substr(0,10) === "data:image"){

      //       // console.log(file)
      //   };
      // }
      if (field === 'problem') {
        this.pContent = this.qInfo.problem;
      } else if (field === 'solution') {
        this.akContent = this.qInfo.solution;
      }

      this.isInFocus = false;
      // this.saveSelection();
    },

    updateOfield(ind) {
      // console.log(ind,this.qInfo['options'][ind])
      this.oContent[ind] = this.qInfo.options[ind];
    },

    getCursorPosition() {
      const element = document.getElementById('pStatement');
      let caretOffset = 0;
      const doc = element.ownerDocument || element.document;
      const win = doc.defaultView || doc.parentWindow;
      let sel;
      if (typeof win.getSelection !== 'undefined') {
        sel = win.getSelection();
        if (sel.rangeCount > 0) {
          const range = win.getSelection().getRangeAt(0);
          const preCaretRange = range.cloneRange();
          preCaretRange.selectNodeContents(element);
          preCaretRange.setEnd(range.endContainer, range.endOffset);
          caretOffset = preCaretRange.toString().length;
        }
      } else if ((sel === doc.selection) && sel.type !== 'Control') {
        const textRange = sel.createRange();
        const preCaretTextRange = doc.body.createTextRange();
        preCaretTextRange.moveToElementText(element);
        preCaretTextRange.setEndPoint('EndToEnd', textRange);
        caretOffset = preCaretTextRange.text.length;
      }
      // console.log(caretOffset);
      return caretOffset;
    },

    saveSelection() {
      if (window.getSelection) { // non IE Browsers
        this.savedRange = window.getSelection().getRangeAt(0);
      } else if (document.selection) { // IE
        this.savedRange = document.selection.createRange();
      }
      // console.log(this.savedRange)
    },

    restoreSelection() {
      this.isInFocus = true;
      document.getElementById('pStatement').focus();
      if (this.savedRange != null) {
        if (window.getSelection) { // non IE and there is already a selection
          const s = window.getSelection();
          if (s.rangeCount > 0) s.removeAllRanges();
          s.addRange(this.savedRange);
        } else if (document.createRange) { // non IE and no selection
          window.getSelection().addRange(this.savedRange);
        } else if (document.selection) { // IE
          this.savedRange.select();
        }
      }
    },

    cancelEvent(e) {
      if (this.isInFocus === false && this.savedRange != null) {
        if (e && e.preventDefault) {
          // alert("FF");
          e.stopPropagation(); // DOM style (return false doesn't always work in FF)
          e.preventDefault();
        } else {
          window.event.cancelBubble = true;// IE stopPropagation
        }
        this.restoreSelection();
        return false; // false = IE style
      }
      return true;
    },

    mountML() {
      // document.getElementById('mathField').addEventListener('input', this.getLatex);
      this.eqnModal = new Modal(document.getElementById('equationmodal'), {});
      this.eqnModal.show();
      this.mathField = makeMathField('mathField', {
        smartMode: false,
        smartSuperscript: true,
        virtualKeyboardMode: 'manual',
        customVirtualKeyboardLayers: {
          'arrow-layer': {
            styles: '',
            rows: [
              [
                {
                  class: 'keycap',
                  latex: '\\rightarrow',
                },
                {
                  class: 'keycap',
                  latex: '\\leftarrow',
                },
                {
                  class: 'keycap',
                  latex: '\\rightleftarrows',
                },
                {
                  class: 'keycap',
                  latex: '\\leftrightarrows',
                },
              ],
              [
                {
                  class: 'keycap',
                  latex: '\\rightharpoonup',
                },
                {
                  class: 'keycap',
                  latex: '\\leftharpoonup',
                },
                {
                  class: 'keycap',
                  latex: '\\rightharpoondown',
                },
                {
                  class: 'keycap',
                  latex: '\\leftharpoondown',
                },
              ],
              [
                {
                  class: 'keycap',
                  latex: '\\rightleftharpoons',
                },
                {
                  class: 'keycap',
                  latex: '\\leftrightharpoons',
                },
              ],
            ],
          },
          'matrice-layer': {
            styles: '',
            rows: [
              [
                {
                  class: 'keycap',
                  insert: '\\begin{bmatrix} \\placeholder{} & \\placeholder{} \\end{bmatrix}',
                  label: '2x1',
                },
                {
                  class: 'keycap',
                  insert: '\\begin{bmatrix} \\placeholder{} \\\\ \\placeholder{} \\end{bmatrix}',
                  label: '1x2',
                },
                {
                  class: 'keycap',
                  insert: '\\begin{bmatrix} \\placeholder{} & \\placeholder{} \\\\'
                    + '\\placeholder{} & \\placeholder{} \\end{bmatrix}',
                  label: '2x2',
                },
              ],
            ],
          },
        },
        customVirtualKeyboards: {
          arrows: {
            label: '&#x21C4;',
            tooltip: 'Arrows',
            layer: 'arrow-layer',
          },
          matrice: {
            label: 'Mat',
            tooltip: 'Matrices',
            layer: 'matrice-layer',
          },
        },
        virtualKeyboards: 'arrows matrice all',
        onContentDidChange: (mathField) => {
          const latex = mathField.getValue('latex-expanded');
          this.eqn = latex;
          // document.getElementById('latex').innerHTML = escapeHtml(
          //     latex
          // );

          //   const mathJSON = MathLive.latexToAST(latex);
          //   document.getElementById('mathjson').innerHTML = escapeHtml(
          //       JSON.stringify(mathJSON)
          //  );
        },
      });
      // console.log(mathField)
    },

    mountOML(ind) {
      // document.getElementById('mathField').addEventListener('input', this.getLatex);
      this.oEqnModal = new Modal(document.getElementById(`oequationmodal${ind}`), {});
      this.oEqnModal.show();
      this[`mathField${String(ind)}`] = makeMathField(`mathField${ind}`, {
        smartMode: false,
        smartSuperscript: true,
        virtualKeyboardMode: 'manual',
        customVirtualKeyboardLayers: {
          'layer-name': {
            styles: '',
            rows: [
              [
                {
                  class: 'keycap',
                  latex: '\\rightarrow',
                },
                {
                  class: 'keycap',
                  latex: '\\leftarrow',
                },
                {
                  class: 'keycap',
                  latex: '\\rightleftarrows',
                },
                {
                  class: 'keycap',
                  latex: '\\leftrightarrows',
                },
              ],
              [
                {
                  class: 'keycap',
                  latex: '\\rightharpoonup',
                },
                {
                  class: 'keycap',
                  latex: '\\leftharpoonup',
                },
                {
                  class: 'keycap',
                  latex: '\\rightharpoondown',
                },
                {
                  class: 'keycap',
                  latex: '\\leftharpoondown',
                },
              ],
              [
                {
                  class: 'keycap',
                  latex: '\\rightleftharpoons',
                },
                {
                  class: 'keycap',
                  latex: '\\leftrightharpoons',
                },
              ],

            ],
          },
        },
        customVirtualKeyboards: {
          arrows: {
            label: '&#x21C4;',
            tooltip: 'Arrows',
            layer: 'layer-name',
          },
        },
        virtualKeyboards: 'arrows all',
        onContentDidChange: (mathField) => {
          const latex = mathField.getValue();
          this.eqn = latex;
          // document.getElementById('latex').innerHTML = escapeHtml(
          //     latex
          // );

          //   const mathJSON = MathLive.latexToAST(latex);
          //   document.getElementById('mathjson').innerHTML = escapeHtml(
          //       JSON.stringify(mathJSON)
          //  );
        },
      });
      // console.log(mathField)
    },

    unmountML() {
      // document.querySelector('mathField').value = '';
      // document.getElementById('mathField').removeEventListener('input', this.getLatex);
      this.mathField.setValue('');
    },

    unmountOML(ind) {
      // document.querySelector('mathField').value = '';
      // document.getElementById('mathField').removeEventListener('input', this.getLatex);
      this[`mathField${ind}`].setValue('');
    },

    getLatex(event) {
      document.getElementById('output').innerHTML = event.target.getValue();
      this.eqn = event.target.getValue();
    },

    addEqn(field) {
      this.eqn = `#${this.eqn}#`;
      this.addToExp(this.eqn.replace(/(\\mleft|\\mright|\^\^\^\^200b)/g, ''), field);
      this.unmountML();
      this.eqnModal.hide();
    },

    addOEqn(ind) {
      this.eqn = `#${this.eqn}#`;
      this.addToOExp(this.eqn.replace(/(\\mleft|\\mright|\^\^\^\^200b)/g, ''), ind);
      this.unmountOML(ind);
      this.oEqnModal.hide();
    },

    updateTitle(event) {
      this.qInfo.title = event.target.innerHTML;
    },

    updateDesc(event) {
      this.qInfo.desc = event.target.innerHTML;
    },

    reRender() {
      // this.$parent.updateMath();
      setTimeout(() => {
        window.MathJax.typesetPromise();
      }, 0);
    },

    addToExp(exp, field) {
      const d1 = document.getElementById('pStatement');
      if (!this.savedRange) {
        this.savedRange = document.createRange();
        this.savedRange.setStart(d1, 0);
        this.savedRange.setEnd(d1, 0);
      }
      this.savedRange.insertNode(document.createTextNode(exp));
      // console.log(this.savedRange,'\n',exp);
      // if (d1.childElementCount == 0) {
      //  exp = "<p>" + exp + "</p>";
      //  d1.insertAdjacentHTML('beforeEnd', exp);

      //  console.log("if")
      // } else {
      //  d1.childNodes[0].insertAdjacentText('beforeEnd', exp);
      // }

      // exp = "<p>" + exp + "</p>";
      // d1.insertAdjacentText('beforeEnd', exp);

      // if (d1.childElementCount == 0) {
      // exp = "<p>" + exp + "</p>";
      // d1.insertAdjacentText('beforeEnd', exp);
      // this.savedRange.insertNode(document.createTextNode(exp));

      // } else {
      // d1.childNodes[d1.childNodes.length-1].insertAdjacentText('beforeEnd', exp);
      // this.savedRange.insertNode(document.createTextNode(exp));
      // }

      // console.log(d1.innerHTML);
      this.qInfo[field] = d1.innerHTML;
      this.reRender();
    },

    addToOExp(exp, ind) {
      const d1 = document.getElementById(`option${String(ind)}`);
      if (!this.savedRange) {
        this.savedRange = document.createRange();
        this.savedRange.setStart(d1, 0);
        this.savedRange.setEnd(d1, 0);
      }
      this.savedRange.insertNode(document.createTextNode(exp));
      // console.log(this.savedRange,'\n',exp);
      // if (d1.childElementCount == 0) {
      //  exp = "<p>" + exp + "</p>";
      //  d1.insertAdjacentHTML('beforeEnd', exp);

      //  console.log("if")
      // } else {
      //  d1.childNodes[0].insertAdjacentText('beforeEnd', exp);
      // }

      // exp = "<p>" + exp + "</p>";
      // d1.insertAdjacentText('beforeEnd', exp);

      // if (d1.childElementCount == 0) {
      // exp = "<p>" + exp + "</p>";
      // d1.insertAdjacentText('beforeEnd', exp);
      // this.savedRange.insertNode(document.createTextNode(exp));

      // } else {
      // d1.childNodes[d1.childNodes.length-1].insertAdjacentText('beforeEnd', exp);
      // this.savedRange.insertNode(document.createTextNode(exp));
      // }

      // console.log(d1.innerHTML);
      this.qInfo.options.splice(ind, 1, d1.innerHTML);
      this.reRender();
    },

    initContedit() {
      document.execCommand('defaultParagraphSeparator', false, 'br');
    },

    uploadImage(e, field, ind) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      // console.log(field);
      if (field === 'option') {
        const elementId = `option${String(ind)}`;
        reader.onload = (event) => {
          const img = document.createElement('img');
          img.src = event.target.result;
          if (!this.savedRange) {
            this.savedRange = document.createRange();
          }
          const el = document.getElementById(elementId);
          let elementLen = 0;
          if (el.childNodes.length > 0) {
            elementLen = el.childNodes.length;
          }
          this.savedRange.setStart(el, elementLen);
          this.savedRange.setEnd(el, elementLen);
          this.savedRange.insertNode(img);
          this.oContent[ind] = el.innerHTML;
          this.addToOExp(' ', ind);
        };
      } else {
        reader.onload = (event) => {
          const img = document.createElement('img');
          img.src = event.target.result;
          if (!this.savedRange) {
            this.savedRange = document.createRange();
            const el = document.getElementById('pStatement');
            this.savedRange.setStart(el, 0);
            this.savedRange.setEnd(el, 0);
          }
          this.savedRange.insertNode(img);
          // console.log(typeof this.savedRange);
          this.addToExp(' ', field);
        };
      }

      // let formData = new FormData();
      // formData.append('file', image)
      // axios.post("/api/assessment/uploadimage", formData,
      // {
      //   headers: {
      //     'Content-Type': 'multipart/form-data'
      //   }
      // })
      // .then(response => {
      //   this.qInfo['image'] = response.data.url;
      //   this.fileName = response.data.fileName;
      // })
    },

    delQuestion() {
      this.deactiveAkey();
      this.setInactive();
      this.$emit('del-question', this.qNo, this.sNo);
    },

    delImage() {
      axios.post('api/assessment/deleteimage', {
        fileName: this.fileName,
      })
        .then(() => { this.qInfo.image = null; });
    },

    delSection() {
      this.setInactive();
      this.$emit('del-section', this.sNo);
    },

  },

  mounted() {
    if (this.qInfo.image) {
      const tempFileName = this.qInfo.image.split('/');
      this.fileName = tempFileName[tempFileName.length - 1];
    }

    if (this.qInfo.problem) {
      this.pContent = this.qInfo.problem;
    }

    if (this.qInfo.solution) {
      this.akContent = this.qInfo.solution;
    }

    this.oContent = this.qInfo.options;

    // this.saveSelection();

    // const mathField = MathLive.makeMathField(document.getElementById('mathField'), {
    //   onContentDidChange: (mathField) => {
    //       console.log(mathField.getValue());
    //   }
    // });
  },
};
</script>
<style>
.add-fr-component {
  border:  dashed #ddd 2px;
  height: 4rem;
  margin: 1rem 2rem;
  vertical-align: middle;
  text-align: center;
  font-size: 0.75rem;
}
.add-fr-component i {
  font-size: 1.25rem;
  color:  var(--oxford-blue);
}
</style>

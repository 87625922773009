<template>
  <div>
    <h2 class="d-inline-block">Add a Course</h2>
  </div>
</template>

<script>

export default {
  name: 'NewCourse',
  components: {
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
  },
  data() {
    return {
    };
  },
  mounted() {
  },
};
</script>
<style scoped>
</style>

<template>
  <div>
    <Navbar></Navbar>
    <router-view/>
  </div>
</template>

<script>
import Navbar from './Navbar.vue';

export default {
  components: {
    Navbar,
  },
  computed: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  },
};
</script>

<style>
div.dataTables_filter input{
  border:  0;
  border-bottom: 5px #bb4b25;
  background-color: #dcf3f9;
}
div.dataTables_filter input:focus{
  background-color: #F5D6CC;
  outline:  none;
}
</style>

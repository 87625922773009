<template>
  <div>
    <div class="col-md-7 col-lg-8 mx-auto py-4">
      <div class="card shadow my-5">
        <div class="card-body">
          <h3 class="card-title text-pri mb-2">Institutes</h3>
          <table id="instTable" class="display table table-hover">
            <thead class="bg-dark text-white">
              <tr>
                <th scope="col"> ID </th>
                <th scope="col"> Institute Name</th>
                <th scope="col"> Teacher Code </th>
                <th scope="col"> Student Code </th>
                <th scope="col"> Subscription </th>
                <th scope="col"> Stripe ID</th>
                <th scope="col"> Expire Date </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="inst, ind in instData" :key="ind"
              @click="loadInst(inst)" class="clickable">
                <td>{{inst.id}}</td>
                <td>{{inst.name}}</td>
                <td>{{inst.teacherCode}}</td>
                <td>{{inst.studentCode}}</td>
                <td>{{inst.subscription}}</td>
                <td class="text-center">{{inst.stripeId}}</td>
                <td>{{inst.expireDate}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Modal } from 'bootstrap';
import {
  required, email,
} from 'vuelidate/lib/validators';

export default {
  name: 'AdminInst',
  components: {
  },
  validations: {
    edit: {
      instName: { required },
      teacherCode: { required },
      email: {
        required,
        email,
      },
    },
  },
  computed: {
  },
  data() {
    return {
      instData: [],
      instFocus: null,
      submitted: false,
      invalid: false,
      fId: '',
      editModal: null,
      editFocus: {},
      edit: {
        instName: null,
        teacherCode: null,
        studentCode: null,
        subscription: null,
        stripeId: null,
        expireDate: null,
      },
    };
  },
  methods: {
    getInst() {
      this.$store.commit('setLoadScreen', { status: false });
      axios.get('/api/admin/getInst', {
      })
        .then((response) => {
          this.instData = response.data.institutes;
          console.log(this.instData);
          setTimeout(() => {
            this.table = $('#instTable').DataTable({
              scrollY: '23rem',
              scrollCollapse: true,
              fixedHeader: true,
              autoWidth: false,
              info: false,
              searching: true,
              paging: true,
              retrieve: true,
              ordering: true,
            });
          }, 10);
        });
      // .catch((error) => {
      //   if (error.response.status === 403) {
      //     this.$router.replace({ path: '/forbidden/' });
      //   }
      // });
    },
    loadInst(inst) {
      this.$router.push(`${inst.id}/info`);
    },
    loadinstDetails(inst) {
      this.editModal = new Modal(document.getElementById('detailsModal', {}));
      this.editFocus = inst;
      console.log(inst);
      [this.fId, this.edit.instName, this.edit.teacherCode, this.edit.studentCode,
        this.edit.subscription, this.edit.stripeId, this.edit.expireDate] = inst;
      this.editModal.show();
    },
    updateDetails() {
      this.submitted = true;
      if (!this.$v.$invalid) {
        this.$store.commit('setLoadScreen', { status: true });
        const payload = {
          id: this.fId,
          instname: this.edit.instName,
          teachercode: this.edit.teacherCode,
          studentcode: this.edit.studentCode,
          stripeid: this.edit.stripeId,
          expiredate: this.edit.expireDate,
        };
        axios.post('/api/admin/updateInst', payload)
          .then(() => {
            this.submitted = false;
            this.editModal.hide();
            this.getinsts();
            this.$store.commit('setLoadScreen', { status: false });
          }).catch((err) => {
            console.error(err);
            this.getinstData();
            this.$store.commit('setLoadScreen', { status: false });
          });
      }
    },
    loadDelete(ind) {
      this.deleteModal = new Modal(document.getElementById('deleteAssModal', {}));
      this.instFocus = ind;
      console.log(this.instFocus);
      this.deleteModal.show();
    },
  },
  mounted() {
    this.getInst();
  },
};
</script>

<style>
div.dataTables_filter input{
  border:  0;
  border-bottom: 5px #bb4b25;
  background-color: #dcf3f9;
}
div.dataTables_filter input:focus{
  background-color: #F5D6CC;
  outline:  none;
}
</style>

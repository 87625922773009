<template>
  <div>
    <Navbar></Navbar>
    <TeacherHome v-if="userData.roleId == 2"></TeacherHome>
    <StudentHome v-else-if="userData.roleId == 1"></StudentHome>
    <ITHome v-else-if="userData.roleId == 4"></ITHome>
    <AdminUser v-else-if="userData.roleId ==5"></AdminUser>
    <div v-else> Who Are You? </div>
  </div>
</template>

<script>

import TeacherHome from '../components/TeacherHome.vue';
import StudentHome from '../components/StudentHome.vue';
import ITHome from '../components/ITHome.vue';
import AdminUser from '../components/AdminUser.vue';
import Navbar from '../components/Navbar.vue';

export default {
  name: 'Home',
  components: {
    TeacherHome,
    StudentHome,
    ITHome,
    AdminUser,
    Navbar,
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
  },
  data() {
    return {
      mode: null,
      code: null,
    };
  },
  mounted() {
    console.log(this.$store.state.userData);
  },
};
</script>
<style scoped>
</style>

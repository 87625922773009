<template>
  <div class="bg-overlay">
    <div class="col-md-7 col-lg-8 mx-auto py-4">
      <div class="modal fade" id="deleteAssModal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteAssModalLabel">
              This action cannot be undone</h5>
              <button type="button" class="btn-close"
                data-bs-dismiss="modal" aria-label="Close">
                </button>
            </div>
            <div class="modal-body">
              Do you wish to proceed?
            </div>
            <div class="modal-footer">
              <button type="button" class="btn bg-acc2"
              data-bs-dismiss="modal">Cancel</button>
              <button type="button" class="btn bg-ter"
              v-on:click="deleteUser()">Confirm</button>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <div type="button" class="btn bg-light text-blue
        rounded-pill shadow ms-2"
        @click="changeTab('info')">
          Account
        </div>
        <div type="button" class="btn bg-dark text-white
        rounded-pill shadow ms-2"
        @click="changeTab('users')">
          Users
        </div>
        <!-- <label>
          <input type="radio" id="members" :value="2" class="tab-radio"
            v-model="activeTab">
          <div type="button" class="btn background-dark text-dark
          rounded-pill shadow ms-2">
            Analytics
          </div>
        </label> -->
      </div>
      <div class="card shadow my-4 pt-2">
        <div class="card-body">
          <h3 class="card-title mb-2 text-pri">Users</h3>
          <table id="usersTable" class="display table table-hover" style="width:100%">
            <thead class="bg-dark text-white">
              <tr>
                <th scope="col"> ID </th>
                <th scope="col"> Given Name </th>
                <th scope="col"> Last Name </th>
                <th scope="col"> Email </th>
                <th scope="col"> Institution </th>
                <th scope="col"> Verified </th>
                <th scope="col"> Role </th>
                <!-- <th scope="col"> Last Login </th> -->
                <!-- <th class="text-center">Actions</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="user, ind in userData" :key="ind"
              @click="loadUser(user)" class="clickable">
                <td>{{user.id}}</td>
                <td>{{user.givenName}}</td>
                <td>{{user.lastName}}</td>
                <td>{{user.email}}</td>
                <td>{{user.school}}</td>
                <td class="text-center">{{user.verified}}</td>
                <td class="text-center">{{user.role}}</td>
                <!-- <td>
                  <p v-if="user.lastLogin">
                    {{new Date(user[6]).toLocaleString('en-UK', {})}}
                  </p>
                  <p v-else> N/A </p>
                </td> -->
                <!-- <td class="text-center">
                  <a><span type="button" class="btn material-icons"
                  @click="loadUserDetails(user)">edit</span></a>
                  <a><span type="button" class="btn material-icons"
                  @click="loadDelete(user)">delete</span> </a>
                </td> -->
                <!-- <td class="">{{student.attempts}}
                  <button type="button" class="btn btn-sm bg-dark text-white ms-3"
                  @click="resetAttempt(student)"> Reset </button>
                </td>
                <td>
                  <p v-if="student.flags">
                    Low Severity: {{student.flags.leave}} <br/>
                    High Severity: {{student.flags.switch}}
                  </p>
                  <p v-else> N/A </p>
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net';
import { Modal } from 'bootstrap';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import {
  required, email,
} from 'vuelidate/lib/validators';

const stubTitleMap = {
  info: 'Account Info',
  users: 'Users',
  // analytics: 'Analytics',
};

export default {
  name: 'AdminUser',
  components: {
  },
  validations: {
    edit: {
      givenName: { required },
      lastName: { required },
      email: {
        required,
        email,
      },
    },
  },
  computed: {
  },
  data() {
    return {
      userData: [],
      userFocus: null,
      submitted: false,
      invalid: false,
      instId: null,
      fId: '',
      editModal: null,
      editFocus: {},
      edit: {
        givenName: null,
        lastName: null,
        email: null,
        verified: null,
      },
    };
  },
  methods: {
    changeTab(stub) {
      this.selectedTab = stubTitleMap[stub];
      this.$router.push(stub);
    },
    getInstUsers() {
      this.$store.commit('setLoadScreen', { status: false });
      axios.get('/api/admin/getInstUsers', {
        params: {
          iId: this.instId,
        },
      })
        .then((response) => {
          this.userData = response.data.users;
          console.log(this.userData);
          setTimeout(() => {
            this.table = $('#usersTable').DataTable({
              scrollY: '23rem',
              scrollCollapse: true,
              fixedHeader: true,
              autoWidth: false,
              info: false,
              searching: true,
              paging: true,
              retrieve: true,
              ordering: true,
            });
          }, 10);
        });
      // .catch((error) => {
      //   if (error.response.status === 403) {
      //     this.$router.replace({ path: '/forbidden/' });
      //   }
      // });
    },
    loadUser(user) {
      this.$router.push(`/users/${user.id}/${user.role}/info`);
    },
    loadUserDetails(user) {
      this.editModal = new Modal(document.getElementById('detailsModal', {}));
      this.editFocus = user;
      console.log(user);
      [this.fId, this.edit.givenName, this.edit.lastName, this.edit.email, ,
        this.edit.verified] = user;
      this.editModal.show();
    },
    updateDetails() {
      this.submitted = true;
      if (!this.$v.$invalid) {
        this.$store.commit('setLoadScreen', { status: true });
        const payload = {
          id: this.fId,
          givenname: this.edit.givenName,
          lastname: this.edit.lastName,
          email: this.edit.email,
          verified: this.edit.verified,
        };
        axios.post('/api/admin/updateUsers', payload)
          .then(() => {
            this.submitted = false;
            this.editModal.hide();
            this.getUsers();
            this.$store.commit('setLoadScreen', { status: false });
          }).catch((err) => {
            console.error(err);
            this.getUserData();
            this.$store.commit('setLoadScreen', { status: false });
          });
      }
    },
    loadDelete(ind) {
      this.deleteModal = new Modal(document.getElementById('deleteAssModal', {}));
      this.userFocus = ind;
      console.log(this.userFocus);
      this.deleteModal.show();
    },
    deleteUser() {
      axios.post('/api/deleteUser', {
        // eslint-disable-next-line no-underscore-dangle
        fId: this.userFocus.fId,
      }).then(() => {
        this.getUsers();
        this.deleteModal.hide();
      }).catch((err) => {
        throw err;
      });
    },
  },
  mounted() {
    this.instId = this.$route.params.iid;
    console.log(this.instId);
    this.getInstUsers();
  },
};
</script>

<style>
div.dataTables_filter input{
  border:  0;
  border-bottom: 5px #bb4b25;
  background-color: #dcf3f9;
}
div.dataTables_filter input:focus{
  background-color: #F5D6CC;
  outline:  none;
}
</style>

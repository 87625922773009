<template>
  <div style="padding-top: 2rem">
    <div class="row p-0 mx-auto mb-2 g-0" id="course-page-header">
      <div class="col text-start">
        <h1 class="d-inline-block me-3">{{ title }}</h1>
        <span v-show="userData.roleId == 2">
          <span class="badge bg-accent-1" v-if="published">Published</span>
          <span class="badge background-dark" v-else>Not Published</span>
        </span>
      </div>
      <div class="col text-end">
        <div class="dropdown">
          <button type="button" class="btn bg-white rounded-pill shadow float-end"
          id="account-menu-btn" data-bs-toggle="dropdown">
            {{ selectedTab }}
            <i class="material-icons align-middle" id="toggle-arrow">
            </i>
          </button>
          <ul class="account-menu dropdown-menu dropdown-menu-end">
            <!-- <li class="dropdown-item">
              <button class="btn bg-white rounded-pill shadow"
              @click="changeTab('dashboard')">Dashboard</button>
            </li> -->
            <li class="dropdown-item">
              <button class="btn bg-white rounded-pill shadow"
              @click="changeTab('dashboard')">Dashboard</button>
            </li>
            <li class="dropdown-item">
              <button class="btn bg-white rounded-pill shadow"
              @click="changeTab('modules')">Modules</button>
            </li>
            <li class="dropdown-item">
              <button class="btn bg-white rounded-pill shadow"
                      @click="changeTab('sessions')">Sessions</button>
            </li>
            <li class="dropdown-item">
              <button class="btn bg-white rounded-pill shadow"
              @click="changeTab('announcements')">Announcements</button>
            </li>
            <li class="dropdown-item" v-show="userData.roleId == 2">
              <button class="btn bg-white rounded-pill shadow"
              @click="changeTab('tasks')">Assignments</button>
            </li>
            <li class="dropdown-item">
              <button class="btn bg-white rounded-pill shadow"
              @click="changeTab('grades')">Grades</button>
            </li>
            <li class="dropdown-item">
              <button class="btn bg-white rounded-pill shadow"
              @click="changeTab('info')">Course Information</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div id="activeTab">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import 'bootstrap';

const stubTitleMap = {
  announcements: 'Announcements',
  tasks: 'Assignments',
  info: 'Course Information',
  dashboard: 'Dashboard',
  sessions: 'Sessions',
  grades: 'Grades',
  modules: 'Modules',
};

export default {
  name: 'CoursePage',
  components: {
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
    published() {
      return this.$store.state.courseInfo.published;
    },
    title() {
      return this.$store.state.courseInfo.title;
    },
  },
  data() {
    return {
      courseId: null,
      selectedTab: 'Dashboard',
      activeItem: [true, false, false, false, false],
    };
  },
  methods: {
    getCourseInfo() {
      this.$store
        .dispatch('loadCourseInfo', { id: this.courseId })
        .catch((error) => {
          if (error.response.status === 403) {
            this.$router.replace({ path: '/forbidden/' });
          }
        });
    },
    changeTab(stub) {
      this.selectedTab = stubTitleMap[stub];
      this.$router.push(stub);
    },
  },
  mounted() {
    this.courseId = this.$route.params.cid;
    // this.changeTab()
    const routeSplit = this.$route.path.split('/');
    this.selectedTab = stubTitleMap[routeSplit[routeSplit.length - 1]];
    this.getCourseInfo();
  },
};
</script>
<style scoped>
#course-page-header {
  max-width: 1400px;
}

#secondary-sidebar {
  position: fixed;
  width: 15em;
  background-color: #f9f5f1;
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.1);
  padding-top: 4.5em;
}
.toggle-tab {
  /*display: block;*/
  width: 40%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  color: #fff;
  background-color: var(--dark-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

}
/*.dropdown-menu {
  width:  40%;
}

.dropdown-menu li {
  padding:  0.5rem 1rem;
  font-size: 14pt;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: var(--accent-1);
}*/

.account-menu {
  text-align: right !important;
  border: none !important;
  background: none !important;
}

#account-menu-btn.show {
  background-color: #002147 !important;
  color: #fff;
}

.dropdown-item:hover {
  background-color: transparent !important;
}

.dropdown-item button:hover {
  background-color: #F5F5F5 !important;
}
#toggle-arrow::after {
  content: "arrow_drop_down";
}
.show #toggle-arrow::after {
  content: "arrow_drop_up" !important;
}
</style>

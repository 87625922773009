<template>
  <div>
    <Navbar></Navbar>
    <div class="col-10 mx-auto py-4">
      <button type="button" class="btn btn-large my-4 text-dark"
      @click="$router.go(-1)">
        <i class="material-icons md-40 align-middle"> arrow_back </i>
        <h5 class="d-inline align-middle">Go Back</h5>
      </button>
      <div class="card top-accent-card mb-5 shadow">
        <div class="card-body">
          <div class="row">
            <div class="col-9">
              <h3 class="card-title text-rust">{{aInfo.title}}</h3>
              <h5 class="card-subtitle mb-2 text-muted" v-if="aInfo.aType">
                Type: {{aInfo.aType.type}}
              </h5>
            </div>
            <div class="col-2 text-end ms-auto align-items-center">
              <button type="button" class="btn btn-outline" @click="loadEdit()">
                <i class="material-icons text-secondary align-middle"> edit </i>
                <p class="d-inline my-auto"> Edit Details </p>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-4 my-auto">
              <div class="rounded p-2 bg-dark text-white me-1">
                <p class="card-text">
                  Started: <b>{{new Date(aInfo.schedUTC).toLocaleString('en-UK', {})}}</b>
                </p>
                <p class="card-text">Duration: <b>{{aInfo.totTime}}</b> mins</p>
              </div>
            </div>
            <div class="col-3 my-auto">
              <div class="rounded p-2 bg-dark text-white mx-1">
                <p class="card-text">Total Points: <b>{{aInfo.totPoints}}</b></p>
                <p class="card-text">Allowed Attempts: <b>{{aInfo.att}}</b></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="detailsModal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Edit assignment details</h5>
              <button type="button" class="btn-close"
                data-bs-dismiss="modal" aria-label="Close">
              </button>
            </div>
            <div class="modal-body">
              <label for="edit-title">Title</label>
              <input id="edit-title" type="text" class="form-control" v-model="edit.editTitle"
              :class="{ 'is-invalid': submitEdit && $v.edit.editTitle.$error }"/>
              <div v-if="submitEdit && !$v.edit.editTitle.required"
              class="form-text error">Title is required
              </div>
              <div>
                <label class="mt-2" for="dur"> Duration </label><br />
                <input v-model="edit.editDuration" id="dur" autocomplete="off"
                type="number" step="1" :min="aInfo.totTime" class="form-control d-inline"
                :class="{ 'is-invalid': submitEdit && $v.edit.editDuration.$error }"/>
                <div v-if="submitEdit && !$v.edit.editDuration.required"
                class="error form-text">
                  Duration is required
                </div>
                <div v-if="submitEdit && !$v.edit.editDuration.minValue"
                class="error form-text">
                  Please enter a positive number
                </div>
              </div>
              <div>
                <label class="mt-2" for="att"> Attempts allowed </label><br />
                <input v-model="edit.editAttempts" id="att" autocomplete="off" type="number"
                step="1" :min="aInfo.att" class="form-control d-inline"
                :class="{ 'is-invalid': submitEdit && $v.edit.editAttempts.$error }"/>
                <div v-if="submitEdit && !$v.edit.editAttempts.required"
                class="error form-text">
                  Number of Attmepts is required
                </div>
                <div v-if="submitEdit && !$v.edit.editAttempts.minValue"
                class="error form-text">
                  Please enter a positive number
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn bg-acc2" data-bs-dismiss="modal">
                Cancel
              </button>
              <button v-if="save=='Save'" type="button" class="btn bg-pri"
                @click="updateDetails()">Save</button>
              <button v-else-if="save=='Saving...'" type="button" class="btn btn-warn"
                @click="updateDetails()">Saving...</button>
              <button v-if="save=='Saved!'" type="button" class="btn btn-success"
                @click="updateDetails()">Saved!</button>
            </div>
          </div>
        </div>
      </div>

      <div class="card-group">
        <div class="card mx-1 rounded card-shadow bg-dark text-white">
          <div class="card-body">
            <div class="row mx-0">
              <div class="col-8">
                <h5 class="card-title"> {{ totalOpened }} student(s)</h5>
                <p class="card-text"> opened </p>
              </div>
              <div class="col-3 ms-auto text-end">
                <i class="material-icons align-middle md-40 text-success">open_in_browser</i>
              </div>
            </div>
          </div>
        </div>

        <div class="card mx-1 rounded card-shadow bg-dark text-white">
          <div class="card-body">
            <div class="row mx-0">
              <div class="col-8">
                <h5 class="card-title"> {{ totalSubmitted }} student(s)</h5>
                <p class="card-text"> submitted </p>
              </div>
              <div class="col-3 ms-auto text-end">
                <i class="material-icons align-middle md-40 text-success">check_circle</i>
              </div>
            </div>
          </div>
        </div>

        <div class="card mx-1 rounded card-shadow bg-dark text-white">
          <div class="card-body">
            <div class="row mx-0">
              <div class="col-8">
                <h5 class="card-title"> {{ totalCheat }} student(s)</h5>
                <p class="card-text"> integrity issues </p>
              </div>
              <div class="col-3 ms-auto text-end">
                <i class="material-icons align-middle md-40 text-warning">report_problem</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card shadow my-5">
        <div class="card-body">
          <h4 class="card-title text-rust">Student List</h4>
          <table id="studentTable" class="display table table-hover">
            <thead class="bg-dark text-white">
              <tr>
                <th scope="col"> First Name </th>
                <th scope="col"> Last Name </th>
                <th scope="col"> Started </th>
                <th scope="col"> Submitted </th>
                <th scope="col"> Attempts Used </th>
                <th scope="col"> Integrity Check </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(student, index) in assessmentStats" :key="index">
                <td class="text-dark"><strong>{{student.givenName}}</strong></td>
                <td >{{student.lastName}}</td>
                <td>
                  <p v-if="student.started">
                    {{new Date(student.started).toLocaleString('en-UK', {})}}
                  </p>
                  <p v-else> N/A </p>
                </td>
                <td>
                  <p v-if="student.submitted">
                    {{new Date(student.submitted).toLocaleString('en-UK', {})}}
                  </p>
                  <p v-else> N/A </p>
                </td>
                <td class="">{{student.attempts}}
                  <button type="button" class="btn btn-sm bg-dark text-white ms-3"
                  @click="resetAttempt(student)"> Reset </button>
                </td>
                <td>
                  <p v-if="student.flags">
                    Low Severity: {{JSON.parse(student.flags).leave}} <br/>
                    High Severity: {{JSON.parse(student.flags).switch}}
                  </p>
                  <p v-else> N/A </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="position-fixed bottom-0 start-0 p-3" style="z-index: 5">
      <div class="toast align-items-center text-white bg-primary border-0" role="alert"
      aria-live="assertive" aria-atomic="true" id="copyToast">
        <div class="d-flex">
          <div class="toast-body">
            Copied invitation!
          </div>
          <button type="button" class="btn-close btn-close-white me-2 m-auto"
          data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import {
  required,
} from 'vuelidate/lib/validators';
import { Modal } from 'bootstrap';
import Swal from 'sweetalert2';
import Navbar from '../components/Navbar.vue';

export default {
  name: 'ProctorView',
  components: {
    Navbar,
  },
  validations: {
    edit: {
      editTitle: { required },
      editDuration: {
        required,
        minValue(val) {
          return val > 0;
        },
      },
      editAttempts: {
        required,
        minValue(val) {
          return val > 0;
        },
      },
    },
  },
  computed: {
    loadScreen() {
      return this.$store.state.loadScreen;
    },
    userData() {
      return this.$store.state.userData;
    },
  },
  data() {
    return {
      assID: null,
      aInfo: {},
      assessmentStats: [],
      totalOpened: 0,
      totalSubmitted: 0,
      int_obj: {},
      totalCheat: 0,
      timer: null,
      nTitle: null,
      nAtt: null,
      nDuration: null,
      save: 'Save',
      detailsModal: null,
      originUrl: 'my.waffle.school',
      edit: {
        editTitle: null,
        editDuration: null,
        editAttempts: null,
      },
      submitEdit: false,
    };
  },
  methods: {
    getInfo() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.get('/api/assessment/getassessmentinfo', {
        params: {
          aId: this.assID,
        },
      })
        .then((response) => {
          this.aInfo = response.data.assignmentInfo;
          this.$store.commit('setLoadScreen', { status: false });
        });
      // .catch((error) => {
      //   if (error.response.status === 403) {
      //     this.$router.replace({ path: '/forbidden/' });
      //   }
      // });
    },

    getStats() {
      axios.get(`/api/assessment/${this.assID}/monitor`)
        .then((response) => {
          this.assessmentStats = response.data;
          this.totalOpened = this.assessmentStats.length;
          this.totalCheat = 0;
          this.totalSubmitted = 0;
          this.totalSubmitted = this.assessmentStats
            .filter((resp) => resp.submitted != null).length;
          this.totalCheat = this.assessmentStats.filter((resp) => {
            if (resp.flags) {
              const cheat = parseInt(resp.flags.leave, 10) > 0
              || parseInt(resp.flags.leave, 10) > 0;
              return cheat;
            }
            return 0;
          }).length;
          setTimeout(() => {
            this.table = $('#studentTable').DataTable({
              scrollY: '23rem',
              scrollCollapse: true,
              fixedHeader: true,
              autoWidth: false,
              info: false,
              searching: true,
              paging: false,
              retrieve: true,
              ordering: true,
            });
          }, 10);
        });
      // .catch((error) => {
      //   if (error.response.status === 403) {
      //     this.$router.replace({ path: '/forbidden/' });
      //   }
      // });
    },

    resetAttempt(student) {
      this.$store.commit('setLoadScreen', { status: true });
      axios.post(`/api/assessment/${this.assID}/attempts/reset`, {
        studentId: student.id,
      })
        .then(() => {
        // this.table.destroy();
        // this.table = null;
          Swal.fire(
            'Done!',
            'The attempts have been reset.',
            'success',
          );
          this.getStats();
          this.$store.commit('setLoadScreen', { status: false });
        })
        .catch((err) => {
          this.$store.commit('setLoadScreen', { status: false });
          Swal.fire(
            'Oops!',
            'The attempt could not be reset.',
            'error',
          );
          console.log(err);
        });
    },

    loadEdit() {
      this.edit.editTitle = this.aInfo.title;
      this.edit.editAttempts = this.aInfo.att;
      this.edit.editDuration = this.aInfo.totTime;
      this.detailsModal = new Modal(document.getElementById('detailsModal'));
      this.detailsModal.show();
      this.save = 'Save';
    },
    updateDetails() {
      this.submitEdit = true;
      if (!this.$v.edit.$invalid) {
        axios.post('/api/assessment/editdetails', {
          aId: this.assID,
          user: this.userData.id,
          updatedDetails: {
            schedUTC: new Date(this.aInfo.schedUTC).toUTCString(),
            totTime: this.edit.editDuration,
            title: this.edit.editTitle,
            att: this.edit.editAttempts,
          },
        })
          .then(() => {
            // this.save = 'Saved!';
            this.detailsModal.hide();
            this.getInfo();
            Swal.fire(
              'Updated!',
              'The assessment has been updated.',
              'success',
            );
          })
          .catch((err) => {
            Swal.fire(
              'Oops!',
              'The assessment could not be updated.',
              'error',
            );
            console.log(err);
          });
      }
    },
  },
  mounted() {
    this.classId = this.$route.params.cid;
    this.assID = this.$route.query.aID;
    this.originUrl = window.location.origin;
    this.getInfo();
    this.getStats();
    this.timer = setInterval(() => {
      // this.table.destroy();
      // this.table = null;
      this.getStats();
    }, 60000);
  },

  beforeRouteLeave(to, from, next) {
    clearInterval(this.timer);
    next();
  },
};
</script>

<style>
div.dataTables_filter input{
  border:  0;
  border-bottom: 5px #bb4b25;
  background-color: #dcf3f9;
}
div.dataTables_filter input:focus{
  background-color: #F5D6CC;
  outline:  none;
}
</style>

<template>
  <div class="col-md-7 col-lg-8 mx-auto pt-5">
    <div class="card card-shadow pt-2 mt-4">
      <div class="card-body">
        <h3 class="card-title mb-4">Join a school</h3>
        <div class="form-row mb-4">
          <label for="schoolCode">School name</label>
          <input type="text" class="input-field" v-model="schoolQuery"
          @keyup="searchSchools"
          :class="{ 'query-results' : schoolQueryResults.length > 0 }">
          <div class="position-relative p-0">
            <div class="autocomplete-results position-absolute"
            v-if="schoolQueryResults.length > 0">
              <ul class="autocomplete-item" v-for="school, idx in schoolQueryResults"
              :key="idx" :class="{ focus : idx === focusItem }" @click="addSchool(idx)">
                {{ school.name }}
              </ul>
            </div>
          </div>
        </div>
        <div class="form-row mb-4">
          <label for="schoolCode">Verification Code</label>
          <input class="input-field" id="schoolCode" type="text" v-model="schoolCode">
          <small class="form-text text-muted">Contact your school's IT
          administrator for the verification code.</small>
        </div>
        <button type="button" class="btn bg-pri float-end"
        @click="submitForm">Join school</button>
      </div>
    </div>
  </div>
</template>

<script>
// import $ from 'jquery';
import axios from 'axios';

export default {
  name: 'JoinSchool',
  components: {
  },
  data() {
    return {
      schoolQuery: '',
      schoolQueryResults: [],
      focusItem: null,
      schoolCode: '',
      selectedSchool: null,
    };
  },
  methods: {
    submitForm() {
      this.$store.dispatch('joinSchool', {
        roleId: this.$store.state.userData.roleId,
        code: this.schoolCode,
        schoolId: this.selectedSchool,
      })
        .then(() => {
          this.$store.dispatch('joinedSchool');
        });
    },
    searchSchools(event) {
      if (event.key === 'Enter') {
        this.schoolQuery = this.schoolQueryResults[this.focusItem].name;
        this.selectedSchool = this.schoolQueryResults[this.focusItem].id;
        this.schoolQueryResults = [];
      } else if (event.key === 'ArrowDown') {
        this.focusItem = Math.min(this.focusItem + 1, this.schoolQueryResults.length - 1);
      } else if (event.key === 'ArrowUp') {
        this.focusItem = Math.max(this.focusItem - 1, 0);
      } else {
        if (this.schoolQuery.length < 1) { this.schoolQueryResults = []; return; }
        axios.get(`/api/school/search?phrase=${this.schoolQuery}`)
          .then((response) => {
            this.schoolQueryResults = response.data;
            console.log(this.schoolQueryResults);
          });
        this.focusItem = 0;
      }
    },
    addSchool(idx) {
      this.schoolQuery = this.schoolQueryResults[idx].name;
      this.selectedSchool = this.schoolQueryResults[idx].id;
      this.schoolQueryResults = [];
    },
  },
  mounted() {
  },
};

</script>

<style scoped>
.autocomplete-results {
  background-color: #fff;
  box-shadow: .125rem .25rem rgba(236,171,50,0.3);
  text-align: left;
  width: 100%;
  border-radius: 1rem;
  z-index: 100;
}

.autocomplete-item {
  padding: 0.5rem 0.5rem;
  margin-bottom: 0px;
}

.autocomplete-item:hover {
  cursor: pointer;
  background-color: #FCF0D9;
  color: #000;
}

.autocomplete-item.focus {
  background-color: #ECAB32;
  color: #000;
}
</style>

<template>
  <!-- Free Response -->
  <div class="fr-cont" v-if="qInfo['type']==0" :id="'q-card-'+qNo">
    <!-- <div type="button" :id="'tools-'+qNo" class="tools accent-rust rounded"
    @click="addComment">
      <i class="material-icons text-white p-2"> rate_review</i>
    </div> -->
    <div v-if="active">
      <div class="f-response q-field col-8 mx-auto my-2 p-4 selected-qn" v-if="!aKey">
        <div class=" mb-1"> <small class="bg-light text-muted px-1">Free Response </small></div>
        <h5 class="mt-2 q-no" v-if="qInfo['title']"> {{qInfo['title']}}
          <small class="float-end e_btn px-0" @click="feedback=!feedback">Feedback &nbsp;
            <i class="material-icons text-success ml-1 e_btn align-middle">feedback</i>
          </small>
        </h5>
        <h5 class="mt-2 q-no" v-else> Question {{qNo}}
          <small class="float-end e_btn px-0" @click="feedback=!feedback">Feedback &nbsp;
            <i class="material-icons text-success ml-1 e_btn align-middle">feedback</i>
          </small>
        </h5>
        <div v-show="qInfo['image']" class="mt-4 mb-2">
          <img v-bind:src="qInfo['image']" class="d-flex img-pre mx-auto" />
        </div>
        <p class="my-4" v-if="qInfo['problem'].length==0 && !qInfo['image']">Enter Problem
        Statement </p>
        <!-- <p class="my-4 mathjax p-pre" v-else><em v-html="qInfo['problem']"></em></p> -->
        <div class="component-container" v-for="pItem, pInd in qInfo['problem']"
          :key="pInd" v-else>
          <RichText :component="pItem" :ind="qNo" :preview="true"
          v-if="pItem.type==='richText'">
          </RichText>
          <Equation :component="pItem" :ind="pInd" :u-ind="`${qNo}_${pInd}`"
            type="fr-akey" :preview="true"
            v-else-if="pItem.type==='equation'">
          </Equation>
        </div>
        <div class="mt-4 border rounded p-1 pt-2">
          <p class="ms-2 pre-title text-muted bg-white"> answer </p>
          <div v-show="sResp['a-image']" class="mt-4 mb-2">
            <img v-bind:src="sResp['a-image']" class="d-flex img-pre mx-auto" />
          </div>
          <p class="mathjax" @mouseup="displayTool($event)" @mousedown="setRangeStart($event)">
            <em v-html="sResp['answer']"></em></p>

        </div>
        <p class="text-primary text-small float-start e_btn px-0 mt-3" @click="activeAkey"><i
          class="material-icons align-middle">assignment_turned_in</i> Answer Key</p>
        <div class="mt-4 mx-0 float-end">
          <input class= "d-inline point-input text-end" type="number" step="1" min="0"
          :max="qInfo['points']" v-model="sResp['awarded']">
          <p class="d-inline text-end text-muted mt-4 col-sm-3 mb-0 me-0 my-auto px-0">/
          {{qInfo['points']}} points </p>
        </div>
        <textarea class="my-4 t-fr bg-light p-2" placeholder="Feedback"
        v-model="sResp['feedback']" v-show="feedback || sResp['feedback'].length>0"></textarea>

      </div>
      <div class="f-response q-field col-8 mx-auto my-2 p-4 selected-qn" v-else>
        <div class=" mb-1"> <small class="bg-light text-muted px-1">Free Response </small></div>
        <h5 class="mt-2 q-no" v-if="qInfo['title']">{{qInfo['title']}}</h5>
        <h5 class="mt-2 q-no" v-else> Question {{qNo}} </h5>
        <div v-show="qInfo['image']" class="mt-4 mb-2">
          <img v-bind:src="qInfo['image']" class="d-flex img-pre mx-auto" />
        </div>
        <p class="my-4" v-if="qInfo['problem'].length==0 && !qInfo['image']">
          <em> "Problem Statement"</em> </p>
        <!-- <p class="my-4 mathjax p-pre" v-else><em v-html="qInfo['problem']"></em></p> -->
        <div class="component-container" v-for="pItem, pInd in qInfo['problem']"
          :key="pInd" v-else>
          <RichText :component="pItem" :ind="qNo" :preview="true"
          v-if="pItem.type==='richText'">
          </RichText>
          <Equation :component="pItem" :ind="pInd" :u-ind="`${qNo}_${pInd}`"
            type="fr-akey" :preview="true"
            v-else-if="pItem.type==='equation'">
          </Equation>
        </div>
        <p class="mt-2"> Enter key words and/or phrases. Each key word must be on a new line.
        </p>
        <p class="my-4 mathjax p-pre d-inline"><em v-html="qInfo['solution']"></em></p>
        <p class="mb-0 text-start text-muted">{{sResp['awarded']}}/{{qInfo['points']}} points
        </p>
        <p type="button" class="mb-0 btn btn-outline-primary btn-small float-end"
        @click="deactiveAkey">Done</p>
      </div>
    </div>
    <div v-else>
      <div class="f-response q-field col-8 mx-auto my-2 p-4">
        <div class=" mb-1"> <small class="bg-light text-muted px-1">Free Response </small></div>
        <h5 class="mt-2 q-no" v-if="qInfo['title']"> {{qInfo['title']}}</h5>
        <h5 class="mt-2 q-no" v-else> Question {{qNo}} </h5>
        <div v-show="qInfo['image']" class="mt-4 mb-2">
          <img v-bind:src="qInfo['image']" class="d-flex img-pre mx-auto" />
        </div>
        <p class="my-4" v-if="qInfo['problem'].length==0 && !qInfo['image']">
        Enter Problem Statement </p>
        <!-- <p class="my-4 mathjax p-pre" v-else><em v-html="qInfo['problem']"></em></p> -->
        <div class="component-container" v-for="pItem, pInd in qInfo['problem']"
          :key="pInd" v-else>
          <RichText :component="pItem" :ind="qNo" :preview="true"
          v-if="pItem.type==='richText'">
          </RichText>
          <Equation :component="pItem" :ind="pInd" :u-ind="`${qNo}_${pInd}`"
            type="fr-akey" :preview="true"
            v-else-if="pItem.type==='equation'">
          </Equation>
        </div>
        <div class="mt-4 border rounded p-1 pt-2">
          <p class="ms-2 pre-title text-muted bg-white"> answer </p>
          <div v-show="sResp['a-image']" class="mt-4 mb-2">
            <img v-bind:src="sResp['a-image']" class="d-flex img-pre mx-auto" />
          </div>
          <p class="mathjax"><em v-html="sResp['answer']"></em></p>
        </div>
        <p class="text-end text-muted mt-4">{{sResp['awarded']}}/{{qInfo['points']}} points
        </p>
      </div>
    </div>
  </div>

  <!-- Multiple Choice Questions -->
  <div id="mc-cont" v-else-if="qInfo['type']==1">
    <div v-if="active">
      <div class="mc-response q-field col-8 mx-auto my-2 p-4 selected-qn" v-if="!aKey">
        <div class=" mb-1"> <small class="bg-light text-muted px-1">Multiple Choice </small>
        </div>
        <h5 class="mt-2 q-no" v-if="qInfo['title']"> {{qInfo['title']}}
          <small class="float-end e_btn px-0" @click="feedback=!feedback">Feedback &nbsp;
            <i class="material-icons text-success float-end e_btn align-middle">feedback</i>
          </small>
        </h5>
        <h5 class="mt-2 q-no" v-else> Question {{qNo}}
          <small class="float-end e_btn px-0" @click="feedback=!feedback">Feedback &nbsp;
            <i class="material-icons text-success float-end e_btn align-middle">feedback</i>
          </small>
        </h5>
        <div v-show="qInfo['image']" class="mt-4 mb-2">
          <img v-bind:src="qInfo['image']" class="d-flex img-pre mx-auto" />
        </div>
        <p class="my-4" v-if="qInfo['problem'].length==0 && !qInfo['image']">
          <em> "Problem Statement"</em> </p>
        <!-- <p class="my-4 mathjax p-pre" v-else><em v-html="qInfo['problem']"></em></p> -->
        <div class="component-container" v-for="pItem, pInd in qInfo['problem']"
          :key="pInd" v-else>
          <RichText :component="pItem" :ind="qNo" :preview="true"
          v-if="pItem.type==='richText'">
          </RichText>
          <Equation :component="pItem" :ind="pInd" :u-ind="`${qNo}_${pInd}`"
            type="fr-akey" :preview="true"
            v-else-if="pItem.type==='equation'">
          </Equation>
        </div>

        <p> Select the correct answer choice: </p>
        <div class="form-check my-2" v-for="o,ind in qInfo['options']" :key="`O${ind}`">
          <input class="form-check-input" type="radio" :id="ind" :name="'answer'+qNo"
          v-bind:value="o" v-model="sResp['answer']" disabled>
          <p class="my-4 mathjax p-pre d-inline" v-if="o.length>0">
            <em v-html="qInfo['options'][ind]"></em>
            <strong v-if="qInfo['solution']===o && sResp['answer']===o"
            class="text-success "> Correct! </strong>
            <strong v-else-if="qInfo['solution']===o && !correct"
            class="text-success "> Correct Answer </strong>
            <strong v-else-if="sResp['answer']===o && !correct"
            class="text-warning "> Student's Answer </strong>
          </p>
          <label for="ind" v-else> Enter answer choice </label>
        </div>
        <p class="text-primary text-small float-start e_btn px-0 mt-3" @click="activeAkey"><i
          class="material-icons align-middle">assignment_turned_in</i> Answer Key</p>
        <div class="mt-4 mx-0 float-end">
          <input class= "d-inline point-input text-end" type="number" step="1" min="0"
          :max="qInfo['points']" v-model="sResp['awarded']">
          <p class="d-inline text-end text-muted mt-4 col-sm-3 mb-0 me-0 my-auto px-0">/
          {{qInfo['points']}} points </p>
        </div>
        <textarea class="my-4 t-fr bg-light p-2" placeholder="Feedback"
        v-model="sResp['feedback']" v-show="feedback || sResp['feedback'].length>0"></textarea>
      </div>
      <div class="mc-response q-field col-8 mx-auto my-2 p-4 selected-qn" v-else>
        <div class=" mb-1">
          <small class="bg-light text-muted px-1">Multiple Choice</small>
        </div>
        <h5 class="mt-2 q-no" v-if="qInfo['title']"> {{qInfo['title']}}</h5>
        <h5 class="mt-2 q-no" v-else> Question {{qNo}} </h5>
        <div v-show="qInfo['image']" class="mt-4 mb-2">
          <img v-bind:src="qInfo['image']" class="d-flex img-pre mx-auto" />
        </div>
        <p class="my-4" v-if="qInfo['problem'].length==0 && !qInfo['image']"><em>
        "Problem Statement"</em> </p>
        <!-- <p class="my-4 mathjax p-pre" v-else><em v-html="qInfo['problem']"></em></p> -->
        <div class="component-container" v-for="pItem, pInd in qInfo['problem']"
          :key="pInd" v-else>
          <RichText :component="pItem" :ind="qNo" :preview="true"
          v-if="pItem.type==='richText'">
          </RichText>
          <Equation :component="pItem" :ind="pInd" :u-ind="`${qNo}_${pInd}`"
            type="fr-akey" :preview="true"
            v-else-if="pItem.type==='equation'">
          </Equation>
        </div>

        <p> Select the correct answer choice: </p>
        <div class="form-check my-2" v-for="o,ind in qInfo['options']" :key="`O${ind}`">
          <input class="form-check-input" type="radio" :id="ind" :name="'answer'+qNo"
          v-bind:value="o" v-model="qInfo['solution']" disabled>
          <p class="my-4 mathjax p-pre d-inline" v-if="o.length>0">
            <em v-html="qInfo['options'][ind]"></em>
          </p>
          <label for="ind" v-else> Enter answer choice </label>
        </div>
        <p v-if="correct" class="text-start text-success">
          <strong>{{sResp['awarded']}}/{{qInfo['points']}}</strong> points </p>
        <p v-else class="text-start text-danger">
          <strong>{{sResp['awarded']}}/{{qInfo['points']}}</strong> points </p>
        <p type="button" class="mb-0 btn btn-outline-primary btn-small float-end"
        @click="deactiveAkey">Done</p>
      </div>
    </div>
    <div v-else>
      <div class="mc-response q-field col-8 mx-auto my-2 p-4">
        <div class=" mb-1"> <small class="bg-light text-muted px-1">Multiple Choice </small>
        </div>
        <h5 class="mt-2 q-no" v-if="qInfo['title']"> {{qInfo['title']}}</h5>
        <h5 class="mt-2 q-no" v-else> Question {{qNo}} </h5>
        <div v-show="qInfo['image']" class="mt-4 mb-2">
          <img v-bind:src="qInfo['image']" class="d-flex img-pre mx-auto" />
        </div>
        <p class="my-4" v-if="qInfo['problem'].length==0 && !qInfo['image']">
        Enter Problem Statement </p>
        <!-- <p class="my-4 mathjax p-pre" v-else><em v-html="qInfo['problem']"></em></p> -->
        <div class="component-container" v-for="pItem, pInd in qInfo['problem']"
          :key="pInd" v-else>
          <RichText :component="pItem" :ind="qNo" :preview="true"
          v-if="pItem.type==='richText'">
          </RichText>
          <Equation :component="pItem" :ind="pInd" :u-ind="`${qNo}_${pInd}`"
            type="fr-akey" :preview="true"
            v-else-if="pItem.type==='equation'">
          </Equation>
        </div>
        <div class="form-check my-2" v-for="o,ind in qInfo['options']" :key="`O${ind}`">
          <input class="form-check-input" type="radio" :id="ind" :name="'answer'+qNo"
          v-bind:value="o" v-model="sResp['answer']" disabled>
          <p class="my-4 mathjax p-pre d-inline" v-if="o.length>0">
            <em v-html="qInfo['options'][ind]"></em>
            <strong v-if="qInfo['solution']===o && sResp['answer']===o" class="text-success">
            Correct! </strong>
            <strong v-else-if="qInfo['solution']===o && !correct"
            class="text-success "> Correct Answer </strong>
            <strong v-else-if="sResp['answer']===o && !correct"
            class="text-warning "> Student's Answer </strong>
          </p>
          <label for="ind" v-else> Enter answer choice </label>
        </div>
        <p v-if="correct" class="text-end text-success">
          <strong>{{sResp['awarded']}}/{{qInfo['points']}}</strong> points </p>
        <p v-else class="text-end text-danger">
          <strong>{{sResp['awarded']}}/{{qInfo['points']}} </strong>points </p>
      </div>
    </div>
  </div>

  <!-- Section Header -->
  <div class="fr-cont" v-else-if="qInfo['type']==2">
    <div>
      <div class="divider"> Section {{sNo+1}}/{{sLen}} </div>
      <div class="s-header q-field col-8 mx-auto mb-2 px-4 pb-4 pt-0 ">
        <div class="row ms-0 mt-4">
          <h4 class="px-0 py-1 t-fr col-sm-8" v-if="qInfo['title']" v-html="qInfo['title']">
          </h4>
          <h4 class="px-0 py-1 text-muted col-sm-8 h4" v-else >
            <em> Section Title </em>
          </h4>
        </div>

        <div data-ph="Description" class="px-0 py-1 col-sm-8" v-html="qInfo['desc']"
        v-if="qInfo['desc']">
        </div>
        <p data-ph="Description" class="px-0 py-1 col-sm-8 text-muted" v-else>
          <em>Description</em>
        </p>

      </div>
    </div>
  </div>

  <!-- Title/Instructions -->
  <div class="fr-cont" v-else-if="qInfo['type']==3">
    <div>
      <div class="f-response q-field col-8 mx-auto mb-2 px-4 pb-4 pt-0 ">
        <div class="row ms-0 mt-4">
          <h4 class="px-0 py-1 col-sm-8" v-if="qInfo['title']">
            {{qInfo['title']}}
          </h4>
          <h4 class="px-0 py-1 text-muted col-sm-8 h4" v-else >
            <em> Title </em>
          </h4>
        </div>

        <div data-ph="Description" class="px-0 py-1 col-sm-8" v-html="qInfo['desc']"
        v-if="qInfo['desc']">
        </div>
        <p data-ph="Description" class="px-0 py-1 col-sm-8 text-muted" v-else>
          <em>Description</em>
        </p>

      </div>
    </div>
  </div>

  <!-- Short Answer -->
  <div class="fr-cont" v-else-if="qInfo['type']==4">
    <div v-if="active">
      <div class="f-response q-field col-8 mx-auto my-2 p-4 selected-qn" v-if="!aKey">
        <div class=" mb-1"> <small class="bg-light text-muted px-1">Short Answer </small></div>
        <h5 class="mt-2 q-no" v-if="qInfo['title']"> {{qInfo['title']}}
          <small class="float-end e_btn px-0" @click="feedback=!feedback">Feedback &nbsp;
            <i class="material-icons text-success float-end e_btn align-middle">feedback</i>
          </small>
        </h5>
        <h5 class="mt-2 q-no" v-else> Question {{qNo}}
          <small class="float-end e_btn px-0" @click="feedback=!feedback">Feedback &nbsp;
            <i class="material-icons text-success float-end e_btn align-middle">feedback</i>
          </small>
        </h5>
        <div v-show="qInfo['image']" class="mt-4 mb-2">
          <img v-bind:src="qInfo['image']" class="d-flex img-pre mx-auto" />
        </div>
        <p class="my-4" v-if="qInfo['problem'].length==0 && !qInfo['image']">
        Enter Problem Statement</p>
        <!-- <p class="my-4 mathjax p-pre" v-else><em v-html="qInfo['problem']"></em></p> -->
        <div class="component-container" v-for="pItem, pInd in qInfo['problem']"
          :key="pInd" v-else>
          <RichText :component="pItem" :ind="qNo" :preview="true"
          v-if="pItem.type==='richText'">
          </RichText>
          <Equation :component="pItem" :ind="pInd" :u-ind="`${qNo}_${pInd}`"
            type="fr-akey" :preview="true"
            v-else-if="pItem.type==='equation'">
          </Equation>
        </div>
        <div class="mt-4 border rounded p-1 pt-2">
          <p class="ms-2 pre-title text-muted bg-white"> answer </p>
          <div v-show="sResp['a-image']" class="mt-4 mb-2">
            <img v-bind:src="sResp['a-image']" class="d-flex img-pre mx-auto" />
          </div>
          <p class="mathjax text-center">
            <em v-html="sResp['answer']"></em>
          </p>
        </div>
        <p class="text-primary text-small float-start e_btn px-0 mt-3" @click="activeAkey"><i
          class="material-icons align-middle">assignment_turned_in</i> Answer Key</p>
        <div class="mt-4 mx-0 float-end">
          <input class= "d-inline point-input text-end" type="number" step="1" min="0"
          :max="qInfo['points']" v-model="sResp['awarded']">
          <p class="d-inline text-end text-muted mt-4 col-sm-3 mb-0 me-0 my-auto px-0">/
          {{qInfo['points']}} points </p>
        </div>
        <textarea class="my-4 t-fr bg-light p-2" placeholder="Feedback"
        v-model="sResp['feedback']" v-show="feedback || sResp['feedback'].length>0"></textarea>

      </div>
      <div class="f-response q-field col-8 mx-auto my-2 p-4 selected-qn" v-else>
        <div class=" mb-1"> <small class="bg-light text-muted px-1">Short Answer</small></div>
        <h5 class="mt-2 q-no" v-if="qInfo['title']"> {{qInfo['title']}}</h5>
        <h5 class="mt-2 q-no" v-else> Question {{qNo}} </h5>
        <div v-show="qInfo['image']" class="mt-4 mb-2">
          <img v-bind:src="qInfo['image']" class="d-flex img-pre mx-auto" />
        </div>
        <p class="my-4" v-if="qInfo['problem'].length==0 && !qInfo['image']">
          <em> "Problem Statement"</em> </p>
        <!-- <p class="my-4 mathjax p-pre" v-else><em v-html="qInfo['problem']"></em></p> -->
        <div class="component-container" v-for="pItem, pInd in qInfo['problem']"
          :key="pInd" v-else>
          <RichText :component="pItem" :ind="qNo" :preview="true"
          v-if="pItem.type==='richText'">
          </RichText>
          <Equation :component="pItem" :ind="pInd" :u-ind="`${qNo}_${pInd}`"
            type="fr-akey" :preview="true"
            v-else-if="pItem.type==='equation'">
          </Equation>
        </div>
        <p class="mt-2"> Acceptable answers separated by commas. </p>
        <p class="my-4 mathjax p-pre d-inline"><strong v-html="qInfo['solution']"></strong></p>
        <p class="mt-4 mb-0 text-start text-muted">
        {{sResp['awarded']}}/{{qInfo['points']}} points</p>
        <p type="button" class="mb-0 btn btn-outline-primary btn-small float-end"
        @click="deactiveAkey">Done</p>
      </div>
    </div>
    <div v-else>
      <div class="f-response q-field col-8 mx-auto my-2 p-4">
        <div class=" mb-1"> <small class="bg-light text-muted px-1">Short Answer </small></div>
        <h5 class="mt-2 q-no" v-if="qInfo['title']"> {{qInfo['title']}}</h5>
        <h5 class="mt-2 q-no" v-else> Question {{qNo}} </h5>
        <div v-show="qInfo['image']" class="mt-4 mb-2">
          <img v-bind:src="qInfo['image']" class="d-flex img-pre mx-auto" />
        </div>
        <p class="my-4" v-if="qInfo['problem'].length==0 && !qInfo['image']">
        Enter Problem Statement </p>
        <!-- <p class="my-4 mathjax p-pre" v-else><em v-html="qInfo['problem']"></em></p> -->
        <div class="component-container" v-for="pItem, pInd in qInfo['problem']"
          :key="pInd" v-else>
          <RichText :component="pItem" :ind="qNo" :preview="true"
          v-if="pItem.type==='richText'">
          </RichText>
          <Equation :component="pItem" :ind="pInd" :u-ind="`${qNo}_${pInd}`"
            type="fr-akey" :preview="true"
            v-else-if="pItem.type==='equation'">
          </Equation>
        </div>
        <div class="mt-4 border rounded p-1 pt-2 mb-3">
          <p class="ms-2 pre-title text-muted bg-white"> answer </p>
          <div v-show="sResp['a-image']" class="mt-4 mb-2">
            <img v-bind:src="sResp['a-image']" class="d-flex img-pre mx-auto" />
          </div>
          <p class="mathjax text-center">
            <em v-html="sResp['answer']"></em>
          </p>
        </div>
         <p v-if="correct" class="text-end text-success">
          <strong>{{sResp['awarded']}}/{{qInfo['points']}}</strong> points </p>
        <p v-else class="text-end text-danger">
          <strong>{{sResp['awarded']}}/{{qInfo['points']}} </strong>points </p>
      </div>
    </div>
  </div>
</template>
<script>
import $ from 'jquery';
import Equation from '@/components/EquationEditor.vue';
import RichText from '@/components/RichTextEditor.vue';

export default {
  name: 'grading-template',
  props: ['qInfo', 'qNo', 'sNo', 'sLen', 'sResp'],
  components: {
    Equation,
    RichText,
  },
  data() {
    return {
      active: false,
      aKey: false,
      editor: null,
      activeSct: false,
      previewImage: null,
      pContent: null,
      fileName: '',
      feedback: false,
      correct: false,
      savedRange: null,
      pageX: 0,
      pageY: 0,
      tool: false,
    };
  },

  delimiters: ['||', '||'],

  methods: {
    setActive() {
      this.active = true;
      this.reRender();
      this.resetEditor();
      // this.popovers();
      this.initContedit();
      // TO BE DELETED
      // this.updateField();
      // CKEDITOR.replace('problemEdit');
    },

    setInactive() {
      this.active = false;
      this.reRender();
      this.resetEditor();
    },

    addOption() {
      const temp = this.qInfo.options;
      temp.push('');
      this.qInfo.options = temp;
      this.$forceUpdate();
    },

    delOption(index) {
      const temp = this.qInfo.options;
      temp.splice(index, 1);
      this.qInfo.options = temp;
      this.$forceUpdate();
    },

    activeAkey() {
      this.aKey = true;
      this.reRender();
      this.resetEditor();
    },

    deactiveAkey() {
      this.aKey = false;
      this.reRender();
      this.resetEditor();
      // this.popovers();
      this.initContedit();

      // TO BE DELETED
      // this.updateField();
    },

    rText() {
      // if(!this.editor){
      //  this.editor= CKEDITOR.inline('problemStatement');
      // }
      // else {
      //  this.editor.destroy();
      //  this.editor= CKEDITOR.inline('problemStatement');
      // }

      // el.setAttribute("v-html","qInfo['solution']");

    },

    resetEditor() {
      // if(this.editor){
      //  this.editor.destroy();
      //  this.editor = null;
      // }

    },

    // TO BE DELETED
    // updateField() {
    //   this.pContent = this.sResp.answer;
    // },

    reRender() {
      // this.$parent.updateMath();
      setTimeout(() => {
        window.MathJax.typesetPromise();
      }, 0);
    },

    initContedit() {
      document.execCommand('defaultParagraphSeparator', false, 'br');
    },

    saveSelection() {
      let sel = null;
      if (window.getSelection) {
        sel = window.getSelection();
      } else if (document.getSelection) {
        sel = document.getSelection();
      } else if (document.selection) {
        sel = document.selection.createRange().text;
      }
      // console.log('saved');
      return sel;
    },

    displayTool() {
      // console.log(event.target);
      this.savedRange = this.saveSelection();
      // if (this.savedRange != '') {

      const container = document.querySelector(`#q-card-${this.qNo}`);
      // console.log(container.getBoundingClientRect(), this.pageX, this.pageY);
      const xPos = this.pageX - container.getBoundingClientRect().left;
      const yPos = this.pageY - 55;
      $(`#tools-${this.qNo}`).css({
        left: xPos,
        top: yPos,
      }).fadeIn(200);
      this.tool = true;
      // }
    },

    setRangeStart(event) {
      this.pageX = event.clientX;
      this.pageY = event.pageY;
    },

    addComment() {
      // let span = document.createElement("span");
      // span.style.backgroundColor = "yellow";
      // console.log(this.savedRange);
    //   this.savedRange = this.saveSelection();
    //   const range = this.savedRange.getRangeAt(0);
    //   const { startOffset } = range;
    //   const { endOffset } = range;
    //   console.log(range);

      //   let allSelected = [];
      //   if (range.commonAncestorContainer.childElementCount > 0) {
      //     const allWithinRange = range.commonAncestorContainer.getElementsByTagName('*');

    //     for (let i = 0, el; el = allWithinRange[i]; i++) {
    //       // The second parameter says to include the element
    //       // even if it's not fully selected
    //       if (this.savedRange.containsNode(el, true)) {
    //         allSelected.push(el);
    //         // let span = document.createElement("span");
    //         // span.style.backgroundColor = "yellow";
    //         // let range=document.createRange();
    //         // let child = el.childNodes[0];
    //         // if (i==0){
    //         //   console.log(child.innerHTML);
    //         //   range.setStart(child,startOffset);
    //         //   range.setEnd(child,child.innerHTML.length-1);
    //         // } else if (i==(allWithinRange.length-1)) {
    //         //   console.log(child.innerHTML);
    //         //   range.setStart(child,0);
    //         //   range.setEnd(child,endOffset);
    //         // } else {
    //         //   console.log(child);
    //         //   range.setStart(child,0);
    //         //   range.setEnd(child,5);
    //         // }
    //         // console.log(range);
    //         // range.surroundContents(span);
    //       }
    //     }
    //     console.log('All selected =', allSelected);
    //     for (let j = 0, el; el = allSelected[j]; j++) {
    //       const span = document.createElement('span');
    //       span.style.backgroundColor = 'yellow';
    //       const newRange = document.createRange();
    //       const child = el.childNodes[0];
    //       console.log(child);
    //       if (j === 0) {
    //         newRange.setStart(child, startOffset);
    //         newRange.setEnd(child, child.length);
    //       } else if (j === (allSelected.length - 1)) {
    //         newRange.setStart(child, 0);
    //         newRange.setEnd(child, endOffset);
    //       } else {
    //         newRange.setStart(child, 0);
    //         newRange.setEnd(child, child.length);
    //       }
    //       console.log(range);
    //       newRange.surroundContents(span);
    //     }
    //   } else {
    //     allSelected = range;
    //     const span = document.createElement('span');
    //     span.style.backgroundColor = 'yellow';
    //     range.surroundContents(span);
    //   }
    },

  },

  mounted() {
    $(document).on('mousedown', () => {
      if (this.tool) {
        $(`#tools-${this.qNo}`).fadeOut(200);
        this.tool = false;
      }
    });

    $(`#tools-${this.qNo}`).mousedown((event) => {
      event.preventDefault();
    });
  },
};
</script>

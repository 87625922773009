<template>
  <div>
    <!-- Free Response -->
    <div class="fr-cont" v-if="qInfo['type']==0">
      <div v-if="active">
        <div class="f-response q-field col-8 mx-auto my-2 p-4 selected-qn">
          <div class=" mb-1">
            <small class="bg-light text-muted px-1">Free Response </small>
          </div>
          <h5 class="mt-2 q-no" v-if="qInfo['title']"> {{qInfo['title']}}</h5>
          <h5 class="mt-2 q-no" v-else> Question {{qNo}} </h5>
          <div v-show="qInfo['image']" class="mt-4 mb-2">
            <img v-bind:src="qInfo['image']" class="d-flex img-pre mx-auto" />
          </div>
          <!-- <p class="my-4  mathjax p-pre"><em v-html="qInfo['problem']"></em></p> -->
          <div class="component-container" v-for="pItem, pInd in qInfo['problem']"
            :key="pInd">
            <RichText :component="pItem" :ind="qNo" :preview="true"
            v-if="pItem.type==='richText'">
            </RichText>
            <Equation :component="pItem" :ind="pInd" :u-ind="`${qNo}_${pInd}`"
              type="fr-akey" :preview="true"
              v-else-if="pItem.type==='equation'">
            </Equation>
          </div>
          <div class="mt-4 container border rounded p-1 pt-2">
            <p class="ms-2 pre-title text-muted bg-white"> preview </p>
            <div class="mt-4 mb-2 img-container text-center" v-if="sResp['a-image']">
              <div style="position:relative; display: inline-block;">
                <img v-bind:src="sResp['a-image']" class="d-flex img-pre-a">
                <span type="button" class="delete-img" @click="delImage">
                  <i class="material-icons bg-danger text-white rounded-circle">cancel</i></span>
              </div>
            </div>
            <p class="mathjax px-1"><em v-html="sResp['answer']"></em></p>
          </div>

           <div id="webcapmodal" class = "modal fade" role="dialog">
            <div class="modal-dialog">
              <form method="POST" @submit="saveWebCap()" onsubmit="return false;">
                <fieldset class="form-group">
                  <div class="modal-content site-config-box">
                    <div class="modal-header">
                      <h5 class="modal-title" > Upload Image </h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body">
                      <div class="border rounded p-2 mx-auto mt-2 mb-3" v-show="screenshot">
                        <div>
                          <img id="a_img_pre" v-bind:src="imgPreview"  class="img-pre-a">
                        </div>
                      </div>
                      <div v-show="!screenshot"
                        class="border rounded p-2 mx-auto mt-2 mb-3 text-center">
                        <video class="tracking-cam align-middle" autoplay > </video>
                        <h2 v-show="countdown >0" class="text-danger webcap-time display-3">
                        {{countdown}}
                        </h2>
                        <i type="button" class="material-icons md-40 mx-auto"
                        @click="takeWebCap()">camera</i>
                      </div>
                      <input name="a-image" id="a-image" type="file" accept="image/jpeg image/png"
                      @change="uploadImage">
                      <label for="a-image" type="button" class="btn btn-sm me-1 btn-inline
                      btn-secondary mb-0"> Upload From Files </label>
                      <button type="button" class="btn btn-sm float-end btn-inline btn-secondary"
                      @click="initWebCap()"> Use WebCapture </button>
                      <div class="b-shutter" v-show="shutter"></div>
                      <p v-if="errors.length" class="mt-2 mb-0 ms-2">
                        <small>
                          <b>Please correct the following error(s):</b>
                          <ul class="ms-3 my-0 p-0 text-sm text-danger">
                            <li v-for="error, idx in errors" :key = "idx"> {{error}}</li>
                          </ul>
                        </small>
                      </p>
                    </div>
                    <div class="modal-footer">
                      <input type="submit" value="Save" class="btn btn-primary btn-sm
                      text-white mt-3">
                      <button type="button" class="btn btn-danger btn-sm
                      text-white mt-3" data-bs-dismiss="modal"> Cancel </button>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>

          <div class="row ms-0 me-0 mt-4 edit-box">
            <div type="text" id="pStatement" name="problemStatement"
            data-ph="Type your answer here" class="px-0 py-1 t-fr col-sm-10"
            @focus="restoreSelection()" @input="updateAnswer"
            @click="saveSelection()" @blur="saveSelection()" v-html="pContent" contenteditable>
            </div>
            <div class="col-sm-2 text-center align-items-middle my-auto h-100">
              <button for="a-image" type="button" class="btn align-middle p-0"
              @click="launchWebCap">
                <i class=" material-icons text-secondary algin-self-center md-32 my-1">
                insert_photo</i>
              </button>
              <button type="button" class="btn align-middle p-0" @click="mountML">
                <i class="material-icons text-secondary algin-self-center md-32 my-1">
                calculate</i>
              </button>
            </div>
          </div>

          <div :id="`equationmodal${qNo}${sNo}`" class = "modal fade" role="dialog">
            <div class="modal-dialog">
              <form method="POST" @submit="addEqn('problem')" @submit.prevent>
                <fieldset class="form-group">
                  <div class="modal-content site-config-box">
                    <div class="modal-header">
                      <h4 class="modal-title" > Create Equation </h4>
                      <button type="button" class="btn-close" data-bs-dismiss="modal"
                      @click="unmountML"></button>
                    </div>

                    <div class="modal-body">
                      <div id="mf" class="border rounded border-primary"
                      >
                      </div>
                      <div id="output"></div>
                    </div>

                    <div class="modal-footer">
                      <input type="submit" value="Save" class="btn btn-primary btn-sm
                      text-white mt-3">
                      <button type="button" class="btn btn-danger btn-sm
                      text-white mt-3" data-bs-dismiss="modal" @click="unmountML"> Cancel </button>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
          <p class="mb-0 text-end text-muted">{{qInfo['points']}} point(s) </p>
        </div>
      </div>
      <div v-else>
        <div class="f-response q-field col-8 mx-auto my-2 p-4 " v-if="qInfo['type']==0">
          <div class=" mb-1"> <small class="bg-light text-muted px-1">Free Response </small></div>
          <h5 class="mt-2 q-no" v-if="qInfo['title']"> {{qInfo['title']}}</h5>
          <h5 class="mt-2 q-no" v-else> Question {{qNo}} </h5>
          <div v-show="qInfo['image']" class="mt-4 mb-2">
            <img v-bind:src="qInfo['image']" class="d-flex img-pre mx-auto" />
          </div>
          <p class="my-4" v-if="qInfo['problem'].length==0 && !qInfo['image']">
          Enter Problem Statement </p>
          <!-- <p class="my-4 mathjax p-pre" v-else><em v-html="qInfo['problem']"></em></p> -->
          <div class="component-container" v-for="pItem, pInd in qInfo['problem']"
            :key="pInd">
            <RichText :component="pItem" :ind="qNo" :preview="true"
            v-if="pItem.type==='richText'">
            </RichText>
            <Equation :component="pItem" :ind="pInd" :u-ind="`${qNo}_${pInd}`"
              type="fr-akey" :preview="true"
              v-else-if="pItem.type==='equation'">
            </Equation>
          </div>
          <div class="mt-4 border rounded p-1 pt-2">
            <p class="ms-2 pre-title text-muted bg-white"> answer </p>
            <div v-show="sResp['a-image']" class="my-3">
              <img v-bind:src="sResp['a-image']" class="d-flex img-pre mx-auto" />
            </div>
            <p class="mathjax px-1"><em v-html="sResp['answer']"></em></p>
          </div>
          <p class="text-end text-muted mt-4">{{qInfo['points']}} point(s) </p>
        </div>
      </div>
    </div>

<!-- Multiple Choice Questions -->
    <div class="mc-cont" v-else-if="qInfo['type']==1">
      <div v-if="active">
        <div class="mc-response q-field col-8 mx-auto my-2 p-4 selected-qn">
          <div class=" mb-1">
            <small class="bg-light text-muted px-1">
              Multiple Choice
            </small>
          </div>
          <h5 class="mt-2 q-no" v-if="qInfo['title']"> {{qInfo['title']}}</h5>
          <h5 class="mt-2 q-no" v-else> Question {{qNo}} </h5>
          <div v-show="qInfo['image']" class="mt-4 mb-2">
            <img v-bind:src="qInfo['image']" class="d-flex img-pre mx-auto" />
          </div>
          <p class="my-4" v-if="qInfo['problem'].length==0 && !qInfo['image']"><em>
          "Problem Statement"</em></p>
          <!-- <p class="my-4 mathjax p-pre" v-else><em v-html="qInfo['problem']"></em></p> -->
          <div class="component-container" v-for="pItem, pInd in qInfo['problem']"
            :key="pInd" v-else>
            <RichText :component="pItem" :ind="qNo" :preview="true"
            v-if="pItem.type==='richText'">
            </RichText>
            <Equation :component="pItem" :ind="pInd" :u-ind="`${qNo}_${pInd}`"
              type="fr-akey" :preview="true"
              v-else-if="pItem.type==='equation'">
            </Equation>
          </div>

          <p> Select the correct answer choice: </p>
          <div class="form-check my-2" v-for="o,ind in qInfo['options']" :key="`O${ind}`">
            <input class="form-check-input" type="radio" id:="ind" :name="'answer'+qNo"
            v-bind:value="o" v-model="sResp['answer']">
            <p class="my-4 mathjax p-pre d-inline" v-if="o.length>0">
              <em v-html="qInfo['options'][ind]"></em></p>
            <label for="ind" v-else> Enter answer choice </label>
          </div>
          <p class="mb-0 text-end text-muted">{{qInfo['points']}} point(s) </p>
        </div>
      </div>
      <div v-else>
        <div class="mc-response q-field col-8 mx-auto my-2 p-4">
          <div class=" mb-1"> <small class="bg-light text-muted px-1">
          Multiple Choice </small></div>
          <h5 class="mt-2 q-no" v-if="qInfo['title']"> {{qInfo['title']}}</h5>
          <h5 class="mt-2 q-no" v-else> Question {{qNo}} </h5>
          <div v-show="qInfo['image']" class="mt-4 mb-2">
            <img v-bind:src="qInfo['image']" class="d-flex img-pre mx-auto" />
          </div>
          <p class="my-4" v-if="qInfo['problem'].length==0 && !qInfo['image']">
          Enter Problem Statement </p>
          <!-- <p class="my-4 mathjax p-pre" v-else><em v-html="qInfo['problem']"></em></p> -->
          <div class="component-container" v-for="pItem, pInd in qInfo['problem']"
            :key="pInd" v-else>
            <RichText :component="pItem" :ind="qNo" :preview="true"
            v-if="pItem.type==='richText'">
            </RichText>
            <Equation :component="pItem" :ind="pInd" :u-ind="`${qNo}_${pInd}`"
              type="fr-akey" :preview="true"
              v-else-if="pItem.type==='equation'">
            </Equation>
          </div>
          <div class="form-check my-2" v-for="o,ind in qInfo['options']" :key="`O${ind}`">
            <input class="form-check-input" type="radio" id:="ind" :name="'answer'+qNo"
            v-bind:value="o" v-model="sResp['answer']">
            <p class="my-4 mathjax p-pre d-inline" v-if="o.length>0">
              <em v-html="qInfo['options'][ind]"></em></p>
            <label for="ind" v-else> Enter answer choice </label>
          </div>
          <p class="text-end text-muted"> {{qInfo['points']}} point(s) </p>
        </div>
      </div>
    </div>

    <!-- Section Header -->
    <div id="fr-cont" v-else-if="qInfo['type']==2">
      <div>
        <div class="divider"> Section {{sNo+1}}/{{sLen}} </div>
        <div class="s-header q-field col-8 mx-auto mb-2 p-4">
          <div class="row ms-0 mt-4">
            <h4 class="px-0 py-1 t-fr col-sm-8" v-if="qInfo['title']" v-html="qInfo['title']">
            </h4>
            <h4 class="px-0 py-1 text-muted col-sm-8 h4" v-else >
              <em> Section Title </em>
            </h4>
          </div>

          <div data-ph="Description" class="px-0 py-1 col-sm-8" v-html="qInfo['desc']"
          v-if="qInfo['desc']">
          </div>
          <p data-ph="Description" class="px-0 py-1 col-sm-8 text-muted" v-else>
            <em>Description</em>
          </p>
        </div>
      </div>
    </div>

    <!-- Title/Instructions -->
    <div class="fr-cont" v-else-if="qInfo['type']==3">
      <div>
        <div class="f-response q-field col-8 mx-auto mb-2 p-4 ">
          <div class="row ms-0 mt-4">
            <h4 class="px-0 py-1 col-sm-8" v-if="qInfo['title']">
              {{qInfo['title']}}
            </h4>
            <h4 class="px-0 py-1 text-muted col-sm-8 h4" v-else >
              <em> Title </em>
            </h4>
          </div>

          <div data-ph="Description" class="px-0 py-1 col-sm-8" v-html="qInfo['desc']"
          v-if="qInfo['desc']">
          </div>
          <p data-ph="Description" class="px-0 py-1 col-sm-8 text-muted" v-else>
            <em>Description</em>
          </p>
        </div>
      </div>
    </div>

    <!-- Short Answer -->
    <div class="fr-cont" v-else-if="qInfo['type']==4">
      <div v-if="active">
        <div class="f-response q-field col-8 mx-auto my-2 p-4 selected-qn">
          <div class=" mb-1"> <small class="bg-light text-muted px-1">Short Answer </small></div>
          <h5 class="mt-2 q-no" v-if="qInfo['title']"> {{qInfo['title']}}</h5>
          <h5 class="mt-2 q-no" v-else> Question {{qNo}} </h5>
          <div v-show="qInfo['image']" class="mt-4 mb-2">
            <img v-bind:src="qInfo['image']" class="d-flex img-pre mx-auto" />
          </div>
          <!-- <p class="my-4  mathjax p-pre"><em v-html="qInfo['problem']"></em></p> -->
          <div class="component-container" v-for="pItem, pInd in qInfo['problem']"
            :key="pInd">
            <RichText :component="pItem" :ind="qNo" :preview="true"
            v-if="pItem.type==='richText'">
            </RichText>
            <Equation :component="pItem" :ind="pInd" :u-ind="`${qNo}_${pInd}`"
              type="fr-akey" :preview="true"
              v-else-if="pItem.type==='equation'">
            </Equation>
          </div>

          <input type="text" id="pStatement" name="problemStatement"
          placeholder="Type your answer here" class="px-0 py-1 t-fr mb-2"
          @focus="restoreSelection()"
          @click="saveSelection()" @blur="saveSelection()" v-model="sResp['answer']"/>
          <p class="mb-2 text-muted"> <em><small> Note: Answer must be one word,
          phrase, or numerical.</small></em></p>
          <p class="mb-0 text-end text-muted">{{qInfo['points']}} point(s) </p>
        </div>
      </div>
      <div v-else>
        <div class="f-response q-field col-8 mx-auto my-2 p-4" v-if="qInfo['type']==4">
          <div class=" mb-1"> <small class="bg-light text-muted px-1">Short Answer </small></div>
          <h5 class="mt-2 q-no" v-if="qInfo['title']"> {{qInfo['title']}}</h5>
          <h5 class="mt-2 q-no" v-else> Question {{qNo}} </h5>
          <div v-show="qInfo['image']" class="mt-4 mb-2">
            <img v-bind:src="qInfo['image']" class="d-flex img-pre mx-auto" />
          </div>
          <p class="my-4" v-if="qInfo['problem'].length==0 && !qInfo['image']">
          Enter Problem Statement </p>
          <!-- <p class="my-4 mathjax p-pre" v-else><em v-html="qInfo['problem']"></em></p> -->
          <div class="component-container" v-for="pItem, pInd in qInfo['problem']"
            :key="pInd" v-else>
            <RichText :component="pItem" :ind="qNo" :preview="true"
            v-if="pItem.type==='richText'">
            </RichText>
            <Equation :component="pItem" :ind="pInd" :u-ind="`${qNo}_${pInd}`"
              type="fr-akey" :preview="true"
              v-else-if="pItem.type==='equation'">
            </Equation>
          </div>
          <div class="mt-4 container border rounded p-1 pt-2">
            <p class="ms-2 pre-title text-muted bg-white"> answer </p>
            <div v-show="sResp['a-image']" class="my-3">
              <img v-bind:src="sResp['a-image']" class="d-flex img-pre mx-auto" />
            </div>
            <p class="mathjax px-1"><em v-html="sResp['answer']"></em></p>
          </div>
          <p class="text-end text-muted mt-4">{{qInfo['points']}} point(s) </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { makeMathField } from 'mathlive/dist/mathlive.min.js';
// import $ from 'jquery';
import { Modal } from 'bootstrap';
import axios from 'axios';
import Croppie from 'croppie';
import Equation from '@/components/EquationEditor.vue';
import RichText from '@/components/RichTextEditor.vue';

export default {
  name: 'TakeProblem',
  props: ['qInfo', 'qNo', 'sNo', 'sLen', 'sResp'],
  components: {
    Equation,
    RichText,
  },
  data() {
    return {
      active: false,
      aKey: false,
      editor: null,
      activeSct: false,
      previewImage: null,
      pContent: null,
      fileName: '',
      errors: [],
      screenshot: true,
      canvas: null,
      ctx: null,
      video: null,
      resize: null,
      imgPreview: null,
      shutter: false,
      countdown: 0,
      eqn: '',
      mf: null,
      savedRange: null,
      eqnModal: null,
      webcapmodal: null,
      // Croppie: null,
    };
  },
  computed: {
    loadScreen() {
      return this.$store.state.loadScreen;
    },
    userData() {
      return this.$store.state.userData;
    },
  },
  methods: {
    setActive() {
      this.active = true;
      this.reRender();
      // this.resetEditor();
      this.initContedit();
      this.updateField();
      // CKEDITOR.replace('problemEdit');
    },

    setInactive() {
      this.active = false;
      this.reRender();
      // this.resetEditor();
      this.updateField();
    },

    updateAnswer(event) {
      const el = event.target;
      const tempC = el.innerHTML;
      // let temp_obj = this.sResp;
      this.sResp.answer = tempC;

      // this.sResp=temp_obj;
      this.reRender();
    },

    updateField() {
      this.pContent = this.sResp.answer;
    },

    updateTitle(event) {
      this.qInfo.title = event.target.innerHTML;
    },

    updateDesc(event) {
      this.qInfo.desc = event.target.innerHTML;
    },

    saveSelection() {
      if (window.getSelection) { // non IE Browsers
        this.savedRange = window.getSelection().getRangeAt(0);
      } else if (document.selection) { // IE
        this.savedRange = document.selection.createRange();
      }
      // console.log(this.savedRange)
    },

    restoreSelection() {
      this.isInFocus = true;
      document.getElementById('pStatement').focus();
      if (this.savedRange != null) {
        if (window.getSelection) { // non IE and there is already a selection
          const s = window.getSelection();
          if (s.rangeCount > 0) s.removeAllRanges();
          s.addRange(this.savedRange);
        } else if (document.createRange) { // non IE and no selection
          window.getSelection().addRange(this.savedRange);
        } else if (document.selection) { // IE
          this.savedRange.select();
        }
      }
    },

    mountML() {
      // document.getElementById('mf').addEventListener('input', this.getLatex);
      this.eqnModal = new Modal(document.getElementById(`equationmodal${this.qNo}${this.sNo}`), {});
      this.eqnModal.show();
      this.mf = makeMathField('mf', {
        smartMode: false,
        smartSuperscript: true,
        virtualKeyboardMode: 'manual',
        customVirtualKeyboardLayers: {
          'layer-name': {
            styles: '',
            rows: [
              [
                {
                  class: 'keycap',
                  latex: '\\rightarrow',
                },
                {
                  class: 'keycap',
                  latex: '\\leftarrow',
                },
                {
                  class: 'keycap',
                  latex: '\\rightleftarrows',
                },
                {
                  class: 'keycap',
                  latex: '\\leftrightarrows',
                },
              ],
              [
                {
                  class: 'keycap',
                  latex: '\\rightharpoonup',
                },
                {
                  class: 'keycap',
                  latex: '\\leftharpoonup',
                },
                {
                  class: 'keycap',
                  latex: '\\rightharpoondown',
                },
                {
                  class: 'keycap',
                  latex: '\\leftharpoondown',
                },
              ],
              [
                {
                  class: 'keycap',
                  latex: '\\rightleftharpoons',
                },
                {
                  class: 'keycap',
                  latex: '\\leftrightharpoons',
                },
              ],

            ],
          },
        },
        customVirtualKeyboards: {
          arrows: {
            label: '&#x21C4;',
            tooltip: 'Arrows',
            layer: 'layer-name',
          },
        },
        virtualKeyboards: 'arrows all',
        onContentDidChange: (mf) => {
          const latex = mf.getValue();
          this.eqn = latex;
          // document.getElementById('latex').innerHTML = escapeHtml(
          //     latex
          // );

          //   const mathJSON = MathLive.latexToAST(latex);
          //   document.getElementById('mathjson').innerHTML = escapeHtml(
          //       JSON.stringify(mathJSON)
          //  );
        },
      });
      // console.log(mf)
    },

    unmountML() {
      // document.querySelector('mf').value = '';
      // document.getElementById('mf').removeEventListener('input', this.getLatex);
      this.mf.setValue('');
    },

    getLatex(event) {
      document.getElementById('output').innerHTML = event.target.getValue();
      this.eqn = event.target.getValue();
    },

    addEqn(field) {
      this.eqn = `#${this.eqn}#`;
      this.addToExp(this.eqn.replace(/(\\mleft|\\mright|\^\^\^\^200b)/g, ''), field);
      this.unmountML();
      this.eqnModal.hide();
    },

    reRender() {
      // this.$parent.updateMath();
      setTimeout(() => {
        window.MathJax.typesetPromise();
      }, 0);
    },

    addToExp(exp) {
      const d1 = document.getElementById('pStatement');
      if (!this.savedRange) {
        this.savedRange = document.createRange();
        this.savedRange.setStart(d1, 0);
        this.savedRange.setEnd(d1, 0);
      }
      this.savedRange.insertNode(document.createTextNode(exp));
      // if (d1.childElementCount == 0) {
      //  exp = "<p>" + exp + "</p>";
      //  d1.insertAdjacentHTML('beforeEnd', exp);

      //  console.log("if")
      // } else {
      //  d1.childNodes[0].insertAdjacentText('beforeEnd', exp);
      // }

      // exp = "<p>" + exp + "</p>";
      // d1.insertAdjacentText('beforeEnd', exp);

      // if (d1.childElementCount == 0) {
      //   // exp = "<p>" + exp + "</p>";
      //   d1.insertAdjacentText('beforeEnd', exp);

      // } else {
      //   d1.childNodes[d1.childNodes.length-1].insertAdjacentText('beforeEnd', exp);
      // }

      // console.log(d1.innerHTML);
      this.sResp.answer = d1.innerHTML;
      this.reRender();
    },

    initContedit() {
      document.execCommand('defaultParagraphSeparator', false, 'br');
    },
    launchWebCap() {
      this.webcapmodal = new Modal(document.getElementById('webcapmodal'), {});
      this.webcapmodal.show();
    },

    uploadImage(e) {
      const image = e.target.files[0];

      // const reader = new FileReader();
      // reader.readAsDataURL(image);
      // reader.onload = e =>{
      //     this.qInfo['image'] = e.target.result;
      // };

      const formData = new FormData();
      formData.append('file', image);
      formData.append('id', this.userData.id);
      axios.post('/api/assessment/uploadimage', formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          this.sResp['a-image'] = `${process.env.VUE_APP_API_URL}${response.data.url}`;
          this.fileName = response.data.fileName;
          this.screenshot = true;
          // $('#webcapmodal').modal('hide');
          this.webcapmodal.hide();
        });

      // this.$store.dispatch('uploadResponseImage', { file: image })
      //   .then((response) => {
      //     this.sResp['a-image'] = response.url;
      //     this.fileName = response.fileName;
      //     this.screenshot = true;
      //     this.webcapmodal.hide();
      //     // $('#webcapmodal').modal('hide');
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    },

    // delQuestion() {

    //   this.deactiveAkey();
    //   this.setInactive();
    //   this.$emit('del-question', this.qNo, this.sNo);
    // },

    delImage() {
      axios.post('api/assessment/deleteimage', {
        filename: this.fileName,
      })
        .then(() => {
          this.sResp['a-image'] = null;
          this.$forceUpdate();
        });
      // this.$store.dispatch('deleteResponseImage', { fileName: this.fileName })
      //   .then(() => {
      //     this.sResp['a-image'] = null;
      //     this.$forceUpdate();
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    },

    // delSection() {
    //   this.setInactive();
    //   this.$emit('del-section', this.sNo);
    // }

    initWebCap() {
      if (this.resize) {
        this.resize.destroy();
        this.resize = null;
      }
      this.screenshot = false;
      const constraints = {
        video: { height: 720, width: 1280 },
      };

      this.video = document.querySelector('video');

      navigator.mediaDevices.getUserMedia(constraints)
        .then((stream) => { this.video.srcObject = stream; });
    },

    webcapTimer() {
      if (this.countdown > 0) {
        this.countdown -= 1;
        // console.log(this.countdown);
        setTimeout(() => {
          this.webcapTimer();
        }, 1000);
      } else {
        // this.shutter=true;
        setTimeout(() => {
          this.ctx.drawImage(this.video, 0, 0);
        }, 500);

        setTimeout(() => {
          this.shutter = false;
          this.imgPreview = this.canvas.toDataURL('image/webp');
          this.screenshot = true;
          setTimeout(() => {
            const el = document.getElementById('a_img_pre');

            this.resize = new Croppie(el, {
              viewport: { width: el.width * 0.9, height: el.height * 0.9 },
              boundary: { width: el.width, height: el.height },
              showZoomer: false,
              enableResize: true,
              enableOrientation: true,
              mouseWheelZoom: 'ctrl',
            });

            this.resize.bind({
              url: this.imgPreview,
            });
          }, 10);

          this.video.srcObject.getTracks().forEach((track) => {
            track.stop();
          });
        }, 600);
      }
    },

    takeWebCap() {
      this.canvas = document.createElement('canvas');
      // this.video = document.querySelector('video');
      this.canvas.width = this.video.videoWidth;
      this.canvas.height = this.video.videoHeight;
      this.ctx = this.canvas.getContext('2d');
      this.ctx.filter = 'contrast(200%) grayscale(100%) brightness(100%)';

      this.countdown = 4;

      // if (this.webcapTimer()) {
      //   console.log('finished');
      //   this.shutter = true;
      //   this.ctx.drawImage(this.video, 0, 0);
      //   setTimeout(() => {
      //     this.shutter = false;
      //   }, 500);
      // }
      this.webcapTimer();
    },

    saveWebCap() {
      // const self = this;
      this.$store.commit('setLoadScreen', { status: true });
      this.resize.result('blob').then((blob) => {
        const formData = new FormData();
        formData.append('file', blob, 'webcap.png');
        formData.append('id', this.userData.id);
        axios.post('/api/assessment/uploadimage', formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            // const temp = this.sResp;
            this.sResp['a-image'] = `${process.env.VUE_APP_API_URL}${response.data.url}`;
            this.fileName = response.data.fileName;
            this.screenshot = true;
            // $('#webcapmodal').modal('hide');
            this.webcapmodal.hide();
            this.$store.commit('setLoadScreen', { status: false });
          })
          .catch((error) => {
            console.log(error);
            this.$store.commit('setLoadScreen', { status: false });
          });
        // this.webcapmodal.hide();
        // this.$store.dispatch('uploadResponseImage', { file: blob })
        //   .then((response) => {
        //     this.sResp['a-image'] = response.url;
        //     this.fileName = response.fileName;
        //     this.screenshot = true;
        //     this.$store.commit('setLoadScreen', { status: false });
        //     // $('#webcapmodal').modal('hide');
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //     this.$store.commit('setLoadScreen', { status: false });
        //   });
      });
    },

  },

  mounted() {
    if (this.sResp['a-image']) {
      const tempFileName = this.sResp['a-image'].split('/');
      this.fileName = tempFileName[tempFileName.length - 1];
    }
  },
};
</script>
<style>
@import '~croppie/croppie.css';
</style>

<template>
  <div>
    <div>
      <div class="row m-0 text-end" style="padding: 15px 20px;">
        <span class="text-muted font-weight-light">Administrator Portal</span>
      </div>
      <div class="col-md-10 ms-sm-auto me-sm-auto mt-4">
        <div class="card top-accent-card card-shadow mb-4">
          <div class="card-body">
            <div class="row">
              <div class="col border-right">
                <h4 class="card-title text-pri">{{ info.school }}</h4>
                <span>{{ numAccounts }} accounts</span><br>
                <ul class="pl-4" style="list-style-type: none;">
                  <li v-for="num, type in info.accounts" :key="num">
                    {{num}} {{ formatType(type) }}
                  </li>
                </ul>
              </div>
              <div class="col">
                <h5 class="card-title text-pri">Subscription tier: {{info.subscription}}</h5>
                <div v-if="info.subscription == 'Snack' || info.subscription == 'Demo'">
                  <span><strong>Lifetime</strong></span>
                </div>
                <div v-else>
                  Status: <span v-bind:class="{ 'text-danger' : status == 'Expired' }">
                    <strong>{{ status }}</strong></span><br>
                  {{ subscriptionPeriod }}
                </div>
                <div v-if="info.subscription == 'Snack'">
                  <span>{{ numAccounts }} out of 100 accounts used</span>
                </div>
                <div v-else-if="info.subscription == 'Brunch'">
                  <span>{{ numAccounts }} out of 1000 accounts used</span>
                </div>
                <div v-if="info.subscription == 'Snack'">
                  <button class="btn btn-link px-0" v-on:click="openUpgradePage">
                    Upgrade my subscription
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-group mt-5">
          <div class="card card-shadow left-accent-card hover-pointer me-2"
            @click="openAccountsPage">
            <div class="card-body">
              <h5 class="card-title text-pri">
                <span class="material-icons align-middle me-1" style="font-size: 1.5em;">
                  people_alt
                </span>
                Manage accounts
              </h5>
              <h5 class="card-subtitle text-muted font-weight-light">
                View accounts, invite users and delete unwanted accounts
              </h5>
            </div>
          </div>
          <div class="card card-shadow left-accent-card hover-pointer" v-on:click="openCodesPage">
            <div class="card-body">
              <h5 class="card-title text-pri">
                <span class="material-icons align-middle me-1" style="font-size: 1.5em;">
                  vpn_key
                </span>
                Verification codes
              </h5>
              <h5 class="card-subtitle text-muted font-weight-light">
                View and refresh verification codes
              </h5>
            </div>
          </div>
          <div class="card card-shadow left-accent-card hover-pointer"
            @click="launchStripePortal"
            v-if="info.subscription == 'Brunch' || info.subscription == 'Feast'">
            <div class="card-body">
              <h5 class="card-title">
                <span class="material-icons align-middle me-1" style="font-size: 1.5em;">
                  attach_money
                </span>
                Billing
              </h5>
              <h5 class="card-subtitle text-muted font-weight-light">
                Manage subscription and payments
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="expiredModal" tabindex="-1"
        v-if="info.subscription == 'Brunch' || info.subscription == 'Feast'">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Subscription expired</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>
                Your subscription expired {{ daysSinceExpire }} day(s) ago.
                You have <strong>{{ 10 - daysSinceExpire }} day(s)</strong>
                to update your payment details or downgrade your subscription.
                After this, your school's data may be
                <strong>permanently erased</strong>.
              </p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

// import $ from 'jquery';
import axios from 'axios';
import { Modal } from 'bootstrap';

export default {
  name: 'ITHome',
  components: {
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
    subscriptionPeriod() {
      if (this.info.subscription === 'Snack' || this.info.subscription === 'Demo') {
        const startDate = new Date(this.info.info.expiredate);
        startDate.setMonth(startDate.getMonth() - this.info.info.months);
        const endDate = new Date(this.info.info.expiredate);
        return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
      }
      return null;
    },
    daysSinceExpire() {
      if (this.info.subscription === 'Snack' || this.info.subscription === 'Demo') {
        const oneDay = 1000 * 60 * 60 * 24;
        const endDate = new Date(this.info.info.expiredate);
        const present = new Date();
        const numDays = Math.round(present.getTime() - endDate.getTime()) / (oneDay);
        return numDays.toFixed(0);
      }
      return null;
    },
  },
  data() {
    return {
      info: '',
      numAccounts: 0,
      status: 'Active',
    };
  },
  methods: {
    updateInfo() {
      axios.get(`/api/school/${this.userData.schoolId}/admin`)
        .then((response) => {
          this.info = response.data;
          this.numAccounts = Object.values(this.info.accounts).reduce((a, b) => a + b);
          console.log(this.info);
        });
    },
    formatType(type) {
      if (type === 'IT') {
        return 'IT administrator(s)';
      }
      return `${type}(s)`;
    },
    launchStripePortal() {
      axios.post('/api/stripe/create-portal-session', {
        cusid: this.info.info.stripeid,
      })
        .then((response) => {
          window.open(response.data.url);
        });
    },
    openCodesPage() {
      this.$router.push({ path: '/verification_codes' });
    },
    openAccountsPage() {
      this.$router.push({ path: '/manage_accounts' });
    },
    openUpgradePage() {
      this.$router.push({ path: '/upgrade_plan' });
    },
  },
  mounted() {
    this.updateInfo();
    if (this.info.subscription === 'Snack' || this.info.subscription === 'Demo') {
      setTimeout(() => {
        this.status = (new Date(this.info.info.expiredate) < new Date()) ? 'Expired' : 'Active';

        if (this.status === 'Expired') {
          console.log('hello');
          const expiredModal = new Modal(document.getElementById('expiredModal'));
          expiredModal.show();
        }
      }, 100);
    }
  },
};
</script>
<style scoped>
</style>

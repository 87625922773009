import { render, staticRenderFns } from "./TakeAss.vue?vue&type=template&id=72ab0709&scoped=true&"
import script from "./TakeAss.vue?vue&type=script&lang=js&"
export * from "./TakeAss.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72ab0709",
  null
  
)

export default component.exports
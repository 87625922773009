<template>
  <div id="assconfig" class="container-fluid py-5 col-8 mx-auto">
    <div v-if="allowed">
      <nav class="navbar fixed-top flex-md-nowrap p-0 navbar-expand-md">
        <button class = "navbar-toggler" type="button"
        data-toggle="collapse" data-target="#collapsibleNavbar">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class = "row mx-0 collapse navbar-collapse justify-content-between"
        id="collapsibleNavbar">
          <i class="col-sm-1 text-center text-primary bg-white material-icons md-40">assignment</i>
          <div class="col-sm-2">
            <input name="ass_name" type="text" class="form-control"
            placeholder="Assessment Name" v-model="aTitle"
            :class="{ 'is-invalid':$v.aTitle.$error }">
          </div>
          <div class="col-sm-2">
            <select class="form-select" name="ass_type" id="ass_type" v-model="sAtype">
            <option v-for="at, id in aTypes"
              :value="{'id': id, 'type': at}" :key="id">{{at}}</option>
            </select>
          </div>
          <div class="mx-0 col-sm-7 text-end">
            <p class="text-muted my-auto me-4 d-inline">
              <span class="align-middle text-primary me-1"
              v-if="saveMessage==='Saved' || saveMessage==='Saving...'">
                <small> <em> {{saveMessage}} </em> </small>
              </span>
              <span class="align-middle text-danger me-1" v-else>
                <small><em> {{saveMessage}} </em></small>
              </span>
            </p>
            <button class="me-1 btn btn-sm bg-acc1" @click="exportPDf">
              Export
            </button>
            <button class="me-1 btn btn-sm bg-pri" @click="saveChanges"> Save </button>
            <button class="me-1 btn btn-sm bg-acc2" @click="closePage"> Close </button>
          </div>
        </div>
      </nav>
      <div class="modal fade" id="saveModal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{saveMessage}}</h5>
              <button type="button" class="btn-close"
              data-bs-dismiss="modal" aria-label="Close">
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="exportModal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Exporting to PDF...</h5>
              <button type="button" class="btn-close"
              data-bs-dismiss="modal" aria-label="Close">
              </button>
            </div>
            <div class="modal-body">
              <div class="my-4 text-center">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ass-title card mt-5 col-8 mx-auto">
        <div class="card-body">
          <h5 class="card-title"> Assignment Details</h5>
          <div class="mt-2">
            <label for="self-paced">
              Self Paced
            </label>
            <label class="switch mt-2 align-middle float-end" id="self-paced">
              <input type="checkbox" v-model="selfPaced">
              <span class="slider round"></span>
            </label>
          </div>
          <div v-if="sAtype.id == 1">
            <div class="row mt-2">
              <div class="col-sm-6" v-show="!selfPaced">
                <label class="mt-2" for="releaseDate"> Release Time </label><br />
                <datetime type="datetime" v-model="releaseDate" @close="calcDuration()"
                :class="{ 'is-invalid':saved && $v.releaseDate.$error }">
                </datetime>
                <div v-if="saved && !$v.releaseDate.required"
                class="form-text error">Please specify a Release Time
                </div>
              </div>
              <div class="col-sm-6" v-show="!selfPaced">
                <label class="mt-2" for="dueDate"> Due Time </label><br />
                <datetime type="datetime" v-model="dueDate" @close="calcDuration()"
                :class="{ 'is-invalid':saved && $v.dueDate.$error }">
                </datetime>
                <div v-if="saved && !$v.dueDate.required"
                class="form-text error">Please specify Due Time
                </div>
              </div>
            </div>
            <label class="mt-2" for="assessment-weight"> Weightage (optional)</label>
            <input id="assessment-weight" name="assessment-weight" placeholder="Weightage" type="number" step="0.1" min="0"
            max="100" class="form-control mb-2" v-model="assessmentWeight">
            <label class="mt-2" for="ass_topic"> Topic </label>
            <select class="form-select" name="ass_topic" id="ass_topic" v-model="sTopic">
            <option v-for="topic, tInd in topics"
              :value="topic.id" :key="tInd">{{topic.name}}</option>
            </select>
            <div class="mt-4">
              <label for="draft">
                Save as Draft
              </label>
              <label class="switch mt-2 align-middle float-end" id="draft">
                <input type="checkbox" v-model="aDraft">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
          <div v-else>
            <div v-show="!selfPaced">
              <label class="mt-2" for="ass_date"> Scheduled Time </label><br />
              <datetime type="datetime" v-model="releaseDate"></datetime>
            </div>
            <div class="row mt-4">
              <div class="col">
                <label class="mt-2" for="ass_time"> Allowed Time (mins) </label>
                <input name="ass_time" type="number" step="1" min="1" class="form-control mb-2"
                v-model="aTime">
              </div>
              <div class="col">
                <label class="mt-2" for="ass_try">
                  Allowed Attempts
                </label>
                <input name="ass_try" type="number" step="1" min="1" class="form-control mb-2"
                v-model="aTries">
              </div>
            </div>
            <label class="mt-2" for="ass_weight"> Weightage (optional)</label>
            <input id="ass_weight" name="ass_weight" placeholder="Weightage" type="number" step="0.1" min="0"
            max="100" class="form-control mb-2" v-model="assessmentWeight">
            <label class="mt-2" for="ass_topic"> Topic </label>
            <select class="form-select" name="ass_topic" id="ass_topic" v-model="sTopic">
            <option v-for="topic, tInd in topics"
              :value="topic.id" :key="tInd">{{topic.name}}</option>
            </select>
            <div class="mt-4" v-if="subType!='Snack'">
              <label for="anti-cheat">
                Enable Anti-Cheating Checks:
              </label>
              <label class="switch mt-2 align-middle float-end" id="anti-cheat">
                <input type="checkbox" v-model="aCheat">
                <span class="slider round"></span>
              </label>
            </div>
            <div class="mt-2">
              <label for="draft">
                Save as Draft
              </label>
              <label class="switch mt-2 align-middle float-end" id="draft">
                <input type="checkbox" v-model="aDraft">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div id="assarea">
        <div v-for="p_dict,s in sDict" :key="s" @mouseover="activeS=s">

          <draggable :list="p_dict" @start="drag=true" @end="drag=false" handle=".handle"
          group="questions">
          <div v-for="p,q in p_dict" :key="q" class="p-cards"
            @click="editMode('sec'+s+'prob'+q)">
            <problem-template :ref="'sec'+s+'prob'+q" :q-info="p" :q-no="q" :s-no="s"
            :s-len="sDict.length" v-on:del-question="delQuestion" v-on:del-section="delSection">
            </problem-template>
          </div>
          </draggable>
        </div>
      </div>

      <div id="zoommodal" class="modal fade justify-content-center" role="dialog">
        <div id="zoom-dialog" class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content"
          style="background-color: transparent!important; border: none!important;">
            <div class="modal-header" >
              <button type="button" class="btn-close"
              data-bs-dismiss="modal" aria-label="Close">
              </button>
            </div>
            <div class="modal-body p-0 bg-white">
              <img id="zoomimg" src="">
            </div>
          </div>
        </div>
      </div>

      <div class="info-bar bg-white rounded py-2">
        <p class="text-muted my-auto text-center"> Total Points </p>
        <p class="h5 text-center my-auto" style="color: #bb4b25;">{{totPoints}}</p>
      </div>
      <div class="error-bar col-md-2 py-2" v-if="errors.length>0">
        <div class="bg-white rounded p-1 mb-2 fade-in" v-for="e in errors" :key="e">
          <p class="text-danger my-auto"><small> {{e}} </small></p>
        </div>
      </div>
      <div class="tool-bar col-1 justify-content-center">
        <div class="dropend mt-2">
          <button type="button" class="btn btn-primary" data-toggle-second="tooltip"
          data-placement="top" title="Add a new question" id="addQnDropdown"
          data-bs-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false"><i class="material-icons">add_box</i></button>
          <div class="dropdown-menu" aria-labelledby="addQnDropdown">
            <a class="dropdown-item" @click="addQ(0)">Free Response</a>
            <a class="dropdown-item" @click="addQ(1)">Multiple Choice</a>
            <a class="dropdown-item" @click="addQ(4)">Short Answer</a>
          </div>
        </div>
        <div class="mt-2">
          <button type="button" class="btn btn-primary" data-toggle="tooltip"
          data-placement="right" title="Add Title and Description" @click="addT">
          <i class="material-icons">text_fields</i>
          </button>
        </div>
        <div class="mt-2">
          <button type="button" class="btn btn-primary" data-toggle="tooltip"
          data-placement="right" title="Add a new section" @click="addS">
            <i class="material-icons">horizontal_split</i>
          </button>
        </div>
      </div>
      <div class="position-fixed bottom-0 start-0 p-3" style="z-index: 5">
        <div class="toast align-items-center text-white bg-primary border-0" role="alert"
        aria-live="assertive" aria-atomic="true" id="copyToast">
          <div class="d-flex">
            <div class="toast-body">
              Copied invitation!
            </div>
            <button type="button" class="btn-close btn-close-white me-2 m-auto"
            data-bs-dismiss="toast" aria-label="Close"></button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      You have reached the limit of assessments you can create under your subscription.
      Contact your administrator to upgrade.
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import {
  required,
} from 'vuelidate/lib/validators';
import draggable from 'vuedraggable';
// import CopyToClipboard from 'vue-copy-to-clipboard';
import { Modal, Toast } from 'bootstrap';
import { Datetime } from 'vue-datetime';
import * as dateUtils from '@/utils/dateUtils';
import ProblemTemplate from '../components/CreateProblem.vue';
import 'vue-datetime/dist/vue-datetime.css';

export default {
  name: 'CreateAss',
  components: {
    draggable,
    ProblemTemplate,
    Datetime,
    // CopyToClipboard,
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
    totPoints() {
      let tp = 0;
      this.sDict.forEach((sec) => {
        sec.forEach((prob) => {
          if (prob.points) {
            tp += parseInt(prob.points, 10);
          }
        });
      });
      return tp;
    },
    loadScreen() {
      return this.$store.state.loadScreen;
    },
    assignerID() {
      return this.$store.state.userData.fId;
    },
    assignerName() {
      return this.$store.state.userData.name;
    },
    // assID() {
    //   return this.$store.state.selectedAss;
    // },
    inviteText() {
      const invitation = `${this.assignerName} has shared an assignment with you.

Title: ${this.aTitle}
Type: ${this.sAtype.type}
Scheduled Time: ${new Date(this.releaseDate).toString()}

To access the assignment please go to the following link
and enter your information along with the passcode

Link: ${`${this.originUrl}/take/${this.assID}/`}
Passcode: ${this.accessToken}
`;

      return invitation;
    },
  },
  data() {
    return {
      // p_dict: [],
      // pIndndex: 0,
      // p_list: [],
      assID: null,
      allowed: true,
      sDict: [[{ type: 2, title: null, desc: null }]],
      types: { 0: 'f_response', 1: 'mcq', 4: 's_ans' },
      activeS: 0,
      aTitle: 'Untitled Assignment', // Default name for autosave
      aTypes: {},
      courses: [],
      sCourse: [],
      sAtype: 1,
      releaseDate: null,
      releaseGrades: false,
      dueDate: null,
      validDate: null,
      aInst: null,
      aTime: 1,
      assessmentWeight: null,
      aTries: 1,
      errors: [],
      // aId: null,
      uClist: 0,
      saved: false,
      saveMessage: 'Not Saved',
      aCheat: false,
      subType: 'subscription',
      eStatus: null,
      aDraft: true, // Default save as draft
      accessToken: null,
      originUrl: 'waffle.school',
      classId: null,
      selfPaced: true,
      topics: [{ id: null, name: 'No Data Available' }],
      sTopic: null,
    };
  },
  validations: {
    aTitle: { required },
    releaseDate: { required },
    dueDate: { required },
  },
  methods: {
    addS() {
      const tempS = this.sDict;
      tempS.push([{ type: 2, title: null, desc: null }]);
      this.sDict = tempS;
    },

    addQ(type) {
      if (this.sDict.length === 0) {
        this.addS();
      }

      const initScroll = $(window).scrollTop();
      // this.pIndndex +=1;
      // this.p_list.push(this.pIndndex);
      // this.p_dict[this.pIndndex]={'type':type, 'problem':"",
      // 'options':[""], 'solution':" ", 'points':0 };
      // this.p_dict.push({'type':type, 'problem':"",
      // 'options':[""], 'solution':" ", 'points':0, 'image':null });

      const tempP = this.sDict[this.activeS];
      tempP.push({
        title: '',
        type,
        problem: [],
        options: [''],
        solution: '',
        points: 0,
        image: null,
        feedback: '',
      });
      // this.sDict[this.activeS] = tempP;
      this.sDict[this.activeS] = tempP;

      // console.log(this.sDict);

      setTimeout(() => {
        window.scrollBy(0, initScroll);
      }, 0);
    },

    addT() {
      if (this.sDict.length === 0) {
        this.addS();
      }
      const initScroll = $(window).scrollTop();

      const tempP = this.sDict[this.activeS];
      tempP.push({ type: 3, title: null, desc: null });
      this.sDict[this.activeS] = tempP;

      setTimeout(() => {
        window.scrollBy(0, initScroll + 1000);
      }, 0);
    },

    updateMath() {
      setTimeout(() => {
        window.MathJax.typesetPromise();
      }, 0);
    },

    delQuestion(qNo, sNo) {
      this.sDict[sNo].splice(parseInt(qNo, 10), 1);
      this.$forceUpdate();
    },

    delSection(sNo) {
      this.sDict.splice(parseInt(sNo, 10), 1);
      this.$forceUpdate();
    },

    editMode(elem) {
      Object.keys(this.$refs).forEach((key) => {
        if (this.$refs[key][0]) {
          if (key !== elem && this.$refs[key][0].active) {
            this.$refs[key][0].setInactive();
          }
        }
      });
      if (this.$refs[elem][0].active === false) {
        this.$refs[elem][0].setActive();
      }
    },

    getType() {
      const typeIdx = Object.keys(this.aTypes).find((key) => this.aTypes[key] === this.sAtype);

      return parseInt(typeIdx, 10);
    },
    getTopics() {
      axios.get('/api/course/getTopics', {
        params: {
          cId: this.classId,
          role: this.userData.roleId,
        },
      })
        .then((response) => {
          this.topics = response.data.topics;
          this.sTopic = this.topics[0].id;
        })
        .catch((err) => {
          console.error(`Failed getting topics for course ID ${this.classId}`, err);
        });
    },
    getCourseId() {
      const courseIdx = [];
      this.sCourse.forEach((cTitle) => {
        courseIdx.push(
          Object.keys(this.courses).find((key) => this.courses[key].title === cTitle),
        );
      });

      return courseIdx;
    },

    updateClist() {
      this.uClist = 1;
    },

    setHomework() {
      this.aTries = 99999;
      // $('#ass_date').val(this.dueDate).change();
    },

    saveChanges() {
      const sTemp = JSON.parse(JSON.stringify(this.sDict));
      if (!this.assessmentWeight) {
        this.assessmentWeight = 100;
      }
      // console.log(this.releaseDate,this.dueDate);
      if (!this.aDraft) {
        this.saved = true;
        this.$v.$touch();
        if (this.getType() === 1 && this.releaseDate > this.dueDate
          && !this.selfPaced) {
          this.validDate = false;
          // this.aDraft = true;
        }
        this.errors = [];
        if (!this.aTitle) {
          this.errors.push('Please add an Assessment Name');
        }
        // if (!this.releaseDate) {
        //   this.errors.push('Please specify Scheduled Time');
        // } else if (!validDate) {
        //   this.errors.push("Please ensure 'Due Time' is after 'Release Time'");
        // }
        if (this.totPoints < 1) {
          this.errors.push('Assignments must be worth at least 1 point');
        }
      } else {
        if (!this.releaseDate) {
          this.releaseDate = '2000/01/01 00:00';
        }

        if (!this.aTime) {
          this.aTime = 1;
        }
      }

      if (this.errors.length > 0) {
        this.saveMessage = 'Please fix errors.';
        // any additional error handling
      } else if (!this.assID) {
        this.saveMessage = ' Saving...';
        // $('#saveModal').modal('show')

        // for(let s=0; s<this.sDict.length; s++){
        //   for(let )
        // }
        sTemp.forEach((sec, sInd) => {
          sec.forEach((prob, pInd) => {
            if (prob.problem) {
              // prob.problem.replace(/\\/g,"\\\\");
              // sTemp[sInd][pInd].problem = prob.problem.replace(/"/g, '"').replace(/'/g, "'");
            }
            if (prob.solution) {
              // prob.problem.replace(/\\/g,"\\\\");
              sTemp[sInd][pInd].solution = prob.solution.replace(/"/g, '"').replace(/'/g, "'");
              // this.sDict[sInd][pInd]['options'] = prob.options.replace(/\"/g,"\\\"");
            }
            if (prob.options) {
              prob.options.forEach((op, oInd) => {
                sTemp[sInd][pInd].options[oInd] = op.replace(/"/g, '"').replace(/'/g, "'");
              });
            }
          });
        });
        this.accessToken = Math.random().toString(36).substring(7);
        console.log(this.classId);
        axios.post('/api/assessment/setassessment', {
          newAss:
          {
            assignerID: this.assignerID,
            title: this.aTitle,
            aType: this.sAtype.id,
            problems: JSON.stringify(sTemp),
            totTime: this.aTime,
            totPoints: this.totPoints,
            schedUTC: new Date(this.releaseDate).toUTCString(),
            att: this.aTries,
            monitor: this.aCheat ? 1 : 0,
            draft: this.aDraft ? 1 : 0,
            numSubmitted: 0,
            numOpened: 0,
            releaseGrades: false,
            passcode: this.accessToken,
            weight: this.assessmentWeight,
            selfPaced: this.selfPaced,
            topic: this.sTopic,
          },
          user: this.userData.id,
          course: this.classId,
        })
          .then((response) => {
            this.assID = JSON.parse(response.data.aId);
            // this.$store.commit('setAssignment', { assID: objID.$oid });
            // console.log('Setting New Assessment');
            this.errors = [];
            if (this.releaseDate === '2000/01/01 00:00') {
              this.releaseDate = null;
              this.dueDate = null;
            }
            this.saveMessage = 'Saved';
            // setTimeout(()=>{

            //   $('#saveModal').modal('hide');
            //   this.closePage();
            // },1000)
          }).catch((error) => {
            if (error.response.status === 403) {
              this.$router.replace({ path: '/forbidden/' });
            } else {
              this.saveMessage = 'Something went wrong. Please try again.';
            }
          });
      } else {
        this.saveMessage = 'Saving...';
        // $('#saveModal').modal('show')

        sTemp.forEach((sec, sInd) => {
          sec.forEach((prob, pInd) => {
            if (prob.problem) {
              // prob.problem.replace(/\\/g,"\\\\");
              // sTemp[sInd][pInd].problem = prob.problem.replace(/"/g, '"').replace(/'/g, "'");
            }
            if (prob.solution) {
              // prob.problem.replace(/\\/g,"\\\\");
              sTemp[sInd][pInd].solution = prob.solution.replace(/"/g, '"').replace(/'/g, "'");
              // this.sDict[sInd][pInd]['options'] = prob.options.replace(/\"/g,"\\\"");
            }
            if (prob.options) {
              prob.options.forEach((op, oInd) => {
                sTemp[sInd][pInd].options[oInd] = op.replace(/"/g, '"').replace(/'/g, "'");
              });
            }
          });
        });
        axios.post('/api/assessment/updateassessment', {
          updatedAss: {
            // assignerID: this.assignerID,
            title: this.aTitle,
            aType: this.sAtype.id,
            cId: this.classId,
            problems: JSON.stringify(sTemp),
            totTime: this.aTime,
            weight: this.assessmentWeight,
            totPoints: this.totPoints,
            schedUTC: new Date(this.releaseDate).toUTCString(),
            att: this.aTries,
            monitor: this.aCheat ? 1 : 0,
            draft: this.aDraft ? 1 : 0,
            update: this.uClist,
            numSubmitted: 0,
            numOpened: 0,
            releaseGrades: this.releaseGrades,
            passcode: this.accessToken,
            selfPaced: this.selfPaced,
            topic: this.sTopic,
          },
          aId: this.assID,
          user: this.userData.id,
        }).then(() => {
          this.uClist = 0;
          this.errors = [];
          // this.saved = true;
          if (this.releaseDate === '2000/01/01 00:00') {
            this.releaseDate = null;
            this.dueDate = null;
          }
          this.saveMessage = 'Saved';
          // setTimeout(()=>{
          //   $('#saveModal').modal('hide');
          //   this.closePage();
          // },1000)
        }).catch((error) => {
          console.log(error);
          this.saveMessage = ' Something went wrong. Please try again.';
        });
      }
    },

    closePage() {
      clearTimeout(this.save_timer);
      this.save_timer = null;
      // TODO: There is no way to discard the assignment!
      this.saveChanges();
      this.$store.commit('setAssignment', { assID: null });
      // console.log('Setting to Null');
      this.$router.push(`/courses/${this.classId}/tasks`);
    },
    calcDuration() {
      let releaseTemp = new Date(this.releaseDate);
      let dueTemp = new Date(this.dueDate);
      releaseTemp = releaseTemp.getTime();
      dueTemp = dueTemp.getTime();
      // console.log(releaseTemp);
      this.aTime = (dueTemp - releaseTemp) / 60000;
      // console.log(this.aTime);
    },

    imgDetect(event) {
      if (event.target.nodeName === 'IMG') {
        const zoomModal = new Modal(document.getElementById('zoommodal'), {});
        zoomModal.show();
        document.getElementById('zoomimg').src = event.target.src;
      }
    },

    detectChange() {
      if (this.save_timer) {
        clearTimeout(this.save_timer);
        this.save_timer = null;
      }
      this.save_timer = setTimeout(() => {
        // console.log("saving...");
        this.saveChanges();
      }, 5000);
    },
    copyInvite() {
      const copyToast = new Toast(document.getElementById('copyToast'));
      copyToast.show();
      setTimeout(() => {
        copyToast.hide();
      }, 5000);
    },
    exportPDf() {
      Object.keys(this.$refs).forEach((key) => {
        if (this.$refs[key][0]) {
          if (this.$refs[key][0].active) {
            this.$refs[key][0].setInactive();
          }
        }
      });
      setTimeout(() => {
        const a = window.open('', '', 'height=700, width=900');
        const source = document.getElementById('assarea').innerHTML;
        let stylesHtml = '';
        document.querySelectorAll('link[rel="stylesheet"], style').forEach((node) => {
          stylesHtml += node.outerHTML;
        });
        /* eslint-disable no-useless-escape */
        a.document.write(`<html>
          <title>${this.aTitle}</title>
          ${stylesHtml}
          <script src="https://polyfill.io/v3/polyfill.min.js?features=es6"><\/script>
          <script id="MathJax-script"
          async src="https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js"><\/script>
          </head><body>`);
        a.document.write(`
          <div class="f-response q-field col-8 mx-auto mb-2 px-4 py-1">
            <div class="row mx-0">
              <div class="col">
                <p class="ms-5 print-title">Title: </p>
                <p class="ms-5 print-title">Scheduled Date:</p>
                <p class="ms-5 print-title">Total Points:</p>
              </div>
              <div class="col">
                <p class="ms-5 print-field">${this.aTitle}</p>
                <p class="ms-5 print-field">${dateUtils.default.prettyDate(this.releaseDate)}</p>
                <p class="ms-5 print-field">${this.totPoints}</p>
              </div>
            </div>
          </div>`);
        a.document.write(source);
        a.document.write('</body></html>');
        a.document.close();
        setTimeout(() => {
          a.print();
          a.close();
        }, 1000);
        /* eslint-enable no-useless-escape */
      }, 100);
    },
  },
  mounted() {
    this.classId = this.$route.params.cid;
    this.assID = this.$route.query.aID;
    this.originUrl = window.location.origin;
    document.body.addEventListener('dblclick', this.imgDetect);
    this.$store.commit('setLoadScreen', { status: true });
    this.getTopics();
    axios.get('/api/assessment/types')
      .then((response) => {
        this.aTypes = response.data.types;
        // this.courses= response.data.courses;
        // this.sCourse.push(this.courses[0][1]);
      }).catch((error) => {
        console.error('error getting assessment types', error);
        this.$store.commit('setLoadScreen', { status: false });
      });
    if (this.assID) {
      axios.post('/api/assessment/getassessment', {
        aId: this.assID,
        user: this.userData.id,
      })
        .then((response) => {
          const aTemp = response.data.assignment;
          this.aTitle = aTemp.title;
          this.sAtype = aTemp.aType;
          this.aTime = aTemp.totTime;
          this.aTries = aTemp.att;
          this.sTopic = aTemp.topic;
          this.releaseGrades = aTemp.releaseGrades;
          this.assessmentWeight = aTemp.weightage;
          // this.accessToken = aTemp.passcode;
          // console.log(this.sAtype);
          try {
            this.sDict = aTemp.problems;
          } catch (err) {
            console.error('Failed to set problems from temp to selected', err);
            this.$store.commit('setLoadScreen', { status: false });
          }

          // this.sDict = JSON.parse(aTemp[3]);
          // this.totPoints = aTemp[4];
          // this.aTitle = aTemp[0];
          // this.aTries = aTemp[8];

          if (aTemp.schedUTC === '2000/01/01 00:00') {
            this.releaseDate = null;
            this.dueDate = null;
          } else {
            this.releaseDate = new Date(aTemp.schedUTC).toISOString();
          }
          // console.log(aTemp[9], typeof aTemp[9]);
          this.aCheat = !!aTemp.monitor;
          this.aDraft = !!aTemp.draft;
          const self = this;
          setTimeout(() => {
            $('#ass_date').val(self.releaseDate).change();
          }, 0);

          // this.sCourse = response.data.courses;
          this.updateMath();
          if (parseInt(this.sAtype.id, 10) === 1) {
            // console.log('inhere');
            let tempR = new Date(this.releaseDate);
            // console.log(tempR);
            tempR = tempR.getTime();
            this.dueDate = tempR + this.aTime * 60000;
            // - (new Date()).getTimezoneOffset() * 60000;
            this.dueDate = new Date(this.dueDate).toISOString();
            // console.log(this.dueDate);
            // this.dueDate =
            //  `${new Date(this.dueDate).toISOString().slice(0, 10).replace(/-/g, '/')
            // } ${new Date(this.dueDate).toISOString().substr(11, 5)}`;
            // console.log(this.dueDate);
          }
          this.$store.commit('setLoadScreen', { status: false });
        });
      // .catch((error) => {
      //   console.log(error);
      //   this.$store.commit('setLoadScreen', { status: false });
      // });
    } else {
      this.sAtype = { id: 1, type: 'Homework' };
      this.$store.commit('setLoadScreen', { status: false });
    }

    const $sidebar = $('.tool-bar');
    const $window = $(window);

    $window.scroll(() => {
      $sidebar.stop().animate({
        bottom: `${-$window.scrollTop() + 10}px`,
      }, 'fast');
    });
    document.body.addEventListener('keyup', this.detectChange);

    // document.getElementById('ass_date').setAttribute("v-model","releaseDate");
    // CKEDITOR.replace('editor1');
  },

  beforeRouteLeave(to, from, next) {
    document.body.removeEventListener('keyup', this.detectChange);
    document.body.removeEventListener('dblclick', this.imgDetect);
    next();
  },
};
</script>

<style>
@media print {
  #assarea {
    background-color: white;
    height: 100%;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 15px;
    font-size: 14px;
    line-height: 18px;
  }
  * { overflow: visible !important; }
  .p-cards {
    width: 100% !important;
  }
  .page { page-break-after:always; }
  .info-bar {
    display: none !important;
    position: relative;

  }

  .error-bar {
    display: none !important;
    position: relative;

  }

  .points-bar {
    display: none !important;
    position: relative;

  }
  .t-answer {
    display: none !important;
  }
  .time-bar {
    display: none !important;
    position: relative;

  }
  .tool-bar {
    display: none !important;
    position: relative;
  }
  .material-icons {
    display: none !important;
  }
  .f-response {
    padding-top: 5rem !important;
    max-width: 90% !important;
  }
  .mc-response {
    padding-top: 5rem !important;
    max-width: 90% !important;
  }
  .fr-cont {
    /*padding-top: 1rem !important;*/
    break-inside: avoid;

  }
  .q-field{
    width:  90% !important;
  }
  #mc-cont {
    /*padding-top: 1rem !important;*/
    break-inside: avoid;
  }
  .divider {
    display: none;
  }
  .divider-print{
    display: block !important;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .print-title{
    font-size: 16px;
    color: #000;
  }
  .print-field{
    font-size: 16px;
    color: #bb4b25;
    font-weight: bold;
  }
  .q-label {
    text-align: right;
    color: #fff;
    background-color: #e6e6e6;
    margin-top: 1em;
    font-size: 8px;
  }
  .s-header {
    max-width: 90% !important;
  }

  img{
    max-width: 50% !important;
  }

  .form-check-input:checked {
    background-color: #0d6efd;
    border-color: #0d6efd;
}

}
#copy-link button {
  font-size: 12px;
  background-color: #bb4b25;
  color: #FFF5EB;
}
#copy-link i {
  /*color: #3f3f3f;*/
  margin-left: auto;
  font-size: 16px;
}
.vdatetime-input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.fixed-top {
  position: fixed;
  top: 0;
  left: 0px;
  height:70px;
  z-index: 1030;
}

.navbar {
  background-color: #FFFFFF;
  /*width: 100%;*/
  box-shadow: 0.01em 0.01em rgba(0,0,0,0.5);

}
.navbar i {
  font-size: 40px;
}
#fr-cont {
  /*padding-top: 1rem !important;*/
  break-inside: avoid;

}
#mc-cont {
  /*padding-top: 1rem !important;*/
  break-inside: avoid;
}
.active-sel{
  /*background-color: rgba(135,206,235,.15) !important;*/
  box-shadow: 0rem 0rem 0.5rem 0.5rem rgba(135,206,235,.15);
}
.ass-title{
  background-color: #ffffff;
  border-top-width: 0.5rem !important;
  border-top-color: #87ceeb !important;
  border-radius: 0.5rem;
  border-bottom: solid 1px rgb(218, 220, 224);
  border-left: solid 1px rgb(218, 220, 224);
  border-right: solid 1px rgb(218, 220, 224);
}

.btn-mini {

    padding: .25rem .5rem;
    font-size: .7rem;
    line-height: 1;
    border-radius: .2rem;
}
.delete-img {
  position: absolute;
  top:-0.5em;
  left: -0.5em;
}
.divider {
  width: 20%;
  margin-left:16.67% !important;
  background-color: #bb4b25;
  margin: 0;
  margin-top: 2em !important;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.2em;
  border-top-left-radius: 0.2em;
  height: 1.5em;
  color: white;
  text-align: center;
  font-style: bold;
}

.divider-print{
  display: none;
}

.divider-sl {
  width:calc(20% + 0.5rem);
  background-color: #bb4b25;
  margin-top: 2em !important;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.2em;
  border-top-left-radius: 0.2em;
  height: 1.5em;
  color: white;
  text-align: center;
  font-style: bold;
  margin-left: calc(16.67%) !important;
}
.e_btn {

  cursor: pointer;
}

.edit-box {
  background-color: #ffffff;
  top: 0%;
  left: -62%;
  width: 60%;
  position: absolute;
  height: 10em;
  text-align: left;
  /*max-height: 50%;*/
  padding: 1em;
  border-radius: 5px;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}

.edit-box-o{
  background-color: #ffffff;
  bottom: 0%;
  left: -64%;
  width: 60%;
  position: absolute;
  height: 10em;
  /*max-height: 50%;*/
  padding: 1em;
  border-radius: 5px;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}

.e-field{
  max-height: 100%;
  overflow-y: auto;
}

#exportModal {
  background: #000;
}
.f-response {
  background-color: #ffffff;
  border-radius: 0.5em;
  border: solid 1px rgb(218, 220, 224);
}

.edit-box-o:after {
  content: ".";
   visibility: hidden;
   display: block;
   height: 0;
   clear: both;
}

.f-response:after {
  content: ".";
   visibility: hidden;
   display: block;
   height: 0;
   clear: both;
}

.handle-ns {
  opacity: 0;
  cursor: move;
  color: #d3d3d3;
}

.f-response:hover .handle-ns{
  opacity: 1;
}

.img-container {
  position: relative;
}

.mc-response:hover .handle-ns{
  opacity: 1;
}

.mcq-opt{
  margin-left: -1.25rem;
}

.mc-response {
  background-color: #ffffff;
  border-radius: 0.5rem;
  /*overflow: hidden;*/
  border: solid 1px rgb(218, 220, 224);

}

.mc-response:after {
  content: ".";
   visibility: hidden;
   display: block;
   height: 0;
   clear: both;
}

.q-field{
  /*align-items: center;*/
}

.img-pre{

  max-width: 100%;
}

.img-pre-a{

  max-width: 100%;
  /*-webkit-filter: contrast(200%) grayscale(80%);
  -moz-filter: contrast(200%) grayscale(80%);
  -ms-filter: contrast(200%) grayscale(80%);
  -o-filter: contrast(200%) grayscale(80%);
  filter: contrast(200%) grayscale(80%);*/
}

#p-image {
  width: 0.1px;
  height:0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.o-image {
  width: 0.1px;
  height:0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

#a-image {
  width: 0.1px;
  height:0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.point-input{
  outline: none;
  border: none;
  border-bottom: 0.05rem solid #808080;
  max-width: 3em;
}

.point-input:focus{
  border-bottom: 0.1rem solid #87ceeb;
  background-color: rgba(135,206,235,.15);
}

.pre-title {
  margin-top:-1.5em;
  max-width: max-content;
  /*background-color: #bb4b25;*/
}

#pStatement {
  max-height: 100%;
  overflow-y: auto;
}

#pStatement img {
  max-width: 100%;
}

.p-pre img {
  max-width: 100%;
  transition:transform 0.25s ease;
}

/*.p-pre img:active {
  -webkit-transform:scale(1.5);
  transform:scale(1.5);
  z-index: 999999 !important;

}*/

.selected-qn {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  border-left: solid 0.5rem #bb4b25 !important;
  padding-left: 1em !important;
  position: relative;
  margin-left: -0.5rem;
  /*margin-left: calc(18% - 0.5rem) !important;*/
}

.s-header{
  background-color: #ffffff;
  border-bottom-right-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  border-top-left-radius: 0;
  overflow: hidden;
  border: solid 1px rgb(218, 220, 224);
}

.special-char-tray {

  font-size: 10px;

}

.sym-tool {
  width: 20em;
  max-height: 5em;
  overflow-y: scroll;
}

.t-answer{
  width: 100%;
  min-height: 6em;
}

.t-fr{
  width: 100%;
  min-height: 1em;
  border: 0;
  border-bottom: 0.05rem solid #808080;
  outline: none;
  background-color: rgba(245,245,220,0.5)
}

.t-fr:focus {
  border-bottom: 0.1rem solid #87ceeb;
  background-color: rgba(135,206,235,.15)

}

[contentEditable=true]:empty:not(:focus):before{
  content:attr(data-ph);
  color:grey;
  font-style:italic;
}

#page-wrap {
  width: 600px;
  margin: 15px auto;
  position: relative;
}

.tool-bar {
  position: absolute;
  bottom: 10px;
  left: 85%;
/*  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
*/
}

.info-bar {
  position: fixed;
  top: 8em;
  left: 75%;
  width: 8em;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

}

.error-bar {
  position: fixed;
  top: 8em;
  left: 5em;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

}

.points-bar {
  position: fixed;
  top: 10em;
  right: 5%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

}

/*.tracking {
  position: fixed;
  top: 14em;
  left: 7%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

}*/

.tracking-cam {
  width: 100%;
  height: 100%;
  /*-webkit-filter: contrast(200%) grayscale(80%) brightness(150%);
  -moz-filter: contrast(200%) grayscale(80%);
  -ms-filter: contrast(200%) grayscale(80%);
  -o-filter: contrast(200%) grayscale(80%);
  filter: contrast(200%) grayscale(80%);*/
  transform: rotateY(180deg);
    -webkit-transform:rotateY(180deg);
    -moz-transform:rotateY(180deg);
    -ms-transform:rotateY(180deg);
    -o-transform:rotateY(180deg);
}

.b-shutter {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  z-index: 9999;
}

.webcap-time {
  position: absolute;
  top: 30%;
  left: 45%;

}

.time-bar {
  position: fixed;
  top: 17em;
  right: 5%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

}

.ML__keyboard {
  z-index: var(--keyboard-zindex,2000) !important;
}

.ML__popover {
  z-index: 2000 !important;
}

/* SLIDER STYLING*/

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
}

#zoomimg {
  width: 100%;
}

#zoom-dialog {
  margin-right: auto !important;
  margin-left: auto !important;
  width: 40% !important;
  max-width: 100% !important;

}

div.tools {
    display: none;
    list-style: none;
    box-shadow: 0px 0px 4px rgba(0,0,0,.5);
    position: absolute;
    z-index: 1;
}

.error {
  color: red !important;
}
</style>

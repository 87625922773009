import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import firebase from 'firebase';
import createPersistedState from 'vuex-persistedstate';
import * as Cookies from 'js-cookie';
// import 'firebase/auth';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState({
    getState: (key) => Cookies.getJSON(key),
    setState: (key, state) => Cookies.set(key, state, { expires: 3, secure: true }),
  })],
  state: {
    userData: {},
    jwt: '',
    loggedIn: false,
    selectedAss: null,
    respInfo: null,
    currentUser: null,
    error: false,
    errorMessage: '',
    loadScreen: false,
    courseInfo: {
      id: null,
      title: null,
      published: false,
    },
  },
  actions: {
    login(context, userInfo) {
      return new Promise((resolve, reject) => {
        axios.post('/api/account/login/', userInfo)
          .then((response) => {
            context.commit('setUserData', { userData: response.data.userData });
            context.commit('setJwtToken', { jwt: response.data.access_token });
            if (Vue.appInsights) {
              Vue.appInsights.setAuthenticatedUserContext(response.data.userData.id);
              Vue.appInsights.trackEvent({
                name: 'Login',
                properties: { // accepts any type
                  email: userInfo.email,
                  user_id: response.data.userData.id,
                  success: true,
                },
              });
            }
            resolve();
          })
          .catch((error) => {
            console.log('Error Authenticating: ', error);
            Vue.appInsights.trackEvent({
              name: 'Login',
              properties: { // accepts any type
                email: userInfo.email,
                success: false,
              },
            });
            reject();
          });
      });
    },
    logout(context) {
      return new Promise((resolve, reject) => {
        try {
          context.commit('clearData');
          resolve();
        } catch (err) {
          reject(err);
        }
      });
    },
    uploadResponseImage(context, payload) {
      return new Promise((resolve, reject) => {
        const storageRef = firebase.storage().ref();
        const fileName = `rsp_${Date.now()}_${context.state.guestData.email}.png`;
        const imageRef = storageRef.child(fileName);
        imageRef.put(payload.file)
          .then((snapshot) => {
            snapshot.ref.getDownloadURL()
              .then((downloadURL) => {
                resolve({ url: downloadURL, fileName });
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteResponseImage(context, payload) {
      return new Promise((resolve, reject) => {
        const storageRef = firebase.storage().ref();
        const imageRef = storageRef.child(payload.fileName);
        imageRef.delete(payload.file)
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    joinSchool(context, data) {
      return new Promise((resolve, reject) => {
        context.commit('setLoadScreen', { status: true });
        axios.post('/api/school/join', data)
          .then(() => axios.get('/api/account/me'))
          .then(({ data: me }) => {
            context.commit('setLoadScreen', { status: false });
            context.commit('setError', { status: false }); // set to false, in case previous error was not dismissed
            context.commit('updateUserData', me);
          })
          .catch((error) => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              if (error.response.data && error.response.data.message) {
                console.error(error.response.data.message);
                context.commit('setError', { status: true, errorMessage: error.response.data.message });
                reject(error.response.data.message);
              }
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.error(error.request);
              reject(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.error('Error', error.message);
              reject(error.message);
            }
          });
      });
    },
    loadCourseInfo(context, { id }) {
      return new Promise((resolve, reject) => {
        context.commit('setLoadScreen', { status: true });
        axios.get(`/api/course/${id}`)
          .then((response) => {
            context.commit(
              'setCourseInfo',
              { id, title: response.data.title, published: response.data.published },
            );
            context.commit('setLoadScreen', { status: false });
            resolve();
          })
          .catch((error) => {
            console.error('Error', error.message);
            reject(error);
          });
      });
    },
  },
  mutations: {
    setUserData(state, payload) {
      state.userData = payload.userData;
      state.loggedIn = true;
    },
    setJwtToken(state, payload) {
      console.log('storing access token');
      localStorage.setItem('access_token', payload.jwt);
      state.jwt = payload.jwt;
    },
    setEmailVerified(state) {
      state.userData.verified = true;
    },
    clearData(state) {
      console.log('clearing access token');
      state.jwt = '';
      localStorage.removeItem('access_token');
      state.loggedIn = false;
      state.userData = {};
    },
    updateUserData(state, payload) {
      state.userData = payload;
    },
    setLoggedIn(state, status) {
      state.loggedIn = status;
    },
    setAssignment(state, payload) {
      state.selectedAss = payload.assID;
    },
    setRespInfo(state, payload) {
      state.respInfo = payload.respInfo;
    },
    setCurrentUser(state, payload) {
      state.currentUser = payload.currentUser;
    },
    setCourseInfo(state, { id, title, published }) {
      state.courseInfo.id = id;
      state.courseInfo.title = title;
      state.courseInfo.published = published;
    },
    setError(state, payload) {
      state.error = payload.status;
      state.errorMessage = payload.errorMessage ?? '';
    },
    setLoadScreen(state, payload) {
      state.loadScreen = payload.status;
    },
  },
});

<template>
  <section class="row g-0 min-vh-100 p-0">
    <div class="col-lg-4 justify-content-end d-flex bg-marigold-gradient">
      <img src="~@/assets/dots_color.png" class="bg-image">
      <div class="divider-login divider-vertical d-none d-lg-block">
        <svg width='100%' height="100%" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none">
          <path d="M50,0 L100,0 L100,100 L50,100 C83.333333,83.3333333 100,66.6666667
          100,50 C100,33.333333 83.33333333,16.6666667 50,0 Z" fill="#FDF7ED">
          </path>
        </svg>
      </div>
    </div>
   <div class="col d-flex align-self-center register-col p-5">
      <div class="container my-auto register-card text-center">
        <form @submit.prevent="submitForm">
          <h1 class="text text-center">Welcome to PPLE</h1>
          <div>
            <div class="text-start my-4 row input-container">
              <label class="form-label fs-5">Email</label>
              <input type="text" name="email"
              class="input-field" v-model="email"
              :class="{ 'is-invalid': submitted && $v.email.$error }"/>
              <span class="input-border"></span>
              <div v-if="submitted && !$v.email.required" class="error">
              Email is required</div>
              <div v-if="submitted && !$v.email.email" class="error">
              Email is invalid</div>
            </div>
            <div class="text-start my-4 row input-container">
              <label class="form-label fs-5">Password</label>
              <input type="password" name="password"
              class="input-field" v-model="secret"
              :class="{ 'is-invalid': submitted && $v.secret.$error }"/>
              <span class="input-border"></span>
              <div v-if="submitted && !$v.secret.required"
              class="error">Password is required</div>
            </div>
            <div v-if="invalid" class="error mt-2 mb-2 text-start">
              <small class="fw-bold"> Email or Password is incorrect. Please try again.</small>
            </div>
            <div class="row">
              <div class="col">
                <router-link to="/forgotpassword" class="float-start text-primary pl-2">
                Forgot Password?</router-link>
              </div>
              <div class="col bg-gradient-primary text-white">
                <button type="submit"
                class=" float-end btn bg-pri text-center">
                  Login
                </button>
              </div>
            </div>
            <br><hr>
            <!-- <p class="fs-5"> or
              <button @click="checkGoogleUser" class="social-button mt-2">
                <img alt="Google Logo" src="../assets/btn_google_signin_light_normal_web.png">
              </button>
            </p> -->
            <div class="form-group row">
              <p>Don't have an account?
                <a class="text-start text-primary pl-2" href="/register/">
                  Create a new account
                </a>
                today.
              </p>
            </div>
            <div class="form-group row">
              <p>Interested in bringing your school on Quill?
                <a class="text-start text-primary pl-2" href="https://waffle.school/waffle-lms">
                  Find out more
                </a>
                .
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
// import axios from 'axios';
import {
  required, email,
} from 'vuelidate/lib/validators';
// import firebase from 'firebase';

export default {
  name: 'Login',
  components: {
  },
  data() {
    return {
      email: '',
      secret: '',
      submitted: false,
      googleSubmitted: false,
      invalid: false,
      userData: {},
      resetEmail: null,
      emailVerified: null,
      toUrl: '',
    };
  },
  validations: {
    email: { required, email },
    secret: { required },
  },
  computed: {
    loadScreen() {
      return this.$store.state.loadScreen;
    },
  },
  methods: {
    submitForm() {
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.commit('setLoadScreen', { status: true });
        this.authenticate();
      }
    },
    authenticate() {
      this.$store.dispatch('login', { email: this.email, password: this.secret })
        .then(() => {
          this.$store.commit('setLoadScreen', { status: false });
          if (this.toUrl) {
            this.$router.push(this.toUrl);
          } else {
            this.$router.push('/');
          }
        })
        .catch(() => {
          this.$store.commit('setLoadScreen', { status: false });
          this.invalid = true;
        });
    },
  },
  mounted() {
    this.toUrl = this.$route.query.redirect;
  },

};
</script>

<style scoped>
.error {
  color: red;
}

.button-color{
  background-color: #0067A5 !important;
  color: #FFF !important;
}

#email-field i {
  position: absolute;
  top: 270px;
  right: 30px;
  /*min-width: 40px;*/
}

label {
  width: 100%;
}

.card-select {
  display: none;
}

.card-input {
  margin: 00px;
  height: 100%;
  width: 100%;
  border-radius: 2em;
}

.card-input:hover {
  cursor: pointer;
  box-shadow: .25rem .25rem rgba(20,1,1,0.075);
}

.card-select:checked + .card-input {
  background-color: #E97852;
  box-shadow: .125rem .25rem rgba(0,0,0,0.075);
 }

.divider-login {
  position: relative;
  z-index: 0; }
  .divider-login svg {
    background: none !important;
    height: 6vw; }
    .divider-login svg:not([class*='bg']) path {
      background-color: #f3ece6; }
  .divider-login:not(:first-child) {
    top: 1px; }
  .divider-login:first-child {
    bottom: 1px; }
  .divider-login.divider-vertical {
    height: 100vh;
    width: 25rem;
    top: 0;
    left: 1px; }
    .divider-login.divider-vertical svg {
      height: 100vh;
      width: 25rem; }

.bg-image {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; }
  .bg-image:not([class*='absolute']) {
    position: absolute; }
  .bg-image + .card-body {
    position: relative;
    z-index: 0; }

img.bg-image {
  width: 50%;
  object-fit: cover;
  vertical-align: middle;
    border-style: none; }

.register-col {
  z-index: 1;
  position: relative;
  background-color: #FDF7ED;
  height: 100vh;
}

.register-card {
  max-width: 50%;
}

.bg-marigold-gradient {
  /*background: rgb(187,75,37);*/
  background: linear-gradient(140deg, rgba(0,103,165,1) 0%, rgba(0,33,71,1) 70%);
}

.social-button img {
  width: 100%;
  background-color: #f3ece6;
}
.social-button {
  border: 0;
}

</style>

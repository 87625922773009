<template>
  <div class="bg-overlay">
    <div v-if="verified===false" class="col-lg-6 mx-auto align-self-center my-auto text-center">
      <p>
      Verifying your email, please wait a moment
      </p>
    </div>
    <div v-else class="row mx-0 p-5">
      <div class="col-lg-6 mx-auto align-self-center my-auto text-center">
        <p>
        Your email has been verified.
        </p>
        <p class="text-center my-3" >You will be redirected in {{redirectTime}}</p>
        <div class="bg-gradient-primary text-white">
          <button type="submit"
          class=" float-center btn button-color btn text-center"
          @click="homeRedirect">
            HOME
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
import {
  required, email,
} from 'vuelidate/lib/validators';

export default {
  name: 'VerifyEmail',
  data() {
    return {
      verified: false,
      email: '',
      secret: '',
      submitted: false,
      toUrl: '',
      redirectTime: 5,
    };
  },
  validations: {
    email: { required, email },
    secret: { required },
  },
  computed: {
    loadScreen() {
      return this.$store.state.loadScreen;
    },
    userData() {
      return this.$store.state.userData;
    },
  },
  methods: {
    decoder64(param) {
      const encoded = atob(param);
      return encoded;
    },
    submit() {
      this.$store.commit('setLoadScreen', { status: true });
      const payload = {
        email: this.email,
      };
      axios.post('/api/account/verifyEmail', payload)
        .then(() => {
          // Email address has been verified.
          this.verified = true;
          this.$store.commit('setEmailVerified');
          this.$router.push(this.toUrl);
          this.$store.commit('setLoadScreen', { status: false });
          setTimeout(() => {
            this.redirectTimer();
          }, 100);
          // console.log(resp);
          // TODO: Display a confirmation message to the user.
          // You could also provide the user with a link back to the app.

          // TODO: If a continue URL is available, display a button which on
          // click redirects the user back to the app via continueUrl with
          // additional state determined from that URL's parameters.
        }).catch((error) => {
          this.$store.commit('setLoadScreen', { status: false });
          console.log(error);
        // Code is invalid or evxpired. Ask the user to verify their email address
        // again.
        });
    },
    homeRedirect() {
      clearInterval(this.interval);
      this.$router.push('/');
    },
    redirectTimer() {
      this.interval = setInterval(() => {
        if (this.redirectTime > 0) {
          this.redirectTime -= 1;
        } else {
          this.homeRedirect();
        }
      }, 1000);
    },
  },
  mounted() {
    this.email = this.decoder64(this.$route.params.eid);
    this.toUrl = this.$route.query.redirect;
    this.submit();
  },
  beforeMount() {
    if (this.userData.verified) {
      this.$router.push('/');
    }
  },
};
</script>
<style scoped>
.error {
  color: red;
}

.button-color{
  background-color: #BB4B25 !important;
  color: #FFF !important;
}
</style>

<template>
  <div class="container-fluid my-auto register-card text-center">
    <div v-if="status==false">
      <form @submit.prevent>
        <div>
          <h3>Welcome to Quill</h3>
          <h5>Create Your Account</h5>
          <p class="text-small"> If the information displayed is incorrect,
          please contact your facility's IT administrator.
          </p>
          <div class="text-start text-secondary mb-3 row input-container">
            <label class="form-label fs-6" for="schoolName">School</label>
            <input class="fs-6 input-field" id="schoolName"
            type="text" v-model="schoolName" disabled>
          </div>
          <div class="text-start text-secondary mb-3 row input-container">
            <label class="form-label fs-6">Given Name(s)</label>
            <input type="text" name="firstname"
            class=" fs-6 input-field" v-model="user.firstName"
            :class="{ 'is-invalid': submitted && $v.user.firstName.$error }"/>
            <span class="input-border"></span>
            <div v-if="submitted && !$v.user.firstName.required"
            class="form-text error">First Name is required</div>
          </div>
          <div class="text-start text-secondary mb-3 row input-container">
            <label class="form-label fs-6" >Last Name</label>
            <input type="text" name="lastname"
            class=" fs-6 input-field" v-model="user.lastName"
            :class="{ 'is-invalid': submitted && $v.user.lastName.$error }"/>
            <span class="input-border"></span>
            <div v-if="submitted && !$v.user.lastName.required"
            class="form-text error">Last Name is required</div>
          </div>
          <div id="email-field" class="text-start text-secondary mb-3 row input-container">
            <label class="form-label fs-6">Email</label>
            <!-- <input @blur="checkUser" @focus="emailEntry=false" type="text" name="email"
            class=" fs-6 input-field" v-model="user.userEmail"
            :class="{ 'is-invalid': submitted && $v.user.userEmail.$error,
            'user-valid': userValid && emailEntry && !$v.user.userEmail.$error,
            'user-invalid': emailEntry && !$v.user.userEmail.$error }"> -->
            <input type="text" name="email"
            class=" fs-6 input-field" v-model="user.userEmail"
            :class="{ 'is-invalid': submitted && $v.user.userEmail.$error,
            'user-valid': userValid && emailEntry && !$v.user.userEmail.$error,
            'user-invalid': emailEntry && !$v.user.userEmail.$error }" disabled>
            <span class="input-border"></span>
            <div v-if="submitted && !$v.user.userEmail.required" class="form-text error">
              Email is required</div>
            <div v-if="submitted && !$v.user.userEmail.email" class="form-text error">
            Email is invalid</div>
            <div v-show="emailEntry && !userValid && !$v.user.userEmail.$error"
            class="error">
              <small>
                This email is already been registered, please sign in
                <router-link class= "text-danger text-underline" to="/login">here</router-link>
              </small>
            </div>
          </div>
          <div v-if="inputRole == 'administrator'">
            <div class="text-start text-secondary mb-3">
              <div class="form-label fs-6">Role</div>
              <div class="form-check">
                <input class="form-check-input" type="radio" id="adminRadio" value="IT"
                name="administrator" v-model="inputRole" disabled>
                <label class="form-check-label" for="adminRadio">
                  Administrator
                </label>
              </div>
            </div>
          </div>
          <div v-if="inputRole == 'teacher'">
            <div class="text-start text-secondary mb-3">
              <div class="form-label fs-6">Role</div>
              <div class="form-check">
                <input class="form-check-input" type="radio" id="teacherRadio"
                value="teacher" name="teacher" v-model="inputRole" disabled>
                <label class="form-check-label" for="teacherRadio">
                  Teacher
                </label>
              </div>
            </div>
          </div>
          <div v-if="inputRole == 'student'">
            <div class="text-start text-secondary mb-3">
              <div class="form-label fs-6">Role</div>
              <div class="form-check">
                <input class="form-check-input" type="radio" id="studentRadio"
                value="student" name="student" v-model="inputRole" disabled>
                <label class="form-check-label" for="studentRadio">
                  Student
                </label>
              </div>
            </div>
          </div>
          <div class="text-start text-secondary mb-3 row input-container">
            <label class="form-label fs-6">Password</label>
            <input type="password" name="password"
            class=" fs-6 input-field" v-model="user.password"
            :class="{ 'is-invalid': submitted && $v.user.password.$error }"/>
            <span class="input-border"></span>
            <div v-if="submitted && !$v.user.password.required" class="form-text error">
            Password is required</div>
            <div v-if="submitted && !$v.user.password.minLength" class="form-text error">
            Password must be at least 8 characters long</div>
            <div v-if="submitted && !$v.user.password.valid && user.password"
            class="form-text error">
            Password must contain Uppercase and lowercase characters, numbers and symbols</div>
          </div>
          <div class="text-start text-secondary mb-3 row input-container">
            <label class="form-label fs-6" >Confirm Password</label>
            <input type="password" name="confirmpassword"
            class=" fs-6 input-field" v-model="user.confirmPassword"
            :class="{ 'is-invalid': submitted && $v.user.confirmPassword.$error }"/>
            <span class="input-border"></span>
            <div v-if="submitted && !$v.user.confirmPassword.required"
            class="form-text error">Confirm Password is required</div>
            <div v-if="submitted && !$v.user.confirmPassword.sameAsPassword"
            class="form-text error">Passwords do not match</div>
          </div>
          <div>
            <p class="text-start">
            By signing up, you agree to our <a class="text-primary fw-light form-text"
            id="a" href="http://waffle.school/terms-conditions">T&C and privacy policy</a>
            </p>
          </div>
          <div class="mt-0">
            <button type="submit"
            class=" float-end btn bg-pri my-2 text-center text-white" @click="submitForm">
              Create Account
            </button>
          </div>
        </div>
      </form>
    </div>
    <div v-else>
      <h4 class="text-center my-3" >Your Registration is Successful!</h4>
      <p class="text-center my-3" >A confirmation email was sent to your registered email,
        please verify your email</p>
      <p class="text-center my-3" >You will be redirected in {{redirectTime}}</p>
      <div class="form-group bg-gradient-primary text-white mt-4">
        <button type="button" class="btn bg-pri text-white mt-4 text-center float-center"
        @click="loginRedirect">Login
        </button>
      </div>
    </div>
      <!-- <div class="modal fade" id="uploadModal"
        tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Upload csv</h5>
              <button type="button" class="btn-close"
                data-bs-dismiss="modal" aria-label="Close">
              </button>
            </div>
            <div class="modal-body">
              <label class="mt-4" for="datafile"> Upload .csv containing raw data: </label><br />
              <input type="file" id="datafile" name="datafile" accept=".csv"
              @change="uploadFile"><br/>
              <h4 v-show="status">{{status}}</h4>
            </div>
            <div class="modal-body">
              <label class="mt-4" for="data"> Download .csv Template </label><br/>
              <button type="button" class="btn btn-info btn-sm
              text-white mt-3" data-dismiss="modal"> Download Template </button>
              <input type="file" id="datafile" name="datafile" accept=".csv"
              @change="File"><br/>
              <h4 v-show="status">{{status}}</h4>
            </div>
          </div>
        </div>
      </div> -->
  </div>
</template>

<script>
import axios from 'axios';
import { Modal } from 'bootstrap';
import {
  required, email, minLength, sameAs,
} from 'vuelidate/lib/validators';

export default {
  name: 'InvitePage',
  components: {
  },
  computed: {
    // loadScreen() {
    //   return this.$store.state.loadScreen;
    // },
  },
  data() {
    return {
      user: {
        firstName: '',
        lastName: '',
        userEmail: '',
        password: '',
        confirmPassword: '',
      },
      csvdata: null,
      assFocus: null,
      roleId: '',
      roles: [],
      schoolName: '',
      schoolId: '',
      schoolCode: '',
      inputRole: null,
      submitted: false,
      invalid: null,
      schoolValid: null,
      userValid: null,
      codeValid: null,
      schoolEntry: false,
      emailEntry: false,
      codeEntry: false,
      status: false,
      redirectTime: 5,
      userData: {},
      rootUrl: window.location.origin,
      // toggle: false,
    };
  },
  validations: {
    user: {
      firstName: { required },
      lastName: { required },
      userEmail: { required, email },
      password: {
        required,
        minLength: minLength(8), // I assume you'd want something like this too
        valid(value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return (
            containsUppercase
            && containsLowercase
            && containsNumber
            && containsSpecial
          );
        },
      },
      confirmPassword: { required, sameAsPassword: sameAs('password') },
    },
  },
  methods: {
    submitForm() {
      this.userValid = true;
      this.submitted = true;
      if (this.userValid) {
        this.$v.$touch();
        if (!this.$v.user.$invalid && this.userValid) {
          this.$store.commit('setLoadScreen', { status: true });
          const signDate = new Date();
          const payload = {
            givenname: this.user.firstName,
            lastname: this.user.lastName,
            email: this.user.userEmail,
            password: this.user.password,
            schoolid: this.schoolId,
            role: parseInt(this.roleId, 10),
            signUpDate: signDate,
            lastLogin: signDate,
          };
          console.log(payload);
          axios.post('/api/account/registerUser', payload)
            .then(() => {
              this.sendVerificationEmail();
            })
            .catch((error) => {
              alert(error);
              this.$store.commit('setLoadScreen', { status: false });
            });
        }
      }
    },
    sendVerificationEmail() {
      const encEmail = this.encoder64(this.user.userEmail);
      const session = {
        email: this.user.userEmail,
        name: this.user.firstName,
        school: this.schoolName,
      };
      session.verificationUrl = `${this.rootUrl}/verify/${encEmail}`;
      console.log(session);
      axios.post('/api/account/sendVerification', session)
        .then(() => {
          this.$store.commit('setLoadScreen', { status: false });
          this.status = true;
          setTimeout(() => {
            this.redirectTimer();
          }, 100);
        });
    },
    uploadFile(e) {
      [this.file] = [e.target.files];
    },
    loadUpload() {
      this.uploadModal = new Modal(document.getElementById('uploadModal', {}));
      this.uploadModal.show();
    },
    checkUser() {
      try {
        this.$v.user.userEmail.$touch();
        const payload = {
          email: this.user.userEmail,
        };
        axios.post('/api/checkUser', payload)
          .then((res) => {
            this.emailEntry = true;
            if (res.data.valid === 1) {
              this.userValid = true;
            } else {
              this.userValid = false;
            }
          });
      } catch (error) {
        console.error(error.response);
      }
    },
    getSchool() {
      const payload = {
        id: this.schoolId,
      };
      axios.post('/api/getSchoolInfo', payload)
        .then((res) => {
          this.schoolName = res.data.info.schoolName;
        });
    },
    getRole() {
      const payload = {
        id: this.roleId,
      };
      axios.post('/api/getRole', payload)
        .then((res) => {
          this.inputRole = res.data.role;
          console.log(this.inputRole);
        });
    },
    encoder64(param) {
      const encoded = btoa(param);
      return encoded;
    },
    decoder64(param) {
      const encoded = atob(param);
      return encoded;
    },
    loginRedirect() {
      clearInterval(this.interval);
      this.$router.push('/login');
    },
    redirectTimer() {
      this.interval = setInterval(() => {
        if (this.redirectTime > 0) {
          this.redirectTime -= 1;
        } else {
          this.loginRedirect();
        }
      }, 1000);
    },
  },
  mounted() {
    this.roleId = this.decoder64(this.$route.params.rid);
    this.schoolId = this.decoder64(this.$route.params.sid);
    this.schoolCode = this.decoder64(this.$route.params.cid);
    this.user.userEmail = this.decoder64(this.$route.params.email);
    this.getSchool();
    this.getRole();
    console.log(this.roleId, this.schoolId, this.schoolCode);
  },
};

</script>

<style scoped>
.error {
  color: red;
  font-size: 0.8em;
}

#email-field i {
  position: absolute;
  top: 270px;
  right: 30px;
  /*min-width: 40px;*/
}
.user-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("~@/assets/error_red_24dp.svg");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.user-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("~@/assets/check_circle_green_24dp.svg");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.code-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("~@/assets/error_red_24dp.svg");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.code-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("~@/assets/check_circle_green_24dp.svg");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

label {
  width: 100%;
}

.card-select {
  display: none;
}

.card-input {
  margin: 00px;
  /*height: 100%;*/
  height: 10em;
  width: 100%;
  border-radius: 2em;
}

.card-input:hover {
  cursor: pointer;
  box-shadow: .25rem .25rem rgba(20,1,1,0.075);
}

.card-select:checked + .card-input {
  background-color: #E97852;
  box-shadow: .125rem .25rem rgba(0,0,0,0.075);
  color: #FFF;
 }

.divider-register {
  position: relative;
  z-index: 0; }
  .divider-register svg {
    background: none !important;
    height: 6vw; }
    .divider-register svg:not([class*='bg']) path {
      background-color: #f3ece6; }
  .divider-register:not(:first-child) {
    top: 1px; }
  .divider-register:first-child {
    bottom: 1px; }
  .divider-register.divider-vertical {
    height: 100%;
    width: 25rem;
    top: 0;
    left: 1px; }
    .divider-register.divider-vertical svg {
      height: 100%;
      width: 25rem;
    }

.bg-image {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; }
  .bg-image:not([class*='absolute']) {
    position: absolute; }
  .bg-image + .card-body {
    position: relative;
    z-index: 0;
}

img.bg-image {
  width: 50%;
  object-fit: cover;
  vertical-align: middle;
    border-style: none; }

.pricing hr {
  margin: 1.5rem 0;
}

.pricing .card-title {
  margin: 0.5rem 0;
  font-size: 1.2rem;
  letter-spacing: .1rem;
  font-weight: bold;
  color: #87ceeb;
}

.pricing .card-price {
  font-size: 3rem;
  margin: 0;
}

.pricing .card-price .period {
  font-size: 0.8rem;
}

.pricing .material-icons {
  vertical-align: middle;
  margin-right: 1rem;
}

.pricing ul {
  list-style-type: none;
}

.pricing ul li {
  margin-bottom: 1rem;
}

.pricing .text-muted {
  opacity: 0.7;
}

.pricing .card {
  overflow: hidden;
}
.pricing .card:hover {
  box-shadow: .25rem .25rem 10px rgba(135,206,235,0.5), -.25rem -.25rem 10px rgba(135,206,235,0.55);
  cursor: pointer;
}

.pricing .card.selected {
  background-color: #36454f;
  color: white;
}

.pricing .card.selected .text-grey{
  color: white;
}

.pricing .card .text-grey {
  color: #6c757d;
}

.pricing .card.selected hr {
  border-color: white;
}

.corner-ribbon {
  width: 200px;
  background: #87ceeb;
  position: absolute;
  top: 25px;
  left: -50px;
  text-align: center;
  line-height: 40px;
  letter-spacing: 3px;
  color: #36454f;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left-accent-card {
  box-shadow: .125rem .25rem rgba(0,0,0,0.075);
  border-left: 0.5rem solid #bb4b25;
  margin-left: -0.5rem;
}

.register-col {
  z-index: 1;
  position: relative;
  object-fit: cover;
  background-color: #f3ece6;
  min-height: 100vh;
  background-size: auto;
  background-repeat: repeat;
}

.register-card {
  max-width: 50%;
}

.bg-rust-gradient {
  /*background: rgb(187,75,37);*/
  background: linear-gradient(159deg, rgba(187,75,37,1) 0%, rgba(233,120,82,1) 50%);
}

.social-button img {
  width: 100%;
  background-color: #f3ece6;
}

.social-button {
  border: 0;
}
</style>

<template>
  <div>
    <div class="col-md-7 col-lg-8 mx-auto py-4">
      <div class="card shadow my-5">
        <div class="card-body">
          <h3 class="card-title text-pri mb-2">User List</h3>
          <table id="usersTable" class="display table table-hover">
            <thead class="bg-dark text-white">
              <tr>
                <th scope="col"> ID </th>
                <th scope="col"> Given Name </th>
                <th scope="col"> Last Name </th>
                <th scope="col"> Email </th>
                <th scope="col"> Institution </th>
                <th scope="col"> Verified </th>
                <th scope="col"> Role </th>
                <!-- <th scope="col"> Last Login </th> -->
                <!-- <th class="text-center">Actions</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="user, ind in userData" :key="ind"
              @click="loadUser(user)" class="clickable">
                <td>{{user.id}}</td>
                <td>{{user.givenName}}</td>
                <td>{{user.lastName}}</td>
                <td>{{user.email}}</td>
                <td>{{user.school}}</td>
                <td class="text-center">{{user.verified}}</td>
                <td class="text-center">{{user.role}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Modal } from 'bootstrap';
import {
  required, email,
} from 'vuelidate/lib/validators';

export default {
  name: 'AdminUser',
  components: {
  },
  validations: {
    edit: {
      givenName: { required },
      lastName: { required },
      email: {
        required,
        email,
      },
    },
  },
  computed: {
  },
  data() {
    return {
      userData: [],
      userFocus: null,
      submitted: false,
      invalid: false,
      fId: '',
      editModal: null,
      editFocus: {},
      edit: {
        givenName: null,
        lastName: null,
        email: null,
        verified: null,
      },
    };
  },
  methods: {
    getUsers() {
      this.$store.commit('setLoadScreen', { status: false });
      axios.get('/api/admin/users', {
      })
        .then((response) => {
          this.userData = response.data.users;
          console.log(this.userData);
          setTimeout(() => {
            this.table = $('#usersTable').DataTable({
              // scrollY: '23rem',
              scrollCollapse: true,
              fixedHeader: true,
              autoWidth: false,
              info: false,
              searching: true,
              paging: true,
              retrieve: true,
              ordering: true,
            });
          }, 10);
        });
      // .catch((error) => {
      //   if (error.response.status === 403) {
      //     this.$router.replace({ path: '/forbidden/' });
      //   }
      // });
    },
    loadUser(user) {
      this.$router.push(`users/${user.id}/${user.role}/info`);
    },
    loadUserDetails(user) {
      this.editModal = new Modal(document.getElementById('detailsModal', {}));
      this.editFocus = user;
      console.log(user);
      [this.fId, this.edit.givenName, this.edit.lastName, this.edit.email, ,
        this.edit.verified] = user;
      this.editModal.show();
    },
    updateDetails() {
      this.submitted = true;
      if (!this.$v.$invalid) {
        this.$store.commit('setLoadScreen', { status: true });
        const payload = {
          id: this.fId,
          givenname: this.edit.givenName,
          lastname: this.edit.lastName,
          email: this.edit.email,
          verified: this.edit.verified,
        };
        axios.post('/api/admin/updateUsers', payload)
          .then(() => {
            this.submitted = false;
            this.editModal.hide();
            this.getUsers();
            this.$store.commit('setLoadScreen', { status: false });
          }).catch((err) => {
            console.error(err);
            this.getUserData();
            this.$store.commit('setLoadScreen', { status: false });
          });
      }
    },
    loadDelete(ind) {
      this.deleteModal = new Modal(document.getElementById('deleteAssModal', {}));
      this.userFocus = ind;
      console.log(this.userFocus);
      this.deleteModal.show();
    },
    deleteUser() {
      axios.post('/api/deleteUser', {
        // eslint-disable-next-line no-underscore-dangle
        fId: this.userFocus.fId,
      }).then(() => {
        this.getUsers();
        this.deleteModal.hide();
      }).catch((err) => {
        throw err;
      });
    },
  },
  mounted() {
    this.getUsers();
  },
};
</script>

<style>
div.dataTables_filter input{
  border:  0;
  border-bottom: 5px #bb4b25;
  background-color: #dcf3f9;
}

div.dataTables_filter input:focus{
  background-color: #F5D6CC;
  outline:  none;
}

</style>

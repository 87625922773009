export default {
  prettyDate(date) {
    const tempDate = new Date(date);
    console.log(date);
    const month = this.checkDigit(tempDate.getMonth() + 1);
    const day = this.checkDigit(tempDate.getDate());
    const year = this.checkDigit(tempDate.getFullYear());
    const hour = this.checkDigit(tempDate.getHours());
    const minute = this.checkDigit(tempDate.getMinutes());

    return `${year}/${month}/${day} ${hour}:${minute}`;
  },
  checkDigit(val) {
    if (String(val).length < 2) { return `0${val}`; }
    return val;
  },
};

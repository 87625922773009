<template>
  <div>
    <div class="col-md-10 col-lg-10 mx-sm-auto pt-5">
      <div v-if="userData.schoolId == 1">
        <h5 class="text-muted text-center mt-5">
          Looks like you are not part of any institution. Find your school and join below.
        </h5>
        <JoinSchool ></JoinSchool>
      </div>
      <div class="row" v-else>
        <div class="col-3 d-flex flex-column justify-content-between">
          <div class="card left-accent-card" style="height: 41vh">
            <div class="card-body" style="overflow-y: scroll">
              <h4 class="card-title">Ongoing</h4>
              <ul class="list-group list-group-flush">
                <li class="list-group-item" v-for="item, index in ongoingTasks" :key="index">
                  <div>
                    <span><strong>{{ item.title }}</strong></span><br>
                    <span class="text-muted">{{ item.course }}</span><br>
                    <span v-if="item.remaining > 1">
                      <em>Closes in {{ item.remaining }} days</em>
                    </span>
                    <span class="text-danger" v-else-if="item.remaining == 1">
                      <em>Closes tomorrow</em>
                    </span>
                    <span class="text-danger" v-else><em>Closes today</em></span>
                    <br>
                    <span>
                      {{ item.submits }} out of {{item.students}} students submitted
                    </span>
                  </div>
                </li>
              </ul>
              <p class="card-text" v-if="ongoingTasks.length == 0"></p>
              <em>No ongoing assignments</em>
            </div>
          </div>
          <div class="card left-accent-card" style="height: 41vh">
            <div class="card-body" style="overflow-y: scroll">
              <h4 class="card-title">Ungraded</h4>
              <ul class="list-group list-group-flush">
                <li class="list-group-item" v-for="item, index in ungraded" :key="index">
                  <div>
                    <button class="btn float-end" v-on:click="gradeAssessment(item.assid)">
                      <i class="material-icons" data-toggle="tooltip" title="Grade">grading</i>
                    </button>
                    <span><strong>{{ item.title }}</strong></span><br>
                    <span class="text-muted"> {{item.course}} </span><br>
                    <span v-if="item.closed > 1">
                      <em>Closed {{item.closed}} days ago</em>
                    </span>
                    <span v-else-if="item.closed == 1"><em>Closed 1 day ago</em></span>
                    <span v-else><em>Closed recently</em></span>
                  </div>
                </li>
              </ul>
              <p class="card-text" v-if="ungraded.length == 0">
              <em>No ungraded assignments</em>
              </p>
            </div>
          </div>
        </div>
        <div class="card card-shadow col-9">
          <div class="card-body">
            <div id="calendar-container" class="shadow p-4 mx-auto">
              <FullCalendar ref="calendar" :options="calendarOption" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

// import $ from 'jquery';
import { Popover } from 'bootstrap';
import axios from 'axios';
import FullCalendar from '@fullcalendar/vue';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import JoinSchool from './JoinSchool.vue';

export default {
  name: 'TeacherHome',
  components: {
    FullCalendar,
    JoinSchool,
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
  },
  data() {
    return {
      ongoingTasks: [],
      ungraded: [],
      calendarOption: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        headerToolbar: {
          left: 'prev,next today addEventButton',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        navLinks: true,
        dayMaxEventRows: true,
        moreLinkClick: 'day',
        scrollTime: '08:00:00',
        nowIndicator: true,
        initialView: 'dayGridMonth',
        // height: '80vh',
        // contentHeight: 'auto',
        events: this.fetchEvents,
        eventDidMount(arg) {
          // eslint-disable-next-line no-new
          new Popover(arg.el, {
            title: arg.event.title,
            content: arg.event.extendedProps.course,
            trigger: 'hover',
            placement: 'top',
          });
        },
        // eventClick: this.eventClick,
      },
    };
  },
  methods: {
    gradeAssessment(key) {
      const pathvar = `/tasks/${key}/grade`;
      this.$router.push({ path: pathvar });
    },
    fetchEvents(fetchInfo, callback) {
      axios.get('/api/dashboard/events', {
        params: {
          start: fetchInfo.start,
          end: fetchInfo.end,
          user: this.userData.id,
          role: this.userData.roleId,
        },
      })
        .then((response) => {
          callback(response.data);
        });
    },
  },
  mounted() {
    // axios.get('/api/dashboard')
    //   .then((response) => {
    //     this.ongoingTasks = response.data.ongoing;
    //     this.ungraded = response.data.ungraded;
    //   });
  },
};
</script>
<style scoped>
</style>

<template>
  <div class="row mx-0 p-5 justify-content-center bg-overlay">
    <div class="col-lg-6 col-md-7 col-sm-9 col-xs-8 align-self-center">
      <div style = "border-radius:1em;" class="card p-2 shadow border-0">
        <div v-if="success===false" class="col-lg-10 mx-auto my-3 align-self-center text-center">
          <h2 class="text">Update your password</h2>
          <form @submit="submitUpdate">
            <div class="text-start text-secondary my-4 row input-container">
              <label class="form-label p-0 fs-5">Enter new password</label>
              <input type="password" name="newPassword" placeholder="New Password"
              class="input-field fs-6" v-model="newPassword"
              :class="{ 'is-invalid': resetSubmitted && $v.newPassword.$error }"/>
              <span class="input-border"></span>
<!--               <div class="form-text">
                Your password must be 8 characters long
              </div> -->
              <div v-if="resetSubmitted && !$v.newPassword.required" class="form-text error">
              Password is required</div>
              <div v-if="resetSubmitted && !$v.newPassword.minLength" class="form-text error">
              Password must be at least 8 characters long</div>
              <div v-if="resetSubmitted && !$v.newPassword.valid && newPassword"
              class="form-text error">
              Password must contain Uppercase and lowercase characters, numbers and symbols</div>
            </div>
            <div class="text-start text-secondary my-4 row input-container">
              <label class="form-label p-0 fs-5" >Confirm new password</label>
              <input type="password" name="confirmpassword" placeholder="Confirm"
              class="input-field fs-6" v-model="confirmPassword"
              :class="{ 'is-invalid': resetSubmitted && $v.confirmPassword.$error }"/>
              <span class="input-border"></span>
              <div v-if="resetSubmitted && !$v.confirmPassword.required"
              class="error form-text ">Confirm Password is required</div>
              <div v-if="resetSubmitted && !$v.confirmPassword.sameAsPassword"
              class="error form-text ">Passwords do not match</div>
            </div>
            <div class="col bg-gradient-primary text-white">
              <button type="submit"
              class=" float-end btn button-color btn text-center" @click="submitUpdate">
                Confirm Update
              </button>
            </div>
          </form>
        </div>
        <div v-else class="row mx-0 p-5">
          <div class="col-lg-6 mx-auto align-self-center my-auto text-center">
            <p>
            Your password has been updated, please re-login.
            </p>
            <div class="bg-gradient-primary text-white">
              <button type="submit"
              class=" float-center btn button-color btn text-center"
              @click="$router.push('/login')">
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {
  required, minLength, sameAs,
} from 'vuelidate/lib/validators';
// import firebase from 'firebase';

export default {
  name: 'UpdatePassword',
  components: {
  },
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
      resetSubmitted: false,
      invalid: false,
      success: false,
    };
  },
  computed: {
    loadScreen() {
      return this.$store.state.loadScreen;
    },
  },
  validations: {
    newPassword: {
      required,
      minLength: minLength(8), // I assume you'd want something like this too
      // valid(value) {
      //   const containsUppercase = /[A-Z]/.test(value);
      //   const containsLowercase = /[a-z]/.test(value);
      //   const containsNumber = /[0-9]/.test(value);
      //   const containsSpecial = /[#?!@$%^&*-]/.test(value);
      //   return (
      //     containsUppercase
      //     && containsLowercase
      //     && containsNumber
      //     && containsSpecial
      //   );
      // },
    },
    confirmPassword: { required, sameAsPassword: sameAs('newPassword') },
  },
  methods: {
    // submitEmail(event) {
    //   event.preventDefault();
    //   this.emailSubmitted = true;
    //   this.$v.$touch();
    //   if (!this.$v.$invalid) {
    //     this.loadScreen = true;
    //     const auth = firebase.auth();
    //     auth.sendPasswordResetEmail(this.email)
    //       .then(() => {
    //       }).catch(() => {
    //       });
    //   }
    // },
    submitUpdate(event) {
      event.preventDefault();
      const { userData } = this.$store.state;
      console.log(userData);
      this.id = userData.id;
      this.email = userData.email;
      this.resetSubmitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.commit('setLoadScreen', { status: true });
        const payload = {
          email: this.email,
          password: this.newPassword,
        };
        axios.post('/api/updatePassword', payload)
          .then(() => {
            this.success = true;
            this.$store.commit('setLoadScreen', { status: false });
          })
          .catch(() => {
            this.invalid = true;
            this.$store.commit('setLoadScreen', { status: false });
          });
      }
      this.$store.commit('setLoadScreen', { status: false });
    },
  },
};
</script>

<style scoped>
.error {
  color: red;
  font-size: 0.8em;
}

.button-color{
  background-color: #BB4B25 !important;
  color: #FFF !important;
}
</style>

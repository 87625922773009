<template>
  <div>
    <small class="qn-label" v-show="!(preview || ansKey)"> Rich Text </small>
    <div :class="{'active-component': !(preview || ansKey)}">
      <ckeditor :editor="editor" v-model="component.content"
        :config="editorConfig" :readonly="preview || ansKey" :disabled="preview || ansKey">
      </ckeditor>
    </div>
  </div>
</template>

<script>
import InlineEditor from '../ckeditor';

export default {
  name: 'Equation',
  props: ['component', 'ansKey', 'preview'],
  computed: {
  },
  data() {
    return {
      editor: InlineEditor,
      placeholder: 'Add text/image here.',
      editorConfig: {
        isReadOnly: 'true',
        image: {
          // Configure the available styles.
          styles: [
            'full', 'alignLeft', 'alignCenter', 'alignRight',
          ],

          // You need to configure the image toolbar, too, so it shows the new style
          // buttons as well as the resize buttons.
          toolbar: [
            'imageStyle:full',
            'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
            '|',
            'imageTextAlternative',
          ],
        },
        // The configuration of the editor.
      },
    };
  },
  methods: {
  },
  mounted() {
  },
};
</script>

<style scoped>
.active-component {
  background-color: rgba(235, 247, 255, 0.3);
}
</style>

<template>
  <section class="row g-0 min-vh-100 p-0">
    <div class="col-lg-4 justify-content-end d-flex bg-marigold-gradient">
      <img src="~@/assets/quill_fall.png" class="bg-image">
      <div class="divider-login divider-vertical d-none d-lg-block">
        <svg width='100%' height="100%" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none">
          <path d="M50,0 L100,0 L100,100 L50,100 C83.333333,83.3333333 100,66.6666667
          100,50 C100,33.333333 83.33333333,16.6666667 50,0 Z" fill="#FDF7ED">
          </path>
        </svg>
      </div>
    </div>
    <div class="col d-flex align-self-center register-col p-5">
      <div v-if="status===false" class="container my-auto register-card text-start">
        <h1 class="mb-4">Your email has not been verified.</h1>
        <h5 class="mb-4">
          Please check your inbox for our verification email.
          Don't forget to check your spam folder!
        </h5>
        <button type="button" @click="sendVerificationEmail"
        class="btn bg-pri text-center mb-4">
          Resend Email
        </button><br>
        <button type="button" @click="$router.push('/login')"
        class="btn btn-link text-start">
          Back to login
        </button>
      </div>
      <div v-else class="container my-auto register-card text-start">
        <h1>A new verification email has been sent.</h1>
        <h5 class="mb-4">
          Follow the instructions in the email to verify your email.
        </h5>
        <button type="button" @click="loginRedirect"
        class="btn bg-pri text-center">Back to login
        </button>
      </div>
    </div>
  </section>
</template>

<script>

// import firebase from 'firebase';
import axios from 'axios';

export default {
  name: 'EmailUnverified',
  components: {
  },
  data() {
    return {
      code: null,
      verified: false,
      email: null,
      status: false,
      rootUrl: window.location.origin,
    };
  },
  computed: {
    loadScreen() {
      return this.$store.state.loadScreen;
    },
  },
  methods: {
    sendVerificationEmail() {
      const userData = this.$store.state;
      console.log(userData.userData.email);
      const encEmail = this.encoder64(userData.userData.email);
      const session = {
        email: userData.userData.email,
        name: userData.userData.givenName,
      };
      session.verificationUrl = `${this.rootUrl}/verify/${encEmail}`;
      axios.post('/api/account/sendVerification', session)
        .then(() => {
          this.$store.commit('setLoadScreen', { status: false });
          this.status = true;
        });
    },
    encoder64(param) {
      const encoded = btoa(param);
      return encoded;
    },
    loginRedirect() {
      this.$router.push('/login');
    },
  },
  mounted() {
  },
};
</script>

<style scoped>
.divider-login {
  position: relative;
  z-index: 0; }
  .divider-login svg {
    background: none !important;
    height: 6vw; }
    .divider-login svg:not([class*='bg']) path {
      background-color: #f3ece6; }
  .divider-login:not(:first-child) {
    top: 1px; }
  .divider-login:first-child {
    bottom: 1px; }
  .divider-login.divider-vertical {
    height: 100vh;
    width: 25rem;
    top: 0;
    left: 1px; }
    .divider-login.divider-vertical svg {
      height: 100vh;
      width: 25rem; }

.bg-image {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; }
  .bg-image:not([class*='absolute']) {
    position: absolute; }
  .bg-image + .card-body {
    position: relative;
    z-index: 0; }

img.bg-image {
  width: 50%;
  object-fit: cover;
  vertical-align: middle;
    border-style: none; }

.register-col {
  z-index: 1;
  position: relative;
  background-color: #FDF7ED;
  height: 100vh;
}

.register-card {
  max-width: 50%;
}

.bg-marigold-gradient {
  /*background: rgb(187,75,37);*/
  background: linear-gradient(140deg, rgba(0,103,165,1) 0%, rgba(0,33,71,1) 70%);
}
</style>

import Vue from 'vue';
import VueRouter from 'vue-router';
import Register from '../views/Register.vue';
import InvitePage from '../views/InvitePage.vue';
import NewSchool from '../components/NewSchool.vue';
import ManageAccounts from '../components/ManageAccounts.vue';
import Login from '../views/Login.vue';
import UserMgmt from '../views/UserMgmt.vue';
import Account from '../views/Account.vue';
import CoursesHome from '../components/CoursesHome.vue';
import Course from '../views/Course.vue';
// import AnalyticsDashboard from '../views/AnalyticsDashboard.vue';
import NewCourse from '../components/NewCourse.vue';
import CoursePage from '../components/CoursePage.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import UpdatePassword from '../views/UpdatePassword.vue';
import EmailUnverified from '../views/EmailUnverified.vue';
// import PasswordReset2 from '../views/PasswordReset2.vue';
import CreateAss from '../views/CreateAss.vue';
import GradeAss from '../views/GradeAss.vue';
import ReviewAss from '../views/ReviewAss.vue';
import ProctorView from '../views/ProctorView.vue';
import VerifyEmail from '../components/VerifyEmail.vue';
import AdminInstHome from '../components/AdminInstHome.vue';
import AdminInst from '../components/AdminInst.vue';
import AdminInstAcc from '../components/AdminInstAcc.vue';
import AdminInstUsers from '../components/AdminInstUsers.vue';
import PasswordReset from '../components/PasswordReset.vue';
import TakeAss from '../components/TakeAss.vue';
import AdminUser from '../components/AdminUser.vue';
import AdminUserHome from '../components/AdminUserHome.vue';
import AdminUserAcc from '../components/AdminUserAcc.vue';
import AdminUserCourse from '../components/AdminUserCourse.vue';
// import ScatterHeatChart from '../components/ScatterHeatChart.vue';
import store from '../store/index';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
      requiresVer: true,
    },
  },
  {
    path: '/courses/',
    component: Course,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        name: 'CoursesHome',
        component: CoursesHome,
      },
      {
        path: 'create',
        name: 'CreateClass',
        component: () => import('../views/CreateCourse.vue'),
      },
      {
        path: ':cid/',
        component: CoursePage,
        children: [
          {
            path: '/',
            redirect: 'dashboard',
          },
          {
            path: 'dashboard',
            name: 'CourseDashboard',
            component: () => import('../components/CourseDashboard.vue'),
          },
          {
            path: 'announcements',
            name: 'CourseAnnouncements',
            component: () => import('../components/CourseAnnouncements.vue'),
          },
          {
            path: 'modules',
            name: 'CourseModulesHome',
            component: () => import('../components/CourseModulesHome.vue'),
          },
          {
            path: 'sessions',
            name: 'CourseSessionsHome',
            component: () => import('../components/CourseSessionsHome.vue'),
          },
          {
            path: 'module/:seq',
            name: 'CourseModuleDetails',
            component: () => import('../components/CourseModuleDetails.vue'),
          },
          {
            path: 'tasks',
            name: 'CourseTasks',
            component: () => import('../components/CourseTasks.vue'),
          },
          {
            path: 'grades',
            name: 'CourseGrades',
            component: () => import('../components/CourseGrades.vue'),
          },
          {
            path: 'info',
            name: 'CourseInfo',
            component: () => import('../components/CourseInfo.vue'),
          },
          {
            path: 'edit',
            name: 'EditCourse',
            component: () => import('../views/CreateCourse.vue'),
          },
        ],
      },
      {
        path: 'new',
        name: 'NewCourse',
        component: NewCourse,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/edittask/:cid',
    name: 'CreateAss',
    component: CreateAss,
    meta: {
      requiresAuth: true,
      // requiresVer: true,
    },
  },
  {
    path: '/monitortask/:cid',
    name: 'ProctorView',
    component: ProctorView,
    meta: {
      requiresAuth: true,
      // requiresVer: true,
    },
  },
  {
    path: '/submittask/:cid',
    name: 'TakeAss',
    component: TakeAss,
    meta: {
      requiresAuth: true,
      // requiresVer: true,
    },
  },
  {
    path: '/gradetask/:cid',
    name: 'GradeAss',
    component: GradeAss,
    meta: {
      requiresAuth: true,
      // requiresVer: true,
    },
  },
  {
    path: '/reviewtask/:cid',
    name: 'ReviewAss',
    component: ReviewAss,
    meta: {
      requiresAuth: true,
      // requiresVer: true,
    },
  },
  {
    path: '/manage_accounts',
    name: 'ManageAccounts',
    component: ManageAccounts,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/verify/:eid',
    name: 'verfication',
    component: VerifyEmail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/reset/:eid',
    name: 'passwordreset',
    component: PasswordReset,
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      requiresAuth: true,
      requiresVer: true,
    },
  },
  {
    path: '/register/',
    component: Register,
    children: [
      {
        path: '',
        name: 'createAccount',
        component: () => import('../components/CreateAccount.vue'),
      },
      {
        path: 'new',
        name: 'newSchool',
        component: NewSchool,
      },
      {
        path: 'invite/:rid/:sid/:cid/:email',
        name: 'invitePage',
        component: InvitePage,
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/updatepassword',
    name: 'UpdatePassword',
    component: UpdatePassword,
    meta: {
      requiresAuth: true,
      requiresVer: true,
    },
  },
  {
    path: '/emailunverified',
    name: 'EmailUnverified',
    component: EmailUnverified,
  },
  {
    path: '/usermgmt',
    name: 'UserMgmt',
    component: UserMgmt,
  },
  {
    path: '/users/',
    component: AdminUserHome,
    children: [
      {
        path: '',
        name: 'AdminUser',
        component: AdminUser,
      },
      {
        path: ':uid/:rid/info',
        name: 'AdminUserAcc',
        component: AdminUserAcc,
      },
      {
        path: ':uid/:rid/course',
        name: 'AdminUserCourse',
        component: AdminUserCourse,
      },
    ],
  },
  {
    path: '/institutes/',
    component: AdminInstHome,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'AdminInst',
        component: AdminInst,
      },
      {
        path: ':iid//info',
        name: 'AdminInstAcc',
        component: AdminInstAcc,
      },
      {
        path: ':iid/users',
        name: 'AdminInstUsers',
        component: AdminInstUsers,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
// To add token and email verfication

router.beforeEach((to, from, next) => {
  store.commit('setError', { status: false });
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const requiresAdmin = to.matched.some((x) => x.meta.requiresAdmin);
  const requiresVer = to.matched.some((x) => x.meta.requiresVer);
  if (requiresAuth) {
    if (!store.state.loggedIn) {
      next({ path: '/login', query: { redirect: to.fullPath } });
    } else if (!store.state.userData.verified && requiresVer) {
      next({ path: '/emailunverified' });
    } else if (requiresAdmin) {
      if (store.state.userData.roleId === 4) {
        next();
      } else {
        next({ path: '/forbidden' });
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

<template>
  <div id="app">
    <loading :active.sync="loadScreen"
    :is-full-page="true"></loading>
    <router-view/>
    <div class="position-fixed bottom-0 start-0 p-3" style="z-index: 5">
      <div class="error-toast align-items-center text-white bg-danger border-0" role="alert"
      aria-live="assertive" aria-atomic="true" id="errorToast" v-show="error">
        <div class="d-flex">
          <div class="toast-body" v-show="!errorMessage">
            Oops! Something went wrong. <br />
            Please try again later or
            <a
            href="https://waffle-school.atlassian.net/servicedesk/customer/portals"
            target="_blank">
             contact support
            </a>.
          </div>
          <div class="toast-body" v-show="errorMessage">
           {{errorMessage}}
          </div>
          <button type="button" class="btn-close btn-close-white me-2 m-auto"
          @click="dismissError()" aria-label="Close"></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  // data() {
  //   return {
  //     error: false,
  //   };
  // },\
  components: {
    Loading,
  },
  computed: {
    error() {
      return this.$store.state.error;
    },
    errorMessage() {
      return this.$store.state.errorMessage;
    },
    loadScreen() {
      return this.$store.state.loadScreen;
    },
  },
  methods: {
    dismissError() {
      this.$store.commit('setError', { status: false });
    },
  },
  mounted() {
    // this.errToast = new Toast(document.getElementById('errorToast'));
    this.$store.commit('setError', { status: false });
  },
  errorCaptured(err) {
    // this.errToast.show();
    this.$store.commit('setLoadScreen', { status: false });
    this.$store.commit('setError', { status: true });
    console.log(err);
  },
};
</script>

<style>
@import '~material-icons/iconfont/material-icons.css';
@import '~mathlive/dist/mathlive-fonts.css';
:root {
  --primary-color: #0067A5;
  --accent-2:  #3d3d3d;
  --accent-1:  #ecab32;
  --accent-1-tint-1:  #FCF0D9;
  --accent-1-tint-2: #FAE8C6;
  --dark-color: #002147;
  --dark-color-tint: #00397A;
}

.text-pri {
  color:  var(--primary-color);
}

.text-accent-1 {
  color:  var(--accent-1);
}

#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;

}
.navbar-margin {
  margin-left: 4rem;
}

.bg-primary {
  background-color: var(--primary-color)!important;
 }
.bg-accent-1 {
  background-color: var(--accent-1)!important;
}
.bg-accent-1-tint1 {
  background-color: var(--accent-1-tint-1)!important;
}
.bg-light {
  background-color: #F2F8FF !important;
}
.bg-gray {
  background-color: #D3D3D3 !important;
}
.background-dark {
  background-color: var(--dark-color)!important;
}

.accent-blue {
  background-color: #87ceeb;
}

.accent-rust {
  background-color: #bb4b25;
}

.text-rust {
  color: #bb4b25;
}

.text-blue {
  color: #87ceeb;
}

.text-dark {
  color: #36454f!important;
}

.bg-overlay {
  background-image: url(https://waffle.school/wp-content/uploads/2021/05/CTA-Shapes.png);
  background-position: bottom right;
  background-repeat: no-repeat;
  opacity: 1;
  transition: background .3s,border-radius .3s,opacity .3s;
  min-height: 100vh;
}

.card-shadow {
  box-shadow: .125rem .25rem rgba(0,0,0,0.075);
}

.hover-pointer:hover {
  cursor: pointer;
}

.md-24 {
  font-size: 24px !important;
  vertical-align: middle;

}
.text-pri {
  color:  var(--primary-color) !important;
}
.text-acc1{
  color:  var(--accent-1) !important;
}
.text-acc2{
  color:  var(--accent-2) !important;
}
.text-sec{
  color:  var(--dark-color) !important;
}
.bg-pri {
  background-color: var(--primary-color) !important;
  color: #FFF !important;
}

.bg-sec {
  background-color: #FFE1C2 !important;
  color: #BB4B25 !important;
}

.bg-ter {
  background-color: #FF6531 !important;
  color: #FFF !important;
}

.bg-acc1 {
  background-color: #6EC1E4 !important;
  color: #FFF !important;
}

.bg-acc2 {
  background-color: #54595F !important;
  color: #FFF !important;
}
.icon-btn {
  display: inline-block;
  vertical-align: middle;
  user-select:  none;
  background-color: transparent;
  height: 100%;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-disabled {
  background-color: #d2d2d2 !important;
}
.btn-line-dark {
    border-color: var(--dark-color);
    color:  var(--dark-color) !important;
}
.btn-line-dark:hover {
  background-color: var(--dark-color);
  color:  var(--accent-1) !important;
}
.btn-line-danger {
  border-color: var(--bs-red);
  color:  var(--bs-red) !important;
}
.btn-line-danger:hover {
  background-color: var(--bs-red);
  color:  #fff !important;
}
.input-container{
  /*display: inline-block;*/
  text-align: center;
}
.input-field {
  width: 100%;
  min-height: 1em;
  border: none;
  /*outline: none;*/
  background-color: rgba(0,103,165,0.1);
  border-radius: 3em;
  padding: 0.1em 1em;
}
.input-border {
  margin-left: auto;
  margin-right: auto;
  display:inline-block;
  width: 0px;
  height: 0px;
  background: #ECAB32;
  position: relative;
  top:-2px;
  -webkit-transition: all ease-in-out .15s;
  -o-transition: all ease-in-out .15s;
  transition: all ease-in-out .15s;
}
.input-field:focus {
  outline:  none;
  background-color: rgba(236,171,50,.15);
}

.input-field:focus ~ .input-border {
  height:  2px;
  width: calc(100% - 2em);
}

.error-toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  border-radius: 0.25rem;
}

.material-icons.md-32 {
  font-size: 32px;
}

.material-icons.md-40 {
  font-size: 40px;
}

.material-icons.md-12 {
  font-size: 12px;
}

.material-icons.md-16 {
  font-size: 16px;
}
.pointer {
  cursor: pointer;
}
.handle {
  cursor: move;
}
.info-container {
  max-width: 1400px;
}
</style>

<template>
  <div>
    <VerifyEmail v-if="this.mode==='verifyEmail'"></VerifyEmail>
    <PasswordReset v-else-if="this.mode==='resetPassword'"></PasswordReset>
    <div v-else>
      Not Found
    </div>
  </div>
</template>
<script>

import VerifyEmail from '../components/VerifyEmail.vue';
import PasswordReset from '../components/PasswordReset.vue';

export default {
  name: 'UserMgmt',
  components: {
    PasswordReset,
    VerifyEmail,
  },
  data() {
    return {
      mode: null,
      code: null,
    };
  },
  mounted() {
    this.mode = this.$route.query.mode;
    console.log(this.mode);
  },
};
</script>
<style scoped>
</style>

<template>
  <div class="bg-overlay">
    <div class="col-md-7 col-lg-8 mx-auto py-4">
      <div class="modal fade" id="confirmEdit" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="confirmEditModalLabel">
              Confirm Changes</h5>
              <button type="button" class="btn-close"
                data-bs-dismiss="modal" aria-label="Close">
                </button>
            </div>
            <div class="modal-body">
              Do you wish to proceed?
            </div>
            <div class="modal-footer">
              <button type="button" class="btn bg-acc2"
              data-bs-dismiss="modal">Cancel</button>
              <button type="button" class="btn bg-ter"
              v-on:click="updateInstAcc()">Confirm</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="deleteInstModal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteInstModalLabel">
              This action cannot be undone</h5>
              <button type="button" class="btn-close"
                data-bs-dismiss="modal" aria-label="Close">
                </button>
            </div>
            <div class="modal-body">
              Do you wish to proceed?
            </div>
            <div class="modal-footer">
              <button type="button" class="btn bg-acc2"
              data-bs-dismiss="modal">Cancel</button>
              <button type="button" class="btn bg-ter"
              v-on:click="deleteInst()">Confirm</button>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <div type="button" class="btn bg-dark text-white
        rounded-pill shadow ms-2"
        @click="changeTab('info')">
          Account
        </div>
        <div type="button" class="btn bg-light text-blue
        rounded-pill shadow ms-2"
        @click="changeTab('users')">
          Users
        </div>
        <!-- <label>
          <input type="radio" id="members" :value="2" class="tab-radio"
            v-model="activeTab">
          <div type="button" class="btn background-dark text-dark
          rounded-pill shadow ms-2">
            Analytics
          </div>
        </label> -->
      </div>
      <div class="card card-shadow pt-2 my-4">
        <div class="card-body">
          <h3 class="card-title d-inline mb-2 ms-3 text-pri">Account Information</h3>
          <div class="text-white d-inline">
            <button v-show="edit==false" type="button"
            class="float-end btn btn-danger text-center mx-2" @click="loadDelete">
            <span class="material-icons-outlined align-middle"
               data-bs-toggle="tooltip" title="Delete">delete</span>
              Delete
            </button>
            <button v-show="edit==false" type="button"
            class="float-end btn bg-pri text-center mx-2" @click="editInfo">
            <span class="material-icons-outlined align-middle"
               data-bs-toggle="tooltip" title="Edit">edit</span>
              Edit
            </button>
          </div>
          <form class="container">
            <div class="form-group d-flex fs-4 text-start mt-4 row px-3 input-container">
              <label class="fs-6 text-accent-1">School Name</label>
              <input type="text" name="name" :disabled="edit == false"
              class="input-field fs-5" v-model="name"
              :class="{ 'edit-mode': edit,
              'is-invalid': submitted && $v.name.$error }"/>
              <span class="input-border"></span>
              <div v-if="submitted && !$v.name.required"
              class="form-text error">School Name is required</div>
            </div>
            <div class="form-group d-flex fs-4 text-start mt-3 row px-3 input-container">
              <label class="fs-6 text-accent-1">Subscription</label>
              <input type="text" name="tier"
              class="input-field fs-5" v-model="subscription" disabled/>
              <span class="input-border"></span>
            </div>
            <div class="form-group d-flex fs-4 text-start mt-3 row px-3 input-container">
              <label class="fs-6 text-accent-1">Expire Date</label>
              <input type="text" name="tier"
              class="input-field fs-5" v-model="expireDate" :disabled="edit == false"
              :class=" { 'edit-mode': edit }">
              <span class="input-border"></span>
            </div>
            <div class="form-group d-flex fs-4 text-start mt-3 row px-3 input-container">
              <label class="fs-6 text-accent-1">Stripe ID</label>
              <input type="text" name="tier"
              class="input-field fs-5" v-model="stripeId" disabled>
              <span class="input-border"></span>
            </div>
            <div class="form-group d-flex fs-4 text-start mt-3 row px-3 input-container">
              <label class="fs-6 text-accent-1">Teacher Code</label>
              <input type="text" name="name" disabled
              class="input-field fs-5" v-model="teacherCode">
            </div>
            <div class="form-group d-flex fs-4 text-start mt-3 row px-3 input-container">
              <label class="fs-6 text-accent-1">Student Code</label>
              <input type="text" name="studentCode" disabled
              class="input-field fs-5" v-model="studentCode">
            </div>
            <div class="form-group fs-4 text-start mt-4 mx-2">
              <div class="col-auto">
                <button type="button" class="btn mt-0 btn bg-acc1"
                @click="refreshCode">
                  Reset Codes
                </button>
              </div>
            </div>
            <div v-show="edit===true"
            class="col mx-2  mt-4 bg-gradient-primary text-white">
              <button type="button"
              class=" mx-2 float-end btn bg-pri text-center" @click="submitEdit">
                Update
              </button>
            </div>
            <div v-show="edit===true"
            class="col mx-2 mt-4 bg-gradient-primary text-white">
              <button type="button"
              class=" mx-2 float-end btn bg-ter text-center" @click="cancelEdit">
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
// import firebase from 'firebase';
import { Modal } from 'bootstrap';
import {
  required,
} from 'vuelidate/lib/validators';

const stubTitleMap = {
  info: 'Account Info',
  users: 'Users',
  // analytics: 'Analytics',
};

export default {
  name: 'AdmininstAcc',
  components: {
  },
  computed: {
    loadScreen() {
      return this.$store.state.loadScreen;
    },
  },
  data() {
    return {
      instAcc: null,
      instFocus: null,
      instId: null,
      title: '',
      name: '',
      teacherCode: '',
      studentCode: '',
      subscription: null,
      stripeId: null,
      expireDate: false,
      submitted: false,
      invalid: null,
      edit: false,
    };
  },
  validations: {
    name: { required },
    teacherCode: { required },
  },
  methods: {
    changeTab(stub) {
      this.selectedTab = stubTitleMap[stub];
      this.$router.push(stub);
    },
    getInstAcc() {
      this.$store.commit('setLoadScreen', { status: true });
      const payload = {
        id: this.instId,
      };
      axios.post('/api/admin/getInstAcc', payload)
        .then((response) => {
          this.instAcc = response.data.institute;
          console.log(this.instAcc);
          this.name = this.instAcc.name;
          this.teacherCode = this.instAcc.teacherCode;
          this.studentCode = this.instAcc.studentCode;
          this.subscription = this.instAcc.subscription;
          this.stripeId = this.instAcc.stripeId;
          this.expireDate = this.instAcc.expireDate;
          this.$store.commit('setLoadScreen', { status: false });
        }).catch((err) => {
          console.error(err);
          this.$store.commit('setLoadScreen', { status: false });
        });
    },
    editInfo() {
      this.edit = true;
    },
    cancelEdit() {
      this.edit = false;
      this.getInstAcc();
    },
    submitEdit() {
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.editModal = new Modal(document.getElementById('confirmEdit', {}));
        this.editModal.show();
      }
    },
    refreshCode() {
      axios.get(`/api/school/${this.instId}/update_codes`)
        .then(() => {
          this.getInstAcc();
        });
    },
    updateInstAcc() {
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.commit('setLoadScreen', { status: true });
        const payload = {
          id: this.instId,
          name: this.name,
          teachercode: this.teacherCode,
          studentcode: this.studentCode,
          subscription: this.subscription,
          stripeid: this.stripeId,
          expiredate: this.expireDate,
        };
        axios.post('/api/admin/updateInst', payload)
          .then(() => {
          }).catch((err) => {
            console.error(err);
            this.getInstAcc();
            this.$store.commit('setLoadScreen', { status: false });
          });
        this.edit = false;
        this.submitted = false;
        this.editModal.hide();
        this.$store.commit('setLoadScreen', { status: false });
      }
    },
    loadDelete(ind) {
      this.deleteModal = new Modal(document.getElementById('deleteInstModal', {}));
      this.instFocus = ind;
      console.log(this.instFocus);
      this.deleteModal.show();
    },
    deleteInst() {
      axios.post('/api/admin/deleteInst', {
        // eslint-disable-next-line no-underscore-dangle
        id: this.instId,
      }).then(() => {
        this.deleteModal.hide();
        this.$router.go(-1);
      }).catch((err) => {
        throw err;
      });
    },
  },
  mounted() {
    this.instId = this.$route.params.iid;
    this.getInstAcc();
  },
};
</script>

<style>
.error {
  color: red;
}
input.edit-mode {
  background: #F3ECE6 !important;
  color: #000 !important;
}

#course-page-header {
  max-width: 1200px;
}

#secondary-sidebar {
  position: fixed;
  width: 15em;
  background-color: #f9f5f1;
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.1);
  padding-top: 4.5em;
}
.toggle-tab {
  /*display: block;*/
  width: 40%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  color: #fff;
  background-color: var(--oxford-blue);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

}
/*.dropdown-menu {
  width:  40%;
}

.dropdown-menu li {
  padding:  0.5rem 1rem;
  font-size: 14pt;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: var(--marigold);
}*/

.account-menu {
  text-align: right !important;
  border: none !important;
  background: none !important;
}

#account-menu-btn.show {
  background-color: #002147 !important;
  color: #fff;
}

.dropdown-item:hover {
  background-color: transparent !important;
}

.selected-pill {
  background-color: #87ceeb;
}

</style>

<template>
  <div class="row mx-0 justify-content-center">
    <div class="col-12 col-md-10 col-lg-8 p-4 mb-4 rounded bg-white left-accent-card">
      <form @submit.prevent="submitSnack">
        <div v-if="formStep==0" class="">
          <h3 class="text">Register a new school</h3>
          <div id="school-field" class="text-start text-secondary mb-3 row input-container">
            <label class="form-label fs-5">School Name</label>
            <input type="text" name="school" class=" fs-6 input-field" v-model="school.name"
            :class="{ 'is-invalid': submit1 && $v.school.name.$error}">
            <span class="input-border"></span>
            <div v-if="submit1 && !$v.school.name.required" class="form-text error">
              School name is required</div>
          </div>
          <small>Note: An account created with a new school is automatically designated as
          an administrator's account with severely limited functionality.</small>
          <h4 class="mt-5 mb-3">Select your subscription plan</h4>
<!--           <div v-if="submit1 && !$v.school.selectedPlan.required"
          class="form-text text-start error mb-2">
            Please select a plan to proceed
          </div> -->
          <div class="row mx-auto col-3 justify-content-center mb-3">
            <div class="btn-group" role="group">
              <input type="radio" class="btn-check sub-period" name="btnradio"
              id=monthly checked @click="subscriptionPeriod='monthly' "
              :class = "{ 'selected' : subscriptionPeriod == 'monthly'}"/>
              <label class="btn btn-outline-dark" for="monthly">Monthly</label>
              <input type="radio" class="btn-check sub-period" name="btnradio"
              id=yearly checked @click="subscriptionPeriod='yearly' "
              :class = "{ 'selected' : subscriptionPeriod == 'yearly'}"/>
              <label class="btn btn-outline-dark" for="yearly">Yearly</label>
            </div>
          </div>
          <div class="card-group pricing">
            <div class="card card-shadow"
            :class="{ selected : planArr[0] }"
            v-on:click="selectPlan('snack')">
              <div class="card-body">
                <div class="corner-ribbon" v-if="planArr[0]"><strong>Selected</strong></div>
                <h5 class="card-title text-uppercase text-center">Snack</h5>
                <h6 class="card-price text-center">$0<span class="period">/lifetime</span></h6>
                <hr>
                <ul>
                  <li><span class="material-icons">check</span>100 users</li>
                  <li><span class="material-icons">check</span>20 assignments/user</li>
                  <li><span class="material-icons">check</span>Basic support</li>
                </ul>
              </div>
            </div>
            <div class="card card-shadow"
            :class="{ selected : planArr[1] }"
            v-on:click="selectPlan('brunch')">
              <div class="card-body">
                <div class="corner-ribbon" v-if="planArr[1]"><strong>Selected</strong></div>
                <h5 class="card-title text-uppercase text-center">Brunch</h5>
                <h6 class="card-price text-center" v-if="subscriptionPeriod == 'monthly'">
                ${{ plans.monthly.brunch.price }}<span class="period">/month</span></h6>
                <h6 class="card-price text-center" v-else>
                  <span style="font-size: 60%" class="text-grey">
                  <del>
                  ${{ plans.monthly.brunch.price }}</del>
                  </span>
                  ${{ plans.yearly.brunch.price }}<span class="period">/month</span>
                </h6>
                <hr>
                <ul>
                  <li><span class="material-icons">check</span>1000 users</li>
                  <li><span class="material-icons">check</span>Unlimited assignments</li>
                  <li><span class="material-icons">check</span>24/7 emergency support</li>
                  <li><span class="material-icons">check</span>Anti-cheating checks</li>
                  <li><span class="material-icons">check</span>Auto grader</li>
                </ul>
              </div>
            </div>
            <div class="card card-shadow"
            :class="{ selected : planArr[2] }"
            v-on:click="selectPlan('feast')">
              <div class="card-body">
                <div class="corner-ribbon" v-if="planArr[2]"><strong>Selected</strong></div>
                <h5 class="card-title text-uppercase text-center">Feast</h5>
                <h6 class="card-price text-center" v-if="subscriptionPeriod == 'monthly'">
                ${{ plans.monthly.feast.price }}
                <span class="period">/month</span>
                </h6>
                <h6 class="card-price text-center" v-else><span style="font-size: 60%"
                class="text-grey"><del>
                ${{ plans.monthly.feast.price }}</del>
                </span>${{ plans.yearly.feast.price }}<span class="period">/month</span></h6>
                <hr>
                <ul>
                  <li><span class="material-icons">check</span>Unlimited users</li>
                  <li><span class="material-icons">check</span>Unlimited assignments</li>
                  <li><span class="material-icons">check</span>24/7 emergency support</li>
                  <li><span class="material-icons">check</span>Anti-cheating checks</li>
                  <li><span class="material-icons">check</span>Auto grader</li>
                  <li><span class="material-icons">check</span>PDF to Quill assignment</li>
                  <li><span class="material-icons">check</span>Zoom integration</li>
                  <li><span class="material-icons">check</span>AI insights & analytics</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div v-if="formStep=== 1" class="">
          <h3 class="text-start my-3"> Account Information</h3>
          <div class="text-start text-secondary mb-3 row input-container">
            <label class="form-label fs-5">Given Name(s)</label>
            <input type="text" name="firstname"
            class=" fs-6 input-field" v-model="user.firstName"
            :class="{ 'is-invalid': submitted && $v.user.firstName.$error }"/>
            <span class="input-border"></span>
            <div v-if="submitted && !$v.user.firstName.required"
            class="form-text error">First Name is required</div>
          </div>
          <div class="text-start text-secondary mb-3 row input-container">
            <label class="form-label fs-5" >Last Name</label>
            <input type="text" name="lastname"
            class=" fs-6 input-field" v-model="user.lastName"
            :class="{ 'is-invalid': submitted && $v.user.lastName.$error }"/>
            <span class="input-border"></span>
            <div v-if="submitted && !$v.user.lastName.required"
            class="form-text error">Last Name is required</div>
          </div>
          <div id="email-field" class="text-start text-secondary mb-3 row input-container">
            <label class="form-label fs-5">Email</label>
            <input @blur="checkUser" @focus="emailEntry=false" type="text" name="email"
            class=" fs-6 input-field" v-model="user.userEmail"
            :class="{ 'is-invalid': submitted && $v.user.userEmail.$error,
            'user-valid': userValid && emailEntry && !$v.user.userEmail.$error,
            'user-invalid': emailEntry && !$v.user.userEmail.$error }">
            <span class="input-border"></span>
            <div v-if="submitted && !$v.user.userEmail.required" class="form-text error">
              Email is required</div>
            <div v-if="submitted && !$v.user.userEmail.email" class="form-text error">
            Email is invalid</div>
            <div v-show="emailEntry && !userValid && !$v.user.userEmail.$error"
            class="error">
              <small>
                This email is already been registered, please sign in
                <router-link class= "text-danger text-underline" to="/login">here</router-link>
              </small>
            </div>
          </div>
    <!--       <div v-if="role == 'administrator'"> -->
          <div class="text-start text-secondary mb-3">
            <div class="form-label fs-5">Role</div>
            <div class="form-check">
              <input class="form-check-input" type="radio" id="adminRadio" value="IT"
              name="administrator" v-model="inputRole" disabled>
              <label class="form-check-label" for="adminRadio">
                Administrator
              </label>
            </div>
          </div>
          <!-- </div> -->
          <!-- <div v-if="role == 'teacher'">
            <div class="form-check">
              <div class="col-form-label">Role:</div>
              <input class="form-check-input" type="radio" id="teacherRadio"
              name="teacher" v-model="inputRole" disabled>
              <label class="form-check-label" for="teacherRadio">
                Teacher
              </label>
            </div>
          </div>
          <div v-if="role == 'student'">
            <div class="form-check">
              <div class="col-form-label">Role:</div>
              <input class="form-check-input" type="radio" id="studentRadio"
              name="student" v-model="inputRole" disabled>
              <label class="form-check-label" for="studentRadio">
                Student
              </label>
            </div>
          </div> -->
          <div class="text-start text-secondary mb-3 row input-container">
            <label class="form-label fs-5">Password</label>
            <input type="password" name="password"
            class=" fs-6 input-field" v-model="user.password"
            :class="{ 'is-invalid': submitted && $v.user.password.$error }"/>
            <span class="input-border"></span>
            <div v-if="submitted && !$v.user.password.required" class="form-text error">
            Password is required</div>
            <div v-if="submitted && !$v.user.password.minLength" class="form-text error">
            Password must be at least 8 characters long</div>
            <!-- <div v-if="submitted && !$v.user.password.valid && user.password"
            class="form-text error">
            Password must contain Uppercase and lowercase characters, numbers and symbols</div> -->
          </div>
          <div class="text-start text-secondary mb-3 row input-container">
            <label class="form-label fs-5" >Confirm Password</label>
            <input type="password" name="confirmpassword"
            class=" fs-6 input-field" v-model="user.confirmPassword"
            :class="{ 'is-invalid': submitted && $v.user.confirmPassword.$error }"/>
            <span class="input-border"></span>
            <div v-if="submitted && !$v.user.confirmPassword.required"
            class="form-text error">Confirm Password is required</div>
            <div v-if="submitted && !$v.user.confirmPassword.sameAsPassword"
            class="form-text error">Passwords do not match</div>
          </div>
          <div>
            <p class="text-start">
            By signing up, you agree to our <a class="text-primary fw-light form-text"
            id="a" href="http://waffle.school/terms-conditions">T&C and privacy policy</a>
            </p>
          </div>
        </div>
        <div class="mt-4">
          <div class="col bg-gradient-primary text-white">
            <a class="float-end btn btn-danger mx-1" href="/login">Cancel</a>
          </div>
          <div v-if="formStep == 0" class="col bg-gradient-primary text-white">
            <button type="submit"
            class=" float-end btn btn-dark mx-1 text-center" @click="nextStep">
              Next
            </button>
          </div>
          <button v-if="formStep == 1" type="submit"
          class=" float-start btn btn-dark mx-1 text-center" @click="backStep">
            Back
          </button>
          <button v-if="formStep == 1 && selectedPlan == 'snack'" type="submit"
          class=" float-end btn btn-dark mx-1 text-center">
            Submit
          </button>
          <button v-if="formStep == 1 && selectedPlan !== 'snack'" type="submit"
          class=" float-end btn btn-dark mx-1 text-center" @click="redirectToStripe">
            Review order details
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {
  required, email, minLength, sameAs,
} from 'vuelidate/lib/validators';

export default {
  name: 'NewSchool',
  components: {
  },
  data() {
    return {
      plans: {
        monthly: {
          snack: {
            subscriptionID: 1,
          },
          brunch: {
            price: 349,
            paymentID: 'price_1HeD8nIWgpX52bCfC3NfS3EW',
            subscriptionID: 2,
          },
          feast: {
            price: 549,
            paymentID: 'price_1HeD8sIWgpX52bCf3LCtd2V1',
            subscriptionID: 3,
          },
        },
        yearly: {
          snack: {
            subscriptionID: 1,
          },
          brunch: {
            price: 299,
            paymentID: 'price_1HeD8nIWgpX52bCfiZSof71C',
            subscriptionID: 5,
          },
          feast: {
            price: 499,
            paymentID: 'price_1HeD8sIWgpX52bCf7HasHciV',
            subscriptionID: 6,
          },
        },
      },
      user: {
        firstName: '',
        lastName: '',
        userEmail: '',
        password: '',
        confirmPassword: '',
      },
      formStep: 0,
      role: '',
      school: {
        name: '',
      },
      selectedPlan: 'brunch',
      schoolCode: '',
      subscriptionPeriod: 'monthly',
      planArr: [false, false, false],
      inputRole: null,
      submit1: null,
      submitted: false,
      invalid: null,
      schoolValid: null,
      userValid: null,
      schoolEntry: false,
      emailEntry: false,
      userData: {},
    };
  },
  validations: {
    school: {
      name: { required },
    },
    user: {
      firstName: { required },
      lastName: { required },
      userEmail: { required, email },
      password: {
        required,
        minLength: minLength(8), // I assume you'd want something like this too
        // valid(value) {
        //   const containsUppercase = /[A-Z]/.test(value);
        //   const containsLowercase = /[a-z]/.test(value);
        //   const containsNumber = /[0-9]/.test(value);
        //   const containsSpecial = /[#?!@$%^&*-]/.test(value);
        //   return (
        //     containsUppercase
        //     && containsLowercase
        //     && containsNumber
        //     && containsSpecial
        //   );
        // },
      },
      confirmPassword: { required, sameAsPassword: sameAs('password') },
    },
  },
  methods: {
    nextStep() {
      this.submit1 = true;
      if (!this.$v.school.$invalid) {
        this.role = 'IT admin';
        this.inputRole = 'IT';
        this.formStep += 1;
      }
    },
    backStep() {
      this.formStep -= 1;
    },
    selectPlan(tier) {
      let ind = 0;
      if (tier === 'snack') {
        ind = 0;
      } else if (tier === 'brunch') {
        ind = 1;
      } else {
        ind = 2;
      }
      this.selectedPlan = tier;
      const temp = [false, false, false];
      temp[ind] = true;
      this.planArr = temp;
      console.log(this.selectedPlan);
    },
    submitSnack() {
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.user.$invalid) {
        this.$store.commit('setLoadScreen', { status: true });
        // const signDate = new Date();
        const payload = {
          school: this.school.name,
          givenname: this.user.firstName,
          lastname: this.user.lastName,
          email: this.user.userEmail,
          password: this.user.password,
          role: this.inputRole,
          tier: this.plans[this.subscriptionPeriod][this.selectedPlan].subscriptionID,
        };
        axios.post('/api/account/register/', payload)
          .then(() => {
            this.$router.replace('/login');
            this.$store.commit('setLoadScreen', { status: false });
          })
          .catch((error) => {
            alert(error);
            this.$store.commit('setLoadScreen', { status: false });
          });
      }
    },
    redirectToStripe() {
      // redirects to payment page
    },
    checkUser() {
      try {
        this.$v.user.userEmail.$touch();
        const payload = {
          email: this.user.userEmail,
        };

        axios.post('/api/checkUser', payload)
          .then((res) => {
            this.emailEntry = true;
            if (res.data.valid === 1) {
              this.userValid = true;
            } else {
              this.userValid = false;
            }
          });
      } catch (error) {
        console.error(error.response);
      }
    },
    checkSchool() {
      try {
        this.$v.schoolName.$touch();
        const payload = {
          school: this.schoolName,
        };

        axios.post('/api/checkSchool', payload)
          .then((res) => {
            this.schoolEntry = true;
            if (res.data.valid === 1) {
              this.schoolValid = true;
            } else {
              this.schoolValid = false;
            }
          });
      } catch (error) {
        console.error(error.response);
      }
    },
  },
  mounted() {
    this.selectPlan('brunch');
  },
};

</script>

<style scoped>
.error {
  color: red;
  font-size: 0.8em;
}

#email-field i {
  position: absolute;
  top: 270px;
  right: 30px;
  /*min-width: 40px;*/
}
.user-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("~@/assets/error_red_24dp.svg");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.user-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("~@/assets/check_circle_green_24dp.svg");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

label {
  width: 100%;
}

.card-select {
  display: none;
}

.card-input {
  margin: 00px;
  /*height: 100%;*/
  height: 10em;
  width: 100%;
  border-radius: 2em;
}

.card-input:hover {
  cursor: pointer;
  box-shadow: .25rem .25rem rgba(20,1,1,0.075);
}

.card-select:checked + .card-input {
  background-color: #E97852;
  box-shadow: .125rem .25rem rgba(0,0,0,0.075);
  color: #FFF;
 }

.divider-register {
  position: relative;
  z-index: 0; }
  .divider-register svg {
    background: none !important;
    height: 6vw; }
    .divider-register svg:not([class*='bg']) path {
      background-color: #f3ece6; }
  .divider-register:not(:first-child) {
    top: 1px; }
  .divider-register:first-child {
    bottom: 1px; }
  .divider-register.divider-vertical {
    height: 100%;
    width: 25rem;
    top: 0;
    left: 1px; }
    .divider-register.divider-vertical svg {
      height: 100%;
      width: 25rem;
    }

.bg-image {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; }
  .bg-image:not([class*='absolute']) {
    position: absolute; }
  .bg-image + .card-body {
    position: relative;
    z-index: 0;
}

img.bg-image {
  width: 50%;
  object-fit: cover;
  vertical-align: middle;
    border-style: none; }

.pricing hr {
  margin: 1.5rem 0;
}

.pricing .card-title {
  margin: 0.5rem 0;
  font-size: 1.2rem;
  letter-spacing: .1rem;
  font-weight: bold;
  color: #87ceeb;
}

.pricing .card-price {
  font-size: 3rem;
  margin: 0;
}

.pricing .card-price .period {
  font-size: 0.8rem;
}

.pricing .material-icons {
  vertical-align: middle;
  margin-right: 1rem;
}

.pricing ul {
  list-style-type: none;
}

.pricing ul li {
  margin-bottom: 1rem;
}

.pricing .text-muted {
  opacity: 0.7;
}

.pricing .card {
  overflow: hidden;
}
.pricing .card:hover {
  box-shadow: .25rem .25rem 10px rgba(135,206,235,0.5), -.25rem -.25rem 10px rgba(135,206,235,0.55);
  cursor: pointer;
}

.pricing .card.selected {
  background-color: #36454f;
  color: white;
}

.pricing .card.selected .text-grey{
  color: white;
}

.pricing .card .text-grey {
  color: #6c757d;
}

.pricing .card.selected hr {
  border-color: white;
}

.corner-ribbon {
  width: 200px;
  background: #87ceeb;
  position: absolute;
  top: 25px;
  left: -50px;
  text-align: center;
  line-height: 40px;
  letter-spacing: 3px;
  color: #36454f;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left-accent-card {
  box-shadow: .125rem .25rem rgba(0,0,0,0.075);
  border-left: 0.5rem solid #bb4b25;
  margin-left: -0.5rem;
}

.register-col {
  z-index: 1;
  position: relative;
  object-fit: cover;
  background-color: #f3ece6;
  min-height: 100vh;
  background-size: auto;
  background-repeat: repeat;
}

.register-card {
  max-width: 50%;
}

.bg-rust-gradient {
  /*background: rgb(187,75,37);*/
  background: linear-gradient(159deg, rgba(187,75,37,1) 0%, rgba(233,120,82,1) 50%);
}

.social-button img {
  width: 100%;
  background-color: #f3ece6;
}

.social-button {
  border: 0;
}
</style>

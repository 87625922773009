<template>
  <div>
    <Navbar></Navbar>
    <router-view class="navbar-margin"></router-view>
  </div>
</template>
<script>

import Navbar from '../components/Navbar.vue';

export default {
  components: {
    Navbar,
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
  },
  data() {
    return {
    };
  },
  mounted() {
  },
};
</script>
<style scoped>
</style>

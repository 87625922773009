<template>
  <div class="bg-overlay">
    <div v-if="roleId == 5" class="col-10 col-md-7 col-lg-8 mx-auto py-4">
      There are no courses available for this user.
    </div>
    <div v-else-if="roleId == 4" class="col-10 col-md-7 col-lg-8 mx-auto py-4">
      There are no courses available for this user.
    </div>
    <div v-else-if="roleId == 2" class="col-10 col-md-7 col-lg-8 mx-auto py-4">
      <div class="mb-4 mx-0" style="padding: 0px 15px;">
        <h3 class="d-inline-block">Lectures</h3>
        <button class="btn float-end" @click="loadClassCreation" v-if="this.roleId == 2">
          <i class="align-middle material-icons md-32 md-light">add_circle_outline</i>
        </button>
      </div>
      <div class="course-container" style="padding: 0px 15px;">
        <div class="d-inline-block mx-2 " v-for="course, idx in courselist" :key="idx">
          <div class="course-card card card-shadow"
          style=""
          :style="{ 'border-color': colorArray[idx % 5] }">
            <div class="card-body">
              <h4 class="card-title d-inline">{{ course.title }}</h4>
              <i class="material-icons float-end text-marigold" type="button"
              @click="loadClassPage(idx)"> open_in_new </i>
            </div>
            <div class="card-footer bg-white border-0">
              <div class="row">
                <div class="col">
                  <i class="align-middle material-icons md-40 md-light">
                    account_circle
                  </i>
                </div>
                <div class="col">
                  {{ course.instructor }}<br>
                  <small class="text-muted">Main instructor</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="col-10 col-md-7 col-lg-8 mx-auto py-4">
      <div class="mb-4 mx-0" style="padding: 0px 15px;">
        <h3 class="d-inline-block">Courses Enrolled</h3>
        <button class="btn float-end" @click="loadClassCreation" v-if="this.roleId == 2">
          <i class="align-middle material-icons md-32 md-light">add_circle_outline</i>
        </button>
      </div>
      <div class="course-container" style="padding: 0px 15px;">
        <div class="d-inline-block mx-2 " v-for="course, idx in courselist" :key="idx">
          <div class="course-card card card-shadow"
          style=""
          :style="{ 'border-color': colorArray[idx % 5] }">
            <div class="card-body">
              <h4 class="card-title d-inline">{{ course.title }}</h4>
              <i class="material-icons float-end text-marigold" type="button"
              @click="loadClassPage(idx)"> open_in_new </i>
            </div>
            <div class="card-footer bg-white border-0">
              <div class="row">
                <div class="col">
                  <i class="align-middle material-icons md-40 md-light">
                    account_circle
                  </i>
                </div>
                <div class="col">
                  {{ course.instructor }}<br>
                  <small class="text-muted">Main instructor</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name: 'Course',
  components: {
  },
  computed: {
    // userData() {
    //   return this.$store.state.userData;
    // },
  },
  data() {
    return {
      courselist: [],
      colorArray: ['#4d9de0', '#e15554', '#e1bc29', '#3bb273', '#37000a'],
      userId: null,
      roleId: null,
    };
  },
  methods: {
    getAllCourses() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.get('/api/course', {
        params: {
          role: this.roleId,
          user: this.userId,
        },
      })
        .then((response) => {
          this.courselist = response.data;
          setTimeout(() => {
            this.$store.commit('setLoadScreen', { status: false });
          }, 500);
        });
    },
    loadClassPage(index) {
      this.$router.push(`/courses/${index}/edit`);
    },
    loadClassCreation() {
      this.$router.push('/courses/create');
    },
  },
  mounted() {
    this.userId = this.$route.params.uid;
    this.roleId = this.$route.params.rid;
    console.log(this.userId, this.roleId);
    this.getAllCourses();
  },
};
</script>
<style scoped>
.course-card {
  border-top-style: solid;
  border-width: 0.5rem 0px 0px 0px;
  width:  25rem;
  margin-bottom: 30px;
}
</style>

<template>
  <div>
    <div class="col-md-7 col-lg-8 mx-auto py-4">
      <div class="d-flex justify-content-start">
        <a href="/">Back to home</a>
      </div>
      <div class="card shadow my-5">
        <div class="card-body">
          <div class="row">
            <h3 class="card-title text-pri mb-2 col">Manage accounts</h3>
            <div class="text-white d-inline col">
              <button type="button"
              class="float-end btn btn-sm btn-danger text-center mx-2"
              :disabled="selected.length == 0" @click="loadDelete">
              <span class="material-icons-outlined align-middle"
                 data-bs-toggle="tooltip" title="Delete">delete</span>
                Delete {{ selected.length }} users
              </button>
              <button type="button"
              class="float-end btn btn-sm bg-pri text-center mx-2" @click="loadInvite">
              <span class="material-icons-outlined align-middle"
                 data-bs-toggle="tooltip" title="Invite">person_add</span>
                Invite users
              </button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <table id="userTable" class="display table table-hover">
            <thead class="bg-dark text-white">
              <tr>
                <th scope="col"></th>
                <th scope="col">Last Name</th>
                <th scope="col">Given Name(s)</th>
                <th scope="col" class="text-center">Email</th>
                <th scope="col" class="text-center">Role</th>
                <th scope="col" class="text-center">Date Joined</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user, index in users" :key="index">
                <td><input type="checkbox" v-bind:value="user.id" v-model="selected"></td>
                <td>{{ user.lastname }}</td>
                <td>{{ user.givenname }}</td>
                <td>{{ user.email }}</td>
                <td>{{ user.role }}</td>
                <td class="text-center">{{ user.created }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- To-do -->
    <div class="modal fade" id="deleteUsersModal" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">This action cannot be undone.</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Delete {{ selected.length }} users
          </div>
          <div class="modal-footer">
            <button type="button" class="btn bg-acc2"
              data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-danger" v-on:click="deleteStudents">Delete</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="inviteModal"
    tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Invite Users</h5>
            <button type="button" class="btn-close"
              data-bs-dismiss="modal" aria-label="Close">
            </button>
          </div>
          <div class="modal-body">
            <label for="name">Name</label>
            <input type="text" class="form-control" v-model="invite.name"
            :class="{ 'is-invalid': submitInvite && $v.invite.name.$error }"/>
            <div v-if="submitInvite && !$v.invite.name.required"
            class="form-text error"> Recipient Name is required
            </div>
          </div>
          <div class="modal-body">
            <label for="email">Email Address</label>
            <input type="text" class="form-control" v-model="invite.email"
            :class="{ 'is-invalid': submitInvite && $v.invite.email.$error }"/>
            <div v-if="submitInvite && !$v.invite.email.required"
            class="form-text error"> Recipient Email is required
            </div>
          </div>
          <div class="modal-body">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="inlineRadio1"
              :value="[1, 'student']"
              name="role" v-model="invite.role">
              <label class="form-check-label" for="inlineRadio1" >Student</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="inlineRadio2"
              :value="[2, 'staff']"
              name="role" v-model="invite.role">
              <label class="form-check-label" for="inlineRadio2" >Staff</label>
            </div>
            <div v-if="submitInvite && !$v.invite.role.required"
            class="form-text error"> Please select the type of Invitation
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn bg-acc2"
              data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn bg-pri"
              v-on:click="inviteUsers">
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="uploadModal"
    tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Upload csv</h5>
            <button type="button" class="btn-close"
              data-bs-dismiss="modal" aria-label="Close">
            </button>
          </div>
          <div class="modal-body">
            <label class="mt-4" for="datafile"> Upload .csv containing raw data: </label><br />
            <input type="file" id="datafile" name="datafile" accept=".csv"
            ><br/>
<!--             <h4 v-show=""></h4> -->
          </div>
          <div class="modal-body">
            <label class="mt-4" for="data"> Download .csv Template </label><br/>
            <button type="button" class="btn btn-info btn-sm
            text-white mt-3" data-dismiss="modal"> Download Template </button>
            <input type="file" id="datafile" name="datafile" accept=".csv"
            ><br/>
<!--             <h4 v-show=""></h4> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import axios from 'axios';
import $ from 'jquery';
import { Modal } from 'bootstrap';
import 'datatables.net-bs5';

export default {
  name: 'ManageAccounts',
  components: {
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
  },
  data() {
    return {
      users: [],
      table: null,
      selected: [],
      submitInvite: false,
      inviteModal: null,
      deleteUsersModal: null,
      info: null,
      inputRole: null,
      invite: {
        name: null,
        email: null,
        role: null,
      },
      rootUrl: window.location.origin,
    };
  },
  validations: {
    invite: {
      name: null,
      email: null,
      role: null,
    },
  },
  methods: {
    deleteStudents() {
      axios.post(`/api/school/${this.userData.schoolId}/delete_users`, {
        to_delete: this.selected,
      })
        .then((response) => {
          this.selected = [];
          this.getSchoolInfo();
          this.users = response.data.accounts;
          this.deleteUsersModal.hide();
        });
    },
    uploadFile(e) {
      [this.file] = [e.target.files];
    },
    loadUpload() {
      this.uploadModal = new Modal(document.getElementById('uploadModal', {}));
      this.uploadModal.show();
    },
    loadInvite() {
      this.inviteModal = new Modal(document.getElementById('inviteModal', {}));
      this.inviteModal.show();
    },
    loadDelete() {
      this.deleteUsersModal = new Modal(document.getElementById('deleteUsersModal', {}));
      this.deleteUsersModal.show();
    },
    encoder64(param) {
      const encoded = btoa(param);
      return encoded;
    },
    decoder64(param) {
      const encoded = atob(param);
      return encoded;
    },
    getSchoolInfo() {
      const payload = {
        id: this.userData.schoolId,
      };
      axios.post('/api/getSchoolInfo', payload)
        .then((res) => {
          this.info = res.data.info;
        });
    },
    getUserInfo() {
      axios.get(`/api/school/${this.userData.schoolId}/list_users`)
        .then((response) => {
          this.users = response.data.accounts;
          console.log(this.users);
          setTimeout(() => {
            this.table = $('#userTable').DataTable({
              scrollY: '23rem',
              scrollCollapse: true,
              fixedHeader: true,
              autoWidth: false,
              info: false,
              searching: true,
              paging: true,
              retrieve: true,
              ordering: true,
            });
          }, 10);
        });
    },
    inviteUsers() {
      const encRole = this.encoder64(this.invite.role[0]);
      const encSchool = this.encoder64(this.userData.schoolId);
      const encEmail = this.encoder64(this.invite.email);
      const formData = {
        email: this.invite.email,
        name: this.invite.name,
        school: this.info.schoolName,
      };
      let encCode = '';
      if (this.invite.role === 'student') {
        formData.code = this.info.studentCode;
        encCode = this.encoder64(this.info.studentCode);
      } else {
        formData.code = this.info.teacherCode;
        encCode = this.encoder64(this.info.teacherCode);
      }
      formData.registerUrl = `${this.rootUrl}/register/invite/${encRole}/${encSchool}/${encCode}/${encEmail}`;
      axios.post(`/api/school/${this.userData.schoolId}/invite`, formData)
        .then(() => {
          // $('#inviteUsersModal').modal('hide');
          this.invite.name = null;
          this.invite.email = null;
          this.invite.role = null;
          this.inviteModal.hide();
        });
    },
  },
  mounted() {
    this.getSchoolInfo();
    this.getUserInfo();
  },
};
</script>

<style scoped>

div.dataTables_filter input{
  border:  0;
  border-bottom: 5px #bb4b25;
  background-color: #dcf3f9;
}
div.dataTables_filter input:focus{
  background-color: #F5D6CC;
  outline:  none;
}

</style>

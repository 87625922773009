<template>
  <section class="row g-0 min-vh-100 p-0">
    <div class="col-lg-4 justify-content-end d-flex bg-marigold-gradient" >
      <img src="~@/assets/quill_fall.png" class="bg-image">
      <div class="divider-login divider-vertical d-none d-lg-block">
        <svg width='100%' height="100%" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none">
          <path d="M50,0 L100,0 L100,100 L50,100 C83.333333,83.3333333 100,66.6666667
          100,50 C100,33.333333 83.33333333,16.6666667 50,0 Z" fill="#FDF7ED">
          </path>
        </svg>
      </div>
    </div>
    <div class="col d-flex align-self-center register-col p-5">
      <router-view></router-view>
    </div>
  </section>
</template>
<script>

export default {
  name: 'Register',
};
</script>
<style scoped>
.divider-login {
  position: relative;
  z-index: 0; }
  .divider-login svg {
    background: none !important;
    height: 6vw; }
    .divider-login svg:not([class*='bg']) path {
      background-color: #f3ece6; }
  .divider-login:not(:first-child) {
    top: 1px; }
  .divider-login:first-child {
    bottom: 1px; }
  .divider-login.divider-vertical {
    height: 100vh;
    width: 25rem;
    top: 0;
    left: 1px; }
    .divider-login.divider-vertical svg {
      height: 100vh;
      width: 25rem; }

.bg-image {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; }
  .bg-image:not([class*='absolute']) {
    position: absolute; }
  .bg-image + .card-body {
    position: relative;
    z-index: 0; }

img.bg-image {
  width: 50%;
  object-fit: cover;
  vertical-align: middle;
    border-style: none; }

.register-col {
  z-index: 1;
  position: relative;
  background-color: #FDF7ED;
  height: 100vh;
}

.register-card {
  max-width: 50%;
}

.bg-marigold-gradient {
  /*background: rgb(187,75,37);*/
  background: linear-gradient(140deg, rgba(0,103,165,1) 0%, rgba(0,33,71,1) 70%);
}

.social-button img {
  width: 100%;
  background-color: #f3ece6;
}
.social-button {
  border: 0;
}

</style>
